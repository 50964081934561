import React from 'react';
import {connect} from 'react-redux';
import {Field} from "redux-form";
import {brandsGet} from './redux/actions';

class BrandComponent extends React.Component {

    componentWillMount() {
        const {
            brandsGet,
        } = this.props;
        brandsGet();
    }

    render() {
        const {
            brands: {brands},
            InitialMarca
        } = this.props;
        return (
            <Field name="marca" type="select" className="select-brand" component="select">
                {InitialMarca ? <option value={InitialMarca.nombre}>{InitialMarca.nombre}</option> :
                    <option>...Seleccione</option>}
                {brands.length > 0 && brands.map((brand, index) => {
                    if (InitialMarca === undefined)
                        return <option key={index} value={brand.nombre}>{brand.nombre}</option>
                    else if (brand.id !== InitialMarca.id)
                        return <option key={index} value={brand.nombre}>{brand.nombre}</option>
                })}
            </Field>
        )
    }
}

const mapStateToProps = state => ({
    provider: state.provider,
    brands: state.brands,
});

const connected = connect(mapStateToProps, {
    brandsGet
})(BrandComponent);

export default connected;