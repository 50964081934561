import {SHIPPING_TYPE_GET_ERROR, SHIPPING_TYPE_GET_REQUESTING, SHIPPING_TYPE_GET_SUCCESS} from "./constants";

export const shippingTypeGetRequesting = (token) => ({
    type: SHIPPING_TYPE_GET_REQUESTING,
    token
});

export const shippingTypeGetSuccess = (shippingTypes) => ({
    type: SHIPPING_TYPE_GET_SUCCESS,
    shippingTypes,
});

export const shippingTypeGetError = (error) => ({
    type: SHIPPING_TYPE_GET_ERROR,
    error
});