import {PAYMENT_METHOD_GET_ERROR, PAYMENT_METHOD_GET_REQUESTING, PAYMENT_METHOD_GET_SUCCESS} from "./constants";


const initialState = {
    requesting: false,
    success: false,
    error: '',
    message: '',
    paymentMethods: [],
};

const reducer = (state = initialState, action) => {
    switch (action.type){
        case PAYMENT_METHOD_GET_REQUESTING:
            return {
                ...state,
                paymentMethods: [],
            };
        case PAYMENT_METHOD_GET_SUCCESS:
            return {
                ...state,
                paymentMethods: action.paymentMethods,
            };
        case PAYMENT_METHOD_GET_ERROR:
            return{
                ...state,
                error: action.error
            };
        default:
            return state;
    }
};
export default reducer;