import React from 'react';
import {connect} from 'react-redux';
import {reduxForm} from 'redux-form';
import SearchComponent from "../search/search";
import {TableOrders} from "./table/table-orders";
import ModalOrder from "./modals/add-orders";
import ModalEditOrders from "./modals/edit-orders";
import {FilterComponent} from "../filter/filter";
import {NotFound} from "../../commons/errors/notFound";
import {
    ordersGetRequesting,
    orderSearchRequesting,
    setFocusOrder,
    orderChangeStateRequesting
} from "./redux/actions";
import {statesOrderRequesting} from "../../commons/states/actions";
import ModalDetailOrder from "./modals/modal-detail-orders";
import ModalInfoOrder from "./modals/modal-nfo-orders"

class OrderComponent extends React.Component {

    state = {
        isOrder: false,
        isEditOrder: false,
        addState: false,
        infoOrder: false,
    };
    handleShowModal = (modal, id = 0, order = {}) => {
        const {setFocusOrder} = this.props;
        if (modal !== 'Ordenes')
            setFocusOrder(id, order);
        this.setState({
            isOrder: modal === 'Ordenes',
            isEditOrder: modal === 'Editar Orden',
            addState: modal === 'Agregar estado',
            infoOrder: modal === 'Informacion orden'
        })
    };

    handleHiddenModal = () => {
        this.setState({
            isOrder: false,
            isEditOrder: false,
            addState: false,
            infoOrder: false,
        })
    };

    handleChangeState = (id) => {
        const {orderChangeStateRequesting, provider} = this.props;
        orderChangeStateRequesting(id, provider.token);
    };

    handleSearch = (value) => {
        const {orderSearchRequesting, order, provider: {token}} = this.props;
        if ((order.search !== value.search) && value.search !== '')
            orderSearchRequesting(value.search, token);
    };

    handleFilter = (value) => {
        const {ordersGetRequesting, provider, order} = this.props;
        if (order.filter !== value)
            ordersGetRequesting(order.paginate, value, provider.token);
    };

    handleGetData = (paginate) => {
        const {ordersGetRequesting, provider, order} = this.props;
        if (paginate === 1 && order.hasMore)
            ordersGetRequesting(order.paginate + paginate, order.filter, provider.token);
        if (paginate === -1 && order.hasLess) {
            ordersGetRequesting(order.paginate + paginate, order.filter, provider.token);
        }
    };

    handleGetCsv = () => {
        const {login: {user}} = this.props;
        window.open(`${process.env.REACT_APP_API_URL}/api/exports/ordersProvider?email=${user.correo}`, "_blank")
    };

    componentWillMount() {
        const {
            provider,
            ordersGetRequesting,
            order: {paginate, filter},
            statesOrderRequesting
        } = this.props;
        ordersGetRequesting(paginate, filter, provider.token);
        statesOrderRequesting(provider.token);
    };

    // *******************************
    stopPropagation = (e) => {
        e.stopPropagation();
    };

    render() {
        const {
            order: {order, search, orders, orderDataFocus},
            state: {statesOrder},
            login: {user},
        } = this.props;
        return (
            <div className="styles-container">
                <SearchComponent
                    type={'Ordenes'}
                    placeholder={'Consultar en Ordenes'}
                    handleSearch={this.handleSearch.bind(this)}
                >{search !== '' && (
                    order.length > 0 ? <TableOrders
                        handleShowModal={this.handleShowModal.bind(this)}
                        orders={order}
                        dataCountry={user.datos_pais}
                        changeState={this.handleChangeState.bind(this)}
                    /> : <NotFound/>
                )}
                </SearchComponent>
                <div className="conteiner-page-sector1">
                    <FilterComponent
                        type={'Ordenes'}
                        handleShowModal={this.handleShowModal.bind(this)}
                        handleGetCsv={this.handleGetCsv.bind(this)}
                        states={statesOrder}
                        handleFilter={this.handleFilter.bind(this)}
                        getData={this.handleGetData.bind(this)}
                    />
                    {orders.length > 0 ? (
                        <TableOrders
                            orders={orders}
                            dataCountry={user.datos_pais}
                            handleShowModal={this.handleShowModal.bind(this)}
                            changeState={this.handleChangeState.bind(this)}
                        />) : <NotFound/>}
                    {this.state.isOrder && (
                        <ModalOrder
                            stop={this.stopPropagation.bind(this)}
                            hidden={this.handleHiddenModal.bind(this)}/>
                    )}
                    {this.state.isEditOrder && (
                        <ModalEditOrders
                            stop={this.stopPropagation.bind(this)}
                            hidden={this.handleHiddenModal.bind(this)}
                        />
                    )}
                    {this.state.addState && (
                        <ModalDetailOrder
                            stop={this.stopPropagation.bind(this)}
                            hidden={this.handleHiddenModal.bind(this)}
                            states={statesOrder}
                        />
                    )}
                    {this.state.infoOrder && (
                        <ModalInfoOrder
                            stop={this.stopPropagation.bind(this)}
                            hidden={this.handleHiddenModal.bind(this)}
                        />
                    )}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    provider: state.provider,
    order: state.order,
    state: state.state,
    login: state.login,
});

const connected = connect(mapStateToProps, {
    ordersGetRequesting,
    orderChangeStateRequesting,
    orderSearchRequesting,
    setFocusOrder,
    statesOrderRequesting
})(OrderComponent);

const formed = reduxForm({
    form: 'OrderComponent'
})(connected);

export default formed;