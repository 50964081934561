import React, {Component} from 'react';
import './App.css';
import createSagaMiddleware from 'redux-saga'
import {IndexSagas} from './index-sagas';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {applyMiddleware, createStore, compose} from 'redux'
import {Provider} from 'react-redux'
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'
import ReduxToastr from 'react-redux-toastr'
import {MenuHome} from "./components/menu-home/menuHome";
import LoginBack from "./components/login/login";
import Routes from './commons/routes/routes';
import OrdersComponent from "./components/2-Oders/index";
import ProductComponent from "./components/1-Product-supply/index";
import SupplyComponent from "./components/1-Product-supply/Supply/index";
import {IndexReducer} from "./index-reducer";
import PrivateRoute from "./components/privateRoutes/private";


const composeSetup = process.env.NODE_ENV !== 'production' && typeof window === 'object' &&
window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;

const sagaMiddleware = createSagaMiddleware();


const store = createStore(
    IndexReducer,
    composeSetup(applyMiddleware(sagaMiddleware)),
);

sagaMiddleware.run(IndexSagas);


class App extends Component {
    render() {
        return (
            <Provider store={store}>
                <Router>
                    <div className="App">
                        <ReduxToastr
                            timeOut={10000}
                            newestOnTop={false}
                            preventDuplicates
                            position="top-right"
                            transitionIn="bounceInDown"
                            transitionOut="bounceOutUp"
                            progressBar
                            closeOnToastrClick/>
                        <Routes/>
                        <Switch>
                            <Route path={'/'} exact component={LoginBack}/>
                            <PrivateRoute path={'/productos'} component={ProductComponent}/>
                            <PrivateRoute path={'/insumos'} component={SupplyComponent}/>
                            <PrivateRoute path={'/ordenes'} component={OrdersComponent}/>
                        </Switch>

                    </div>
                </Router>
            </Provider>
        );
    }
}

export default App;


