import React from 'react';
import ImgLogin3 from "../../../assets/img/candado-registro.png";
import {connect} from "react-redux";
import {reduxForm, Field, reset} from "redux-form";
import {sendEmailRequesting, verifyTokenRequesting, resetPasswordRequesting} from "../redux/actions";
import ImgLogin4 from "../../../assets/img/token.png";

class ModalPassword extends React.Component {

    handleSendEmail = (values) => {
        const {sendEmailRequesting} = this.props;
        sendEmailRequesting(values);
    };

    handleVerifyToken = (values) => {
        const {verifyTokenRequesting} = this.props;
        verifyTokenRequesting(values);
    };

    handleResetPassword = (values, dispatch) => {
        const {resetPasswordRequesting} = this.props;
        resetPasswordRequesting(values);
    };

    // *******************************
    stopPropagation = (e) => {
        e.stopPropagation();
    };

    componentWillUpdate(nextProps, nextState, nextContext) {
        if(nextProps.login.successReset && !this.props.login.successReset){
            this.props.dispatch(reset('resetPassword'));
        }
    }

    render() {
        const {
            handleSubmit,
            login: {verify, send, reset}
        } = this.props;
        if(send) {
        return (
            <div className={'body-blur'} onClick={this.props.hidden.bind(this)}>
                <div className="password-modal" onClick={this.props.stop.bind(this)}>

                    <div className="button-container-close">
                        <button className="Btn-modal-cancel " onClick={this.props.hidden.bind(this)}><i
                            className="icon-cancel"/>
                        </button>
                    </div>
                    <div className="item-modal-img">
                        <img src={ImgLogin3} alt=""/>
                    </div>
                    <div className="text-modal">
                        <h1>Recuperar contraseña</h1>
                    </div>
                    <form onSubmit={handleSubmit(this.handleSendEmail)}>
                    <div id="item-modal-p">
                        <p>Te enviaremos un correo para recuperar tu contraseña</p>
                        <Field type="text" component={'input'}
                               name={'email'}
                               className="input input-name" placeholder={'Digita tu correo de registro'}/>
                    </div>

                    <div className="button-container">
                        <div className="btn-container">
                            <button className="Btn-modal" onClick={this.props.hidden.bind(this)}>Cancelar<i className="icon-cancel styles-icons"/></button>
                            <button className="Btn-modal">Enviar<i className="icon-ok styles-icons"/></button>
                        </div>
                    </div>
                    </form>
                </div>
            </div>
        )
        }
        if(verify){
            return (
                <div className={'body-blur'} onClick={this.props.hidden.bind(this)}>
                    <div className="password-modal" onClick={this.props.stop.bind(this)}>

                        <div className="button-container-close">
                            <button className="Btn-modal-cancel " onClick={this.props.hidden.bind(this)}><i
                                className="icon-cancel"/>
                            </button>
                        </div>

                        <div className="item-modal-img">
                            <img src={ImgLogin4} alt=""/>
                        </div>
                        <div className="text-modal">
                            <h1>Validar token</h1>
                        </div>
                        <form onSubmit={handleSubmit(this.handleVerifyToken)}>
                            <div id="item-modal-p">
                                <p>Ingresa tu codigo de verificación</p>
                                <Field type="text" component={'input'}
                                       name={'token'}
                                       className="input input-name" placeholder={'Codigo de verificación'}/>

                            </div>

                            <div className="button-container">
                                <div className="btn-container">

                                    <button className="Btn-modal">Validar<i className="icon-ok styles-icons"/></button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            )
        }
        if(reset){
            return (
                <div className={'body-blur'} onClick={this.props.hidden.bind(this)}>
                    <div className="password-modal" onClick={this.props.stop.bind(this)}>

                        <div className="button-container-close">
                            <button className="Btn-modal-cancel " onClick={this.props.hidden.bind(this)}><i
                                className="icon-cancel"/>
                            </button>
                        </div>
                        <div className="item-modal-img">
                            <img src={ImgLogin3} alt=""/>
                        </div>
                        <div className="text-modal">
                            <h1>Ingresa una nueva contraseña</h1>
                        </div>
                        <form onSubmit={handleSubmit(this.handleResetPassword)}>
                        <div id="item-modal-p">
                            <p>Ingresa tu nueva contraseña</p>
                            <Field type="password" component={'input'}
                                   name={'contraseña'}
                                   className="input input-name" placeholder={'Digite una nueva contraseña'}/>
                            <p>Confirmar tu contraseña</p>
                            <Field type="password" component={'input'}
                                   name={'re_contraseña'}
                                   className="input input-name" placeholder={'Confirme la contraseña'}/>
                        </div>
                        <div className="button-container">
                            <div >
                                <button className="Btn-modal">Cambiar<i className="icon-ok styles-icons"/></button>
                            </div>
                        </div>
                        </form>
                    </div>
                </div>
            )
        }
    }
}

const formed = reduxForm({
    form: 'resetPassword',
    enableReinitialize: true
})(ModalPassword);

const mapStateToProps = state => ({
    login: state.login,
});

const connected = connect(mapStateToProps, {
    sendEmailRequesting,
    verifyTokenRequesting,
    resetPasswordRequesting
})(formed);

export default connected;
