import {call, put, all, takeEvery} from 'redux-saga/effects';
import {CHECK_AUTH} from "./constans";
import {checkAuthError, checkAuthSuccess} from "./actions";
import {handleApiErrors} from "../errors/apiErrors";
import {meGetRequesting} from "../../components/login/redux/actions";

const checkJWT = `${process.env.REACT_APP_API_URL}/api/verifyTokenProvider`;

const checkTokenJWT = () => {
    let token = localStorage.getItem('@ingetem:provider');
    return fetch(checkJWT, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer' + token,
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if(json.data.data === "Token invalido") {
                const haveLocalKey = localStorage.getItem('@ingetem:provider')
                if(haveLocalKey) {
                    localStorage.removeItem('@ingetem:provider')
                }
                throw "Tu cuenta ha sido deshabilitada"
            } 
            if (json.code === 400)
                throw json.data.data;
            if (json.code === 200 && json.data.data !== "Token invalido")
                return token

        }).catch((error) => {
            throw error;
        })
};


function* checkAuthFlow() {
    try {
        const token = yield call(checkTokenJWT);
        yield put(checkAuthSuccess(token));
        yield put(meGetRequesting(token));
    } catch (error) {
        yield put(checkAuthError(error))
    }
}

function* checkAuthWatcher() {
    yield all([
        takeEvery(CHECK_AUTH, checkAuthFlow)
    ])
}

export default checkAuthWatcher;