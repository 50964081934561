import React from 'react';


export class FilterComponent extends React.Component {

    render_create = (type) => {
        if (type !== 'Ordenes') {
            return (
                <div className="div-btn-add">
                    < button onClick={this.props.handleShowModal.bind(this, this.props.type)}
                             className="Btn-general-add">+
                        Agregar {this.props.type}<i
                            className="icon-ok styles-icons"/></button>
                </div>
            );
        } else {
            return null;
        }
    };

    render() {
        const {states, getData, handleGetCsv, handleFilter, type} = this.props;
        return (
            <div className="Btn-general-filter">
                <div className={'div-btn-filter'}></div>
                <div className="container-add-direction">
                    <div className="div-btn-filter">
                        <select name="" onChange={(e) => handleFilter(e.target.value)}>
                            <option value="Todos">Todos</option>
                            {states && states.map((state, index) =>
                                <option value={state.nombre} key={index}>{state.nombre}</option>
                            )}
                        </select>
                    </div>
                    {this.render_create(type)}
                    <div className="div-btn-add">
                        <button onClick={() => handleGetCsv()} className="Btn-general-add">Descargar csv<i
                            className="icon-folder-open-empty styles-icons"/></button>
                    </div>
                    <div className="div-btn-direction">
                        <button className="Btn-general-direction" onClick={() => getData(-1)}><i
                            className="icon-left-big styles-icons"/>Atrás
                        </button>
                        <button className="Btn-general-direction" onClick={() => getData(1)}>Adelante<i
                            className="icon-right-big styles-icons"/></button>
                    </div>
                </div>
            </div>
        )
    }
}