import React from 'react';
import connect from "react-redux/es/connect/connect";
import {checkAuth} from "../provider/actions";

import {getStatesRequesting} from "../states/actions";
import MenuHome from "../../components/menu-home/menuHome";
import {meGetRequesting} from "../../components/login/redux/actions";

class RoutesComponent extends React.Component {

    componentWillMount() {
        const {checkAuth, getStatesRequesting, provider, meGetRequesting} = this.props;
        getStatesRequesting(provider.token);
    }

    render() {
        return (
            <div>
                <MenuHome/>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    provider: state.provider
});

const connected = connect(mapStateToProps, {
    checkAuth,
    getStatesRequesting,
    meGetRequesting,
})(RoutesComponent);

export default connected;