import {PROVIDER_GET_ERROR, PROVIDER_GET_REQUESTING, PROVIDER_GET_SUCCESS} from "./constants";


const initialState = {
    requesting: false,
    success: false,
    error: '',
    message: '',
    providers: [],
};

const reducer = (state= initialState, action)=>{
    switch (action.type){
        case PROVIDER_GET_REQUESTING:
            return{
                ...state,
                providers:[],
            };
        case PROVIDER_GET_SUCCESS:
            return {
                ...state,
                providers: action.providers,
            };
        case PROVIDER_GET_ERROR:
            return {
                ...state,
                error: action.error,
            };
        default:
            return state;
    }
};
export default reducer