import {TYPE_SUBCATEGORY_ERROR, TYPE_SUBCATEGORY_REQUESTING, TYPE_SUBCATEGORY_SUCCESS} from "./constants";

export const typeSubcategoryRequesting = (token) => ({
   type: TYPE_SUBCATEGORY_REQUESTING,
   token
});
export const typeSubcategorySuccess = (typeSubcategories) => ({
    type: TYPE_SUBCATEGORY_SUCCESS,
    typeSubcategories
});
export const typeSubcategoryError = (error) => ({
    type: TYPE_SUBCATEGORY_ERROR,
    error
});