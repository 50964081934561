import React from 'react';
import {connect} from 'react-redux';
import {Field} from 'redux-form';

class CitySelectComponent extends React.Component{

    render(){
        const {
            login: {user},
        } = this.props;
        let cities = user.hasOwnProperty('datos_pais')
            ? user.datos_pais.ciudades.data.length > 0
                ? user.datos_pais.ciudades.data
                : []
            : []
        ;
        return(
            <Field name="city" type="select" className="select-category" component="select">
                {user.hasOwnProperty('ciudad')
                    ? <option value={user.ciudad}>{user.ciudad}</option>
                    : <option>Seleccione</option>
                }
                {cities.length > 0 && cities.map((city, index) => (
                    <option key={index} value={city.nombre}>{city.nombre}</option>
                ))}
            </Field>
        )
    }
}

const mapStateToProps = state => ({
    login: state.login,
});

const connected = connect(mapStateToProps,{
})(CitySelectComponent);

export default connected;