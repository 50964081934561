import {BRANDS_GET_REQUESTING, BRANDS_GET_SUCCESS, BRANDS_GET_ERROR} from "./constants";

export const brandsGet = (token) => ({
    type: BRANDS_GET_REQUESTING,
    token
});

export const brandsGetSuccess = (brands) => ({
    type: BRANDS_GET_SUCCESS,
    brands
});

export const brandsGetError = (error) =>({
    type: BRANDS_GET_ERROR,
    error
});