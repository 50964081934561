import {call, put, takeEvery, all} from 'redux-saga/effects';
import {handleApiErrors} from "../../../commons/errors/apiErrors";

import {PAYMENT_METHOD_GET_REQUESTING} from "./constants";

import {
    paymentMethodGetSuccess,
    paymentMethodGetError
} from "./actions";

const paymentMethodURL = `${process.env.REACT_APP_API_URL}/api/payments`;

function paymentMethodsGetApi(token){

    const URL = `${paymentMethodURL}`;

    return fetch(URL,{
        method: 'GET',
        headers: {
            Authorization: 'Bearer' + token,
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json=>{
            if (json.data.data.length > 0){
                return json.data.data;
            }else{
                throw json;
            }
        }).catch((error)=>{
            throw error
        })
}

function* paymentMethodGetFlow(action){
    try {
        const {token} = action;
        const getPaymentMethod = yield call(paymentMethodsGetApi, token);
        yield put(paymentMethodGetSuccess(getPaymentMethod));
    }catch (error) {
        yield put(paymentMethodGetError(error))
    }
}

function* paymentMethodWatcher() {
    yield all([
        takeEvery(PAYMENT_METHOD_GET_REQUESTING, paymentMethodGetFlow),
    ])
}

export default paymentMethodWatcher;