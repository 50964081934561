import {
    LOGIN_ERROR,
    LOGIN_SUCCESS,
    LOGIN_REQUESTING,
    ME_GET_REQUESTING,
    ME_GET_SUCCESS,
    ME_GET_ERROR,
    LOGOUT_REQUESTING,
    LOGOUT_SUCCESS,
    LOGOUT_ERROR,
    SEND_EMAIL_REQUESTING,
    SEND_EMAIL_SUCCESS,
    SEND_EMAIL_ERROR,
    VERIFY_TOKEN_REQUESTING,
    VERIFY_TOKEN_SUCCESS,
    VERIFY_TOKEN_ERROR,
    RESET_PASSWORD_REQUESTING,
    RESET_PASSWORD_SUCCESS, RESET_PASSWORD_ERROR, MODAL_PASSWORD
} from './constants';

export const loginRequesting = (email, password) => ({
    type: LOGIN_REQUESTING,
    email,
    password,
});

export const loginError = (error) => ({
    type: LOGIN_ERROR,
    error
});

export const loginSuccess = () => ({
    type: LOGIN_SUCCESS
});

export const meGetRequesting = (token) => ({
    type: ME_GET_REQUESTING,
    token,
});

export const meGetSuccess = (user) => ({
    type: ME_GET_SUCCESS,
    user,
});

export const meGetError = (error) => ({
    type: ME_GET_ERROR,
    error,
});

export const logoutRequesting = (token) => ({
    type: LOGOUT_REQUESTING,
    token,
});

export const logoutSuccess = () => ({
    type: LOGOUT_SUCCESS,
});

export const logoutError = (error) => ({
    type: LOGOUT_ERROR,
    error,
});

export const sendEmailRequesting = (values) => ({
    type: SEND_EMAIL_REQUESTING,
    values
});

export const sendEmailSuccess = (message) => ({
    type: SEND_EMAIL_SUCCESS,
    message
});

export const sendEmailError = (error) => ({
    type: SEND_EMAIL_ERROR,
    error
});

export const verifyTokenRequesting = (values) => ({
    type: VERIFY_TOKEN_REQUESTING,
    values
});

export const verifyTokenSuccess = (message) => ({
    type: VERIFY_TOKEN_SUCCESS,
    message
});

export const verifyTokenError = (error) => ({
    type: VERIFY_TOKEN_ERROR,
    error
});

export const resetPasswordRequesting = (values) => ({
    type: RESET_PASSWORD_REQUESTING,
    values
});

export const resetPasswordSuccess = (message) => ({
    type: RESET_PASSWORD_SUCCESS,
    message
});

export const resetPasswordError = (error) => ({
    type: RESET_PASSWORD_ERROR,
    error
});

export const modalPassword = (modal) => ({
    type: MODAL_PASSWORD,
    modal
});