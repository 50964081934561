import {
    SET_FOCUS_SUPPLY,
    SET_FOCUS_SUPPLY_PRODUCT,
    GET_SUPPLY_ERROR,
    GET_SUPPLY_REQUESTING,
    GET_SUPPLY_SUCCESS,
    SUPPLIES_PRODUCT_GET_ERROR,
    SUPPLIES_PRODUCT_GET_REQUESTING,
    SUPPLIES_PRODUCT_GET_SUCCESS,
    SUPPLY_CHANGE_STATE_ERROR,
    SUPPLY_CHANGE_STATE_REQUESTING,
    SUPPLY_CHANGE_STATE_SUCCESS,
    SUPPLY_CREATE_ERROR,
    SUPPLY_CREATE_REQUESTING,
    SUPPLY_CREATE_SUCCESS,
    SUPPLY_PRODUCT_CREATE_ERROR,
    SUPPLY_PRODUCT_CREATE_REQUESTING,
    SUPPLY_PRODUCT_CREATE_SUCCESS, SUPPLY_PRODUCT_UPDATE_ERROR,
    SUPPLY_PRODUCT_UPDATE_REQUESTING, SUPPLY_PRODUCT_UPDATE_SUCCESS,
    SUPPLY_SEARCH_ERROR,
    SUPPLY_SEARCH_REQUESTING,
    SUPPLY_SEARCH_SUCCESS,
    SUPPLY_UPDATE_ERROR,
    SUPPLY_UPDATE_REQUESTING,
    SUPPLY_UPDATE_SUCCESS,
    SUPPLIES_PRODUCTS_GET_ERROR,
    SUPPLIES_PRODUCTS_GET_REQUESTING,
    SUPPLIES_PRODUCTS_GET_SUCCESS,
    SET_FOCUS_SUPPLIES_PRODUCTS,
    SUPPLIES_PRODUCTS_CREATE_ERROR,
    SUPPLIES_PRODUCTS_CREATE_REQUESTING,
    SUPPLIES_PRODUCTS_CREATE_SUCCESS,
    SUPPLIES_PRODUCTS_UPDATE_ERROR,
    SUPPLIES_PRODUCTS_UPDATE_REQUESTING,
    SUPPLIES_PRODUCTS_UPDATE_SUCCESS, SUPPLY_RESET_STATES
} from "../Redux/constants";

const initialState = {
    requesting: false,
    success: false,
    error: '',
    paginate: 1,
    supplies: [],
    supplyDataFocus: {},
    focusSupply: 0,
    supplyProductDataFocus: {},
    focusSupplyProduct: 0,
    search:'',
    supply: [],
    filter: 'Todos',
    hasMore: true,
    hasLess: false,
    productSupplies: [],
    productSupply: [],
    update: false,
    created: false
};

const reducer = (state = initialState, action) => {
    switch (action.type){
        //obtener datos
        case GET_SUPPLY_REQUESTING:
            return {
                ...state,
                paginate: action.paginate,
                filter: action.filter
            };
        case GET_SUPPLY_SUCCESS:
            return {
                ...state,
                supplies: action.supplies,
                hasLess: state.paginate + 1 > 1,
                hasMore: true
            };
        case GET_SUPPLY_ERROR:

            return {
                ...state,
                error: action.error,
                hasMore: false,
                supplies: []
            };
        //crear datos
        case SUPPLY_CREATE_REQUESTING:
            return{
                ...state,
                requesting: true,
                success: false,
                error: '',
                created: false
            };
        case SUPPLY_CREATE_SUCCESS:
            return{
                ...state,
                requesting: false,
                success: true,
                created: true,
                supplies: [action.supplyCreated, ...state.supplies],
            };
        case SUPPLY_CREATE_ERROR:
            return{
                ...state,
                requesting: false,
                success: false,
                error: action.error,
                created: false
            };
        //actualizar datos supply
        case SET_FOCUS_SUPPLY:
            return{
                ...state,
                focusSupply: action.id,
                supplyDataFocus: action.supply,
            };
        case SUPPLY_UPDATE_REQUESTING:
            return{
                ...state,
                requesting: true,
                success: false,
                update: false,
                error: ''
            };
        case SUPPLY_UPDATE_SUCCESS:
            return{
                ...state,
                requesting: false,
                success: true,
                update: true,
                error: '',
                supply: state.supply.map((supply)=>supply.id === action.userSupply.id ? action.userSupply:supply),
                supplies: state.supplies.map((supply)=>supply.id === action.userSupply.id ? action.userSupply:supply)
            };
        case SUPPLY_UPDATE_ERROR:

            return{
                ...state,
                requesting: false,
                success: false,
                update: false,
                error: action.error
            };

        //actualizar estado
        case SUPPLY_CHANGE_STATE_REQUESTING:
            return{
                ...state,
                requesting: false,
                supplyId: action.supplyId
            };
        case SUPPLY_CHANGE_STATE_SUCCESS:
            return{
                ...state,
                requesting: false,
                supplies: state.supplies.map(supplyItem => (
                    supplyItem.id === action.supply.id ? action.supply : supplyItem
                )),
                supply: state.supply.map(supplyItem => (
                    supplyItem.id === action.supply.id ? action.supply : supplyItem
                )),
            };
        case SUPPLY_CHANGE_STATE_ERROR:
            return{
                ...state,
                requesting: false,
                error:''
            };
        //consultar dato
        case SUPPLY_SEARCH_REQUESTING:
            return{
                ...state,
                search: action.search
            };
        case SUPPLY_SEARCH_SUCCESS:
            return{
                ...state,
                supply: action.supply
            };
        case SUPPLY_SEARCH_ERROR:
            return{
                ...state,
                supply: []
            };


        case SUPPLIES_PRODUCT_GET_REQUESTING:
            return {
                ...state,
                requesting: true,
                success: false,
                error:''
            };
        case SUPPLIES_PRODUCT_GET_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                error:'',
                productSupplies: action.productSupply,
            };
        case SUPPLIES_PRODUCT_GET_ERROR:
            return {
                ...state,
                requesting: false,
                success: false,
                error: action.error,
                productSupplies: []
            };
        //crear datos
        case SUPPLY_PRODUCT_CREATE_REQUESTING:
            return{
                ...state,
                requesting: true,
                success: false,
                error: ''
            };
        case SUPPLY_PRODUCT_CREATE_SUCCESS:
            return{
                ...state,
                requesting: false,
                success: true,
                error: '',
                productSupplies: [action.supplyProductCreated, ...state.productSupplies],
            };
        case SUPPLY_PRODUCT_CREATE_ERROR:
            return{
                ...state,
                requesting: false,
                success: false,
                error: action.error
            };
        //actualizar datos
        case SET_FOCUS_SUPPLY_PRODUCT:
            return{
                ...state,
                focusSupplyProduct: action.id,
                supplyProductDataFocus: action.productSupplies,
            };
        case SUPPLY_PRODUCT_UPDATE_REQUESTING:
            return{
                ...state,
                requesting: true,
                success: false,
                error: ''
            };
        case SUPPLY_PRODUCT_UPDATE_SUCCESS:
            let positions = state.supplies.indexOf(state.supplyDataFocus);
            return{
                ...state,
                requesting: false,
                success: true,
                error: '',
                productSupply: state.productSupply.map((productSupply,index)=> index === positions ? action.productSupply : productSupply),
            };
        case SUPPLY_PRODUCT_UPDATE_ERROR:
            return{
                ...state,
                requesting: false,
                success: false,
                error: action.error
            };

             //obtener datos
        case SUPPLIES_PRODUCTS_GET_REQUESTING:
        return {
            ...state,
            requesting: true,
            success: false,
            error: ''
        };
    case SUPPLIES_PRODUCTS_GET_SUCCESS:
        return {
            ...state,
            requesting: false,
            success: true,
            error: '',
            productSupplies: action.productSupplies,
        };
    case SUPPLIES_PRODUCTS_GET_ERROR:
        return {
            ...state,
            requesting: false,
            success: false,
            error: action.error,
            productSupplies: []
        };



        //crear datos
        case SUPPLIES_PRODUCTS_CREATE_REQUESTING:
            return{
                ...state,
                requesting: true,
                success: false,
                error: ''
            };
        case SUPPLIES_PRODUCTS_CREATE_SUCCESS:
            return{
                ...state,
                requesting: false,
                success: true,
                error: '',
                productSupplies: [action.productSuppliesCreated, ...state.productSupplies],
            };
        case SUPPLIES_PRODUCTS_CREATE_ERROR:
            return{
                ...state,
                requesting: false,
                success: false,
                error: action.error
            };
        //actualizar datos
        case SET_FOCUS_SUPPLIES_PRODUCTS:
            return{
                ...state,
                supplyDataFocus: action.supply,
            };
        case SUPPLIES_PRODUCTS_UPDATE_REQUESTING:
            return{
                ...state,
                requesting: true,
                success: false,
                error: ''
            };
        case SUPPLIES_PRODUCTS_UPDATE_SUCCESS:
            let positionSuppliesProduct = state.supplies.productSupplies.indexOf(state.suppliesProductsDataFocus);
            return{
                ...state,
                requesting: false,
                success: true,
                error: '',
                productSupplies: state.productSupplies.map((productSupplies,index)=> index === positionSuppliesProduct ? action.productSupplies : productSupplies),
            };
        case SUPPLIES_PRODUCTS_UPDATE_ERROR:
            return{
                ...state,
                requesting: false,
                success: false,
                error: action.error
            };
        case SUPPLY_RESET_STATES:
            return {
                ...state,
                requesting: false,
                success: false,
                error: '',
                focusSupply: 0,
                supplyProductDataFocus: {},
                focusSupplyProduct: 0,
                update: false,
                created: false
            };
        default:
            return state;
    }
};

export default reducer
