import {
    RESET_STATES,
    USER_PASSWORD_PUT_ERROR,
    USER_PASSWORD_PUT_REQUESTING,
    USER_PASSWORD_PUT_SUCCESS,
    USER_PUT_ERROR,
    USER_PUT_REQUESTING,
    USER_PUT_SUCCESS
} from "./constants";

const initialState = {
    requesting: false,
    success: false,
    error: '',
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case USER_PUT_REQUESTING:
            return {
                ...state,
                requesting: true,
                success: false,
            };
        case USER_PUT_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
            };
        case USER_PUT_ERROR:
            return {
                ...state,
                requesting: false,
                error: action.error,
            };
        case USER_PASSWORD_PUT_REQUESTING:
            return {
                ...state,
                requesting: true,
                success: false,
            };
        case USER_PASSWORD_PUT_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
            };
        case USER_PASSWORD_PUT_ERROR:
            return {
                ...state,
                requesting: false,
                error: action.error,
            };
        case RESET_STATES:
            return {
                ...state,
                requesting: false,
                success: false,
                error: '',
            };
        default:
            return state;
    }
};

export default reducer