import {
    RESET_STATES,
    USER_PASSWORD_PUT_ERROR,
    USER_PASSWORD_PUT_REQUESTING,
    USER_PASSWORD_PUT_SUCCESS,
    USER_PUT_ERROR,
    USER_PUT_REQUESTING,
    USER_PUT_SUCCESS
} from "./constants";

export const userPutRequesting = (token, values) => ({
    type: USER_PUT_REQUESTING,
    token, values
});

export const userPutSuccess = () => ({
    type: USER_PUT_SUCCESS,
});

export const userPutError = (error) => ({
    type: USER_PUT_ERROR,
    error,
});

export const userPasswordPutRequesting = (token, values, id) => ({
    type: USER_PASSWORD_PUT_REQUESTING,
    token, values, id
});

export const userPasswordPutSuccess = () => ({
    type: USER_PASSWORD_PUT_SUCCESS,
});

export const userPasswordPutError = (error) => ({
    type: USER_PASSWORD_PUT_ERROR,
    error,
});

export const resetStates = () => ({
    type: RESET_STATES,
});