import {PROJECTS_GET_ERROR, PROJECTS_GET_REQUESTING, PROJECTS_GET_SUCCESS} from "./constants";

export const projectsGetRequesting = (token) => ({
    type: PROJECTS_GET_REQUESTING,
    token,
});

export const projectsGetSuccess = (projects) => ({
    type: PROJECTS_GET_SUCCESS,
    projects,
});

export const projectsGetError = (error) => ({
    type: PROJECTS_GET_ERROR,
    error
});