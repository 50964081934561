import React from 'react';
import {connect} from 'react-redux';
import Logo from '../../assets/logos/logo-ingetem1.png';
import {Field, reduxForm} from 'redux-form';
import {Redirect} from 'react-router-dom';
import {ErrorComponent} from "../../commons/errors/errors";
import ImgLogin2 from './../../assets/img/logo-no-text.png';
import ImgLogin3 from './../../assets/img/logo-no-text.png';
import ModalPassword from "./modals/forgot-password";
import {loginRequesting, modalPassword} from './redux/actions';
import {toastr} from 'react-redux-toastr'
import {checkAuth} from "../../commons/provider/actions";

class LoginBack extends React.Component {

    state = {
        isPassword : false,
    };

    componentWillMount () {
        const {checkAuth} = this.props;
        checkAuth();
    }

     componentWillUpdate(nextProps) {

         const {login} = this.props;

         if (nextProps.login.error !== '' && nextProps.login.error !== login.error) {
             let toastOptions = {
                 component: (
                     <ErrorComponent message={nextProps.login.error}/>
                 )
             };
             toastr.error('Error', toastOptions);

         }
     }

    handleLogin = (values) => {
        const {loginRequesting} = this.props;
        loginRequesting(values.email, values.password);
    };


     handleShowModal = () => {
        const {modalPassword} = this.props;
        modalPassword(true);
        /* this.setState({
            isPassword : modal === 'contraseña',
        })*/
    };

    handleHiddenModal = () => {
        const {modalPassword} = this.props;
        modalPassword(false);
      /*  this.setState({
            isPassword :false,
        })*/
    };

    stopPropagation = (e) => {
        e.stopPropagation();
    };



    render() {
        const {
            handleSubmit,
            login,
            provider,
        } = this.props;

        console.log(login.success, provider)

        if (login.success || provider.isLogged)
            return <Redirect from="/" to={'/productos'}/>;

        // if (!provider.isLoad && provider.requesting) {
        //     return <div className={'animation'} ref={this.animation} style={{width: '100%', height: 500}}/>;
        // }

        return (
            <div>
                <div className="container-register">
                    <div className="container-info">
                        <div className="info">
                            <button href="#" className="title">INGETEM</button>
                            <div className="title-register">Administrador proveedor</div>
                            <br/>
                            <div className="step">
                                <span className="circle">1</span>
                                <h2>Gestiona entregas</h2>
                            </div>
                            <div className="step">
                                <span className="circle">2</span>
                                <h2>Controla tu negocio</h2>
                            </div>
                            <div className="step">
                                <span className="circle">3</span>
                                <h2>Administración de información</h2>
                            </div>
                        </div>
                    </div>
                    <div className="form">
                        <div id="form-register">
                            <img className="hiden" src={Logo} alt=""/>
                            <img className="hiden2" src={Logo} alt=""/>
                            <h1 className="title-form-h1">Iniciar sesión</h1>
                            <p>Administrador proveedor</p>
                            <form  onSubmit={handleSubmit(this.handleLogin)}>
                                <div className="input-group">
                                    <Field type="email" placeholder="Email" className="email" required component={'input'} name={'email'}/>
                                </div>
                                <div className="input-group">
                                    <Field type="password" placeholder="Contraseña" className="password" required component={'input'} name={'password'}/>
                                </div>
                                <div className="modal-error hidden ">
                                    <label className="incorrect">Datos incorrectos</label>
                                </div>
                                <button className="register">Ingresar<i className="icon-ok styles-icons"/></button>
                            </form>
                            <div className="separate">
                                <span onClick={this.handleShowModal.bind(this)} style={{color: 'black'}}>¿ Olvido su contraseña ?</span>
                            </div>
                            {login.modal && (
                               <ModalPassword
                                    stop={this.stopPropagation.bind(this)}
                                    hidden={this.handleHiddenModal.bind(this)}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>

        )


    }
}

// LoginBack.propTypes = {
//     handleSubmit: PropTypes.func.isRequired,
//     loginRequesting: PropTypes.func.isRequired,
//     login: PropTypes.object.isRequired
// };

const mapStateToProps = state => ({
    login: state.login,
    provider: state.provider
});

const connected = connect(mapStateToProps, {
    loginRequesting,
    checkAuth,
    modalPassword
})(LoginBack);

const formed = reduxForm({
    form: 'login'
})(connected);


export default formed