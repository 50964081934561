import {call, put, takeEvery, all} from 'redux-saga/effects';
import {handleApiErrors} from "../../../commons/errors/apiErrors";

import {PROVIDER_GET_REQUESTING} from "./constants";
import{
    providersGetSuccess,
    providersGetError
} from "./actions";

const providerURL = `${process.env.REACT_APP_API_URL}/api/providers`;

function providersGetApi(token){
    const URL = `${providerURL}/filter/Todos?page=1`;

    return fetch(URL, {
        method: 'GET',
        headers: {
            Authorization: 'Bearer' + token,
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json=>{
            if (json.data.data.length > 0){
                return json.data.data;
            }else{
                throw json;
            }
        }).catch((error)=>{
            throw error
        })
}

function* providersGetFlow(action){
    try {
        const {token} = action;
        const getProvider = yield call(providersGetApi,token);
        yield put(providersGetSuccess(getProvider));
    }catch (error) {
        yield put(providersGetError(error))
    }
}

function* providerWatcher(){
    yield all([
        takeEvery(PROVIDER_GET_REQUESTING, providersGetFlow)
    ])
}

export default providerWatcher;