import {CHECK_AUTH, CHECK_AUTH_ERROR, CHECK_AUTH_SUCCESS, PROVIDER_SET, PROVIDER_UNSET} from "./constans";


export const providerSet = (token) => ({
    type: PROVIDER_SET,
    token
});

export const providerUnSet = () => ({
    type: PROVIDER_UNSET
});

export const checkAuth = () => ({
    type: CHECK_AUTH
});

export const checkAuthError = () => ({
    type: CHECK_AUTH_ERROR
});

export const checkAuthSuccess = (token) => ({
    type: CHECK_AUTH_SUCCESS,
    token
});