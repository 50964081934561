import React from 'react';
import {toastr} from "react-redux-toastr";
import {Field, reduxForm} from 'redux-form';
import connect from "react-redux/es/connect/connect";
import {ErrorComponent} from "../../../commons/errors/errors";
import DocumentsInput from "../file-inputs/documents";
import {documentsPostRequesting} from "../Redux/actions";

class ModalNewDocument extends React.Component {

    componentWillUpdate(nextProps, nextState, nextContext) {
        const {product: {error, successDocument}} = this.props;

        if (nextProps.product.error !== '' && nextProps.product.error !== error) {
            let toastOptions = {
                component: (
                    <ErrorComponent message={nextProps.product.error}/>
                )
            };
            toastr.error('Error', toastOptions);
        }
        if (nextProps.product.successDocument && (successDocument !== nextProps.product.successDocument)) {
            toastr.success('Documento agregado', 'Se ha agregado el documento correctamente.');
            this.props.hidden();
        }
    }

    handleAction = (values) => {
        const {
            documentsPostRequesting,
            provider: {token},
            product: {productDataFocus}
        } = this.props;
        documentsPostRequesting(token, productDataFocus.id, values.documentos);
    };

    render() {
        const {
            handleSubmit,
            product: {requestingDocument}
        } = this.props;
        return (
            <div className={'body-blur'} onClick={this.props.hidden.bind(this)}>
                <div className="edit-modal" onClick={this.props.stop.bind(this)}>
                    <form onSubmit={handleSubmit(this.handleAction)}>
                        <div className="button-container-close">
                            <button className="Btn-modal-cancel " onClick={this.props.hidden.bind(this)}><i
                                className="icon-cancel"/>
                            </button>
                        </div>

                        <div className="text-modal">
                            <h1>Agregar nuevo documento</h1>
                        </div>
                        <div className="item-modal">
                            <label>Ficha tecnica (PDF):</label>
                            <Field name={'documentos'} maxFiles={1} className={'filepond'} component={DocumentsInput}/>
                        </div>

                        <div className="button-container">
                            <div className="btn-container">
                                <button className="Btn-modal" onClick={this.props.hidden.bind(this)}>
                                    Cancelar
                                    <i className="icon-cancel styles-icons"/>
                                </button>
                                <button className="Btn-modal" disabled={requestingDocument} action="submit">
                                    Guardar
                                    <i className="icon-ok styles-icons"/>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

const formed = reduxForm({
    form: 'NewDocument',
    enableReinitialize: true
})(ModalNewDocument);

const mapStateToProps = state => ({
    provider: state.provider,
    product: state.product,
});

const connected = connect(mapStateToProps, {
    documentsPostRequesting,
})(formed);

export default connected