import React from 'react';
import {connect} from 'react-redux';
import {Field} from 'redux-form';
import {paymentMethodGetRequesting} from "./redux/actions";

class PaymentMethodComponent extends React.Component{
    componentWillMount(){
        const {
            provider,
            paymentMethodGetRequesting,
        }= this.props;
        paymentMethodGetRequesting(provider.token);
    }

    render(){
        const {
            paymentMethods,
            InitialPaymentMethod
        } = this.props;
        return(
            <Field name="metodo_pago" type="select" className="select-payment" component="select">
                {InitialPaymentMethod ? <option value={InitialPaymentMethod.id}>{InitialPaymentMethod.nombre}</option> :
                    <option>...Seleccione</option>}
                {paymentMethods.length > 0 && paymentMethods.map((paymentMethod, index)=>{
                    if (InitialPaymentMethod===undefined)
                        return <option key={index} value={paymentMethod.id}>{paymentMethod.nombre}</option>
                    else
                    if (paymentMethod.id !== InitialPaymentMethod.id)
                        return <option key={index} value={paymentMethod.id}>{paymentMethod.nombre}</option>
                })}
            </Field>
        )
    }
}

const mapStateToProps = state => ({
   provider: state.provider,
   paymentMethods: state.paymentMethods.paymentMethods,
});

const connected = connect(mapStateToProps,{
    paymentMethodGetRequesting
})(PaymentMethodComponent);

export default connected;