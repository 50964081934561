import {call, put, takeEvery, all} from 'redux-saga/effects';
import {handleApiErrors} from "../../../commons/errors/apiErrors";
import {userPasswordPutError, userPasswordPutSuccess, userPutError, userPutSuccess} from "./actions";
import {meGetSuccess} from "../../../components/login/redux/actions";
import {USER_PASSWORD_PUT_REQUESTING, USER_PUT_REQUESTING} from "./constants";

const providersURL = `${process.env.REACT_APP_API_URL}/api/providers`;

const userPutApi = (token, values) => {
    let url = `${providersURL}${'Update'}/${values.id}`;
    let body = new FormData();
    body.append('_method', 'put');
    body.append('phone', values.telefono || '');
    body.append('mobile', values.celular || '');
    body.append('address', values.direccion || '');
    body.append('city', values.ciudad || '');
    if (values.photo) {
        body.append('photo', values.photo[0].file || '');
    }
    return fetch(`${url}`, {
        method: 'POST',
        headers: {
            Authorization: 'Bearer' + token,
        },
        body: body
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.code === 200)
                return json.data.data;
            throw json.data;
        }).catch((error) => {
            throw error;
        })
};

function* userPutFlow(action) {
    try {
        const {token, values} = action;
        const user = yield call(userPutApi, token, values);
        yield put(userPutSuccess());
        yield put(meGetSuccess(user));
    } catch (error) {
        yield put(userPutError(error));
    }
}

const userPasswordPutApi = (token, values, id) => {
    console.log(values, id);
    let url = `${providersURL}${'ChangePassword'}/${id}`;
    let body = {
        password: values.password,
        password_confirmation: values.rePassword,
    };
    return fetch(`${url}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer' + token
        },
        body: JSON.stringify(body)
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.code === 200)
                return json.data.data;
            throw json.data;
        }).catch((error) => {
            throw error;
        })
};

function* userPasswordPutFlow(action) {
    try {
        const {token, values, id} = action;
        yield call(userPasswordPutApi, token, values, id);
        yield put(userPasswordPutSuccess());
    } catch (error) {
        yield put(userPasswordPutError(error));
    }
}

function* userWatcher() {
    yield all([
        takeEvery(USER_PUT_REQUESTING, userPutFlow),
        takeEvery(USER_PASSWORD_PUT_REQUESTING, userPasswordPutFlow),
    ]);
}

export default userWatcher;