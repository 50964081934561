import React from 'react';
import {Field, reduxForm} from 'redux-form';
import connect from "react-redux/es/connect/connect";
import {toastr} from "react-redux-toastr";
import * as Ladda from "ladda";
import {ErrorComponent} from "../../../commons/errors/errors";
import {updateProductsRequesting} from "../Redux/actions";
import BrandComponent from './../../brand/index';
import CategoryComponent from './../../category/index';
import ProjectComponent from '../../project';
import ImagesInput from "../file-inputs/images";

class ModalEditProduct extends React.Component {

    constructor(props) {
        super(props);
        this.buttonEdit = React.createRef();
    }

    componentWillUpdate(nextProps) {
        const {product} = this.props;
        let button = null;

        if (this.buttonEdit.current !== null)
            button = Ladda.create(this.buttonEdit.current);

        if (nextProps.product.requesting)
            button.start();

        if (nextProps.product.error !== '' && nextProps.product.error !== product.error) {
            let toastOptions = {
                component: (
                    <ErrorComponent message={nextProps.product.error}/>
                )
            };
            toastr.error('Recuerda', toastOptions);
            button.stop();
        }
        if (nextProps.product.success) {
            button.stop();
        }

        if (nextProps.product.success && !product.success) {
            toastr.success('Producto Actualizado', 'Los datos han sido actualizados correctamente');
            this.props.hidden();
        }
    }

    handleAction = (values) => {
        const {provider, updateProductsRequesting, product: {focusProduct}} = this.props;
        updateProductsRequesting(provider.token, values, focusProduct);
    };

    render() {
        const {
            handleSubmit,
            initialValues,
        } = this.props;
        return (
            <div className={'body-blur'} onClick={this.props.hidden.bind(this)}>
                <div className="edit-modal" onClick={this.props.stop.bind(this)}>
                    <div className="button-container-close">
                        <button className="Btn-modal-cancel " onClick={this.props.hidden.bind(this)}><i
                            className="icon-cancel"/>
                        </button>
                    </div>
                    <div className="text-modal">
                        <h1>Editar producto</h1>
                    </div>
                    <div className="text-modal">
                        <h2>Información de producto</h2>
                    </div>
                    <form onSubmit={handleSubmit(this.handleAction)}>
                        <div className="item-modal">
                            <label>Fotos e imagenes:</label>
                            <Field name={'fotos'} maxFiles={6} className={'filepond'} component={ImagesInput}/>
                        </div>
                        <div className="item-modal">
                            <label>Nombre:</label>
                            <Field name='nombre' className='input input_city' component={'input'}/>
                        </div>
                        <div className="item-modal">
                            <label>Referencia:</label>
                            <Field name={'referencia'} className='input input_city' component={'input'}/>
                        </div>
                        <div className="item-modal">
                            <label>Descripción:</label>
                            <Field name={'descripcion'} className='input input_city' component={'input'}/>
                        </div>
                        <div className="item-modal">
                            <label>Medidas:</label>
                            <Field name={'medidas'} type={'text'} className='input input_city' component={'input'}/>
                        </div>

                        {/*<div className="item-modal">*/}
                            {/*<label>Precio:</label>*/}
                            {/*<Field name={'precio'} type={'number'} className='input input_city' component={'input'}/>*/}
                        {/*</div>*/}

                        {/*<div className="item-modal">*/}
                            {/*<label>Precio gerente lider:</label>*/}
                            {/*<Field name={'precio_gerente_lider'} type={'number'} className='input input_city'*/}
                                   {/*component={'input'}/>*/}
                        {/*</div>*/}

                        <div className="item-modal">
                            <label>Precio envio:</label>
                            <Field name={'precio_envio'} type={'number'} className='input input_city'
                                   component={'input'}/>
                        </div>

                        <div className="item-modal">
                            <label>Tiempo de envio:</label>
                            <Field name={'tiempo_envio'} type={'number'} className='input input_city'
                                   component={'input'}/>
                        </div>

                        {/*<div className="item-modal">*/}
                            {/*<label>Precio descuento:</label>*/}
                            {/*<Field name={'precio_descuento'} type={'number'} className='input input_city'*/}
                                   {/*component={'input'}/>*/}
                        {/*</div>*/}

                        {/*<div className="item-modal">*/}
                            {/*<label>Cantidad de unidades disponibles:</label>*/}
                            {/*<Field name={'inventario_disponible'} type={'number'} className='input input_city'*/}
                                   {/*component={'input'}/>*/}
                        {/*</div>*/}

                        {/*<div className="item-modal">*/}
                            {/*<label>El producto se deshabilita cuando el inventario llega a:</label>*/}
                            {/*<Field name={'limite_inventario'} type={'number'} className='input input_city'*/}
                                   {/*component={'input'}/>*/}
                        {/*</div>*/}

                        <div className="item-modal">
                            <label>Marca:</label>
                            <BrandComponent
                                InitialMarca={initialValues.marca}
                                name={'marca'}
                            />
                        </div>

                        <div className="item-modal">
                            <label>Categoría:</label>
                            <CategoryComponent
                                InitialCategory={initialValues.categoria}
                                name={'marca'}
                            />
                        </div>

                        <div className="item-modal">
                            <label>Proyecto:</label>
                            <ProjectComponent
                                InitialPaymentMethod={initialValues.proyecto}
                                name={'proyecto'}
                            />
                        </div>

                        <div className="button-container">
                            <div className="ladda-button btn-container"  data-style="expand-left">
                                <button className="Btn-modal"  onClick={this.props.hidden.bind(this)}>
                                    Cancelar
                                    <i className="icon-cancel styles-icons"/>
                                </button>

                                <button className="Btn-modal" ref={this.buttonEdit}>
                                    Aprobar
                                    <i className="icon-ok styles-icons"/>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

const formed = reduxForm({
    form: 'ProductEditModal'
})(ModalEditProduct);

const mapStateToProps = state => ({
    provider: state.provider,
    product: state.product,
    initialValues: state.product.productDataFocus
});

const connected = connect(mapStateToProps, {
    updateProductsRequesting
})(formed);

export default connected