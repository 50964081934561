import {combineReducers} from 'redux';
import {reducer as form} from 'redux-form';
import {reducer as toastrReducer} from 'react-redux-toastr'
import login from './components/login/redux/reducer';
import provider from './commons/provider/reducer';
import product from './components/1-Product-supply/Redux/reducer';
import supply from './components/1-Product-supply/Supply/Redux/reducer';
import order from './components/2-Oders/redux/reducer';
import brands from './components/brand/redux/reducer';
import categories from './components/category/redux/reducer';
import unitMeasures from './components/unitMeasure/redux/reducer';
import typeSubcategories from './components/typeSubcategory/redux/reducer';
import paymentMethods from './components/paymentMethod/redux/reducer';
import shippingTypes from './components/shippingType/redux/reducer';
import providers from './components/provider/redux/reducer';
import state from './commons/states/reducer';
import project from './components/project/redux/reducer';
import user from './redux/auth/user/reducer';

export  const  IndexReducer  =  combineReducers ({
    toastr: toastrReducer,
    form,
    login,
    provider,
    product,
    supply,
    order,
    brands,
    categories,
    unitMeasures,
    typeSubcategories,
    paymentMethods,
    shippingTypes,
    providers,
    state,
    project,
    user,
});