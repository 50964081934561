import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/logos/logo-ingetem1.png";
import ImgLogin2 from "./../../assets/img/logo-no-text.png";
import ImgLogin3 from "./../../assets/img/logo-no-text.png";
//import Icon1 from './../../assets/icons/iconos-nav-1.png';
import Icon2 from "./../../assets/icons/iconos-nav-2.png";
import Icon3 from "./../../assets/icons/iconos-nav-3.png";
//import Icon4 from './../../assets/icons/iconos-nav-4.png';
//import Icon5 from './../../assets/icons/iconos-nav-5.png';
//import Icon6 from './../../assets/icons/iconos-nav-6.png';
//import Icon7 from './../../assets/icons/iconos-nav-7.png';
import IconSelectWhite from "./../../assets/icons/iconos-bajar-blanco.svg";
// import IconSelectBlack from './../../assets/icons/iconos-bajar-negro.svg';
import $ from "jquery";
import { connect } from "react-redux";
import { checkAuth } from "../../commons/provider/actions";
import { logoutRequesting } from "../login/redux/actions";
import ModalMyAccount from "../my-account/modals/modal-my-account";
import ModalChangePassword from "../my-account/modals/change_password";
class MenuHome extends React.Component {
  componentDidMount() {
    // Aca va las funciones del jquery

    //funciones para menu header//

    $(document).ready(function () {
      $("#mostrar1").on("click", function () {
        $("#target1").slideToggle(200);
      });

      $("#target1").slideUp(100);
      $("#mostrar1").click(function (event) {
        event.stopPropagation();
        // Do something
      });

      $("#mostrar2,#mostrar33").on("click", function () {
        $("#target1").slideToggle(200);
      });
    });

    $("html").on("click", function () {
      $("#target1").slideUp(100);
      $("#mostrar2,#mostrar33").click(function (event) {
        event.stopPropagation();
        // Do something
      });
    });
    //funciones para menu header//

    // funciones para menu bar
    $(function () {
      //If the overlay is clicked, then we close the menu bars.
      $(".overlay").on("click", function () {
        $(this).fadeOut(300);
        $(".menu-bar").removeClass("is-active");
        $(".menu-button").removeClass("is-clicked");
      });

      $(".top-menu").on("click", function () {
        var side = $(".menu-button").data("menu");
        //If this has is-clicked, then we close the menu.
        if ($(".menu-button").hasClass("is-clicked")) {
          //Since we created the code for overlay already.
          $(".overlay").click();
        }
        //Otherwise, we open it.
        else {
          $(".overlay").fadeIn(300);
          $(".menu-button").addClass("is-clicked");
          $(".menu-bar." + side).addClass("is-active");
        }
      });
    });
    // funciones para menu bar

    //funciones para menu isquierdo//

    $(document).ready(function () {
      $("#mostrar3").on("click", function () {
        $("#target2").slideToggle(250);
      });

      $("#mostrar4").on("click", function () {
        $("#target2").slideToggle(250);
      });

      $("#mostrar5").on("click", function () {
        $("#target3").slideToggle(250);
      });

      $("#mostrar6").on("click", function () {
        $("#target3").slideToggle(250);
      });

      $("#mostrar7").on("click", function () {
        $("#target4").slideToggle(250);
      });

      $("#mostrar8").on("click", function () {
        $("#target4").slideToggle(250);
      });

      $("#mostrar9").on("click", function () {
        $("#target5").slideToggle(250);
      });

      $("#mostrar10").on("click", function () {
        $("#target5").slideToggle(250);
      });

      $("#mostrar11").on("click", function () {
        $("#target6").slideToggle(250);
      });

      $("#mostrar12").on("click", function () {
        $("#target6").slideToggle(250);
      });

      $("#mostrar13").on("click", function () {
        $("#target7").slideToggle(250);
      });

      $("#mostrar14").on("click", function () {
        $("#target7").slideToggle(250);
      });

      $("#mostrar15").on("click", function () {
        $("#target8").slideToggle(250);
      });

      $("#mostrar16").on("click", function () {
        $("#target8").slideToggle(250);
      });

      //funcion//
    });

    // Aca va las funciones del jquery
  }

  state = {
    editUser: false,
    changePassword: false,
  };

  handleShowModal = (modal) => {
    this.setState({
      editUser: modal === "editUser",
      changePassword: modal === "changePassword",
    });
  };

  handleHiddenModal = () => {
    this.setState({
      editUser: false,
      changePassword: false,
    });
  };

  stopPropagation = (e) => {
    e.stopPropagation();
  };

  handleLogout = () => {
    const {
      provider: { token },
      logoutRequesting,
    } = this.props;
    logoutRequesting(token);
  };

  render() {
    const {
      provider: { isLogged },
      login: { user },
    } = this.props;
    const { editUser, changePassword } = this.state;

    return (
      <div style={isLogged ? {} : { display: "none" }}>
        <header>
          <div className="body-data-site-header">
            {editUser && (
              <ModalMyAccount
                stop={this.stopPropagation.bind(this)}
                hidden={this.handleHiddenModal.bind(this)}
              />
            )}
            {changePassword && (
              <ModalChangePassword
                stop={this.stopPropagation.bind(this)}
                hidden={this.handleHiddenModal.bind(this)}
              />
            )}
            <div>
              <div className="top-menu">
                <div className="menu-button" data-menu="left" />
              </div>
              <div className="menu-bar left menu-bar-on">
                <div className="menu-body">
                  <div id="body-menu-left">
                    <div className="title-center-home">
                      <h2>INGETEM</h2>
                    </div>
                    <div className="container-data-site">
                      <img
                        src={
                          user.hasOwnProperty("foto")
                            ? "/android-chrome-192x192.png"
                            : "https://backoffice.ingetem.com/apple-touch-icon.png"
                        }
                        alt="LogoIngetem"
                      />
                      <div className="date-site">
                        {/*<h3>Climatización</h3>*/}
                        <h3>Soluciones de temperatura a todo nivel</h3>
                        <h3>Dirección: {user.direccion}</h3>
                        <h3>Correo: {user.correo}</h3>
                        <h3>Teléfono: {user.celular}</h3>
                      </div>
                    </div>
                    <div className="container-data-general">
                      <div className="title-center-home">
                        <h2>Modulo de servicios</h2>
                      </div>
                      <div className="Content-General">
                        <div className="icono1">
                          <img src={Icon3} alt="Ingetem" />
                        </div>
                        <input
                          type="button"
                          className="styles3"
                          id="mostrar3"
                          name="boton1"
                          value="Gestion productos-insumos"
                        />
                        <img
                          src={IconSelectWhite}
                          className="styles icono2"
                          id="mostrar4"
                          alt="select"
                        />
                      </div>
                      <div id="target2">
                        <ul>
                          <li>
                            <Link to={"/productos"}>Productos</Link>
                          </li>
                          <li>
                            <Link to={"/insumos"}>Insumos</Link>
                          </li>
                        </ul>
                      </div>
                      <div className="Content-General">
                        <div className="icono1">
                          <img src={Icon2} alt="Ingetem" />
                        </div>
                        <Link to={"/ordenes"}>
                          <input
                            type="button"
                            className="styles3"
                            id="mostrar5"
                            name="boton1"
                            value="Ordenes"
                          />
                          {/*<img className="styles icono2" id="mostrar6" src={IconSelectWhite}*/}
                          {/*     alt=" select"/>*/}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="overlay overlay-off"></div>
            </div>
            <div className="item-header">
              <div className="body-menu-header">
                <div className="icono1-header" id="mostrar33">
                  <img
                    src={
                      user.hasOwnProperty("foto")
                        ? "/android-chrome-192x192.png"
                        : "https://backoffice.ingetem.com/apple-touch-icon.png"
                    }
                    alt="LogoIngetem"
                  />
                </div>
                <input
                  type="button"
                  className="styles-nav-header"
                  id="mostrar1"
                  name="boton1"
                  value="Configuración"
                />
                <img
                  src={IconSelectWhite}
                  className="styles-nav-header icono2-header"
                  id="mostrar2"
                  alt=" select"
                />
              </div>
              <div id="target1">
                <ul>
                  <li>
                    <button
                      className={"close-session"}
                      onClick={() => this.handleShowModal("editUser")}
                    >
                      Mi perfil
                    </button>
                  </li>
                  <li>
                    <button
                      className={"close-session"}
                      onClick={() => this.handleShowModal("changePassword")}
                    >
                      Cambiar contraseña
                    </button>
                  </li>
                  <li>
                    <a
                      className={"close-session"}
                      href={"https://proveedores.ingetem.com/services.pdf"}
                      target="_blank"
                    >
                      Manual de uso
                    </a>
                  </li>
                  <li>
                    <button
                      className="close-session"
                      onClick={() => this.handleLogout()}
                    >
                      Cerrar sesión
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </header>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  provider: state.provider,
  login: state.login,
});

const connected = connect(mapStateToProps, {
  checkAuth,
  logoutRequesting,
})(MenuHome);

export default connected;
