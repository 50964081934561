import {PROVIDER_GET_ERROR, PROVIDER_GET_REQUESTING, PROVIDER_GET_SUCCESS} from "./constants";

export const providersGetRequesting = (token) => ({
    type: PROVIDER_GET_REQUESTING,
    token
});
export const providersGetSuccess = (providers) => ({
    type: PROVIDER_GET_SUCCESS,
    providers
});
export const providersGetError = (error) => ({
   type: PROVIDER_GET_ERROR,
   error
});