import {call, put, takeEvery, all} from 'redux-saga/effects';
import {handleApiErrors} from "../../../commons/errors/apiErrors";

import {SHIPPING_TYPE_GET_REQUESTING} from "./constants";

import {
    shippingTypeGetSuccess,
    shippingTypeGetError
} from "./actions";

const shippingTypeURL =  `${process.env.REACT_APP_API_URL}/api/shippingTypes`;

function shippingTypeGetApi(token){

    const URL = `${shippingTypeURL}`;

    return fetch(URL, {
        method: 'GET',
        headers: {
           Authorization: 'Bearer' + token
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json=>{
            if (json.data.data.length > 0){
                return json.data.data;
            }else{
                throw json
            }
        }).catch((error)=>{
            throw error
        })
}

function* shippingTypeGetFlow(action){
    try {
        const {token} = action;
        const getShippingType = yield call(shippingTypeGetApi, token);
        yield put(shippingTypeGetSuccess(getShippingType));
    }catch (error) {
        yield put(shippingTypeGetError(error))
    }
}

function* shippingTypeWatcher(){
    yield all([
        takeEvery(SHIPPING_TYPE_GET_REQUESTING,shippingTypeGetFlow),
    ])
}

export default shippingTypeWatcher;