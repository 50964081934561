import React from 'react';
import ModalEditOrders from "../modals/edit-orders";
import {ModalAudit} from "../modals/audit";
import FormattedCurrency from 'react-formatted-currency';

export class TableOrders extends React.Component {

    state = {
        isOrders: false,
        isAudit: false,
        isEditOrder: false,
    };

    handleShowModal = (modal) => {
        this.setState({
            isOrders: modal === 'detalle de orden',
            isAudit: modal === 'Registro',
            isEditOrder: modal === 'Editar Ordenes',
        })
    };
    handleHiddenModal = () => {
        this.setState({
            isOrders: false,
            isEditOrder: false,
            isInfoProduct: false,
            isAudit: false,
        })
    };
    stopPropagation = (e) => {
        e.stopPropagation();
    };

    render() {
        const {orders, changeState, dataCountry} = this.props;
        return (
            <div className="Conteiner-table">
                {this.state.isOrders && (
                    <ModalEditOrders
                        stop={this.stopPropagation.bind(this)}
                        hidden={this.handleHiddenModal.bind(this)}/>
                )}
                {this.state.isAudit && (
                    <ModalAudit
                        stop={this.stopPropagation.bind(this)}
                        hidden={this.handleHiddenModal.bind(this)}/>
                )}
                <table className="table-orders">
                    <thead>
                    <tr>
                        <th>Código</th>
                        <th>Fecha de pago</th>
                        <th>Fecha de orden</th>
                        <th>Fecha de envio</th>
                        <th>Total</th>
                        <th>Metodo pago</th>
                        <th>Usuario</th>
                        <th>Telefono de Usuario</th>
                        <th>Ciudad de envio</th>
                        <th>Dirección de envio</th>
                        <th>Empresa de mensajeria</th>
                        <th>Numero de la remesa</th>
                        <th>Estado</th>
                        <th>Datos de mensajeria</th>
                        <th>Estados</th>
                        <th>Detallado</th>
                    </tr>
                    </thead>
                    <tbody>
                {orders.map((order, index) => (
                    <tr key={index}>
                        <td>{order.id}</td>
                        <td>{order.fecha_pago}</td>
                        <td>{order.fecha_orden}</td>
                        <td>{order.fecha_envio === null ? 'Sin fecha asignada aún' : order.fecha_envio}</td>
                        <td>
                            <FormattedCurrency
                                amount={order.total}
                                currency={dataCountry.moneda}
                                symbol={dataCountry.simbolo_moneda}
                                locale={dataCountry.ubicacion}
                                parts={['code', 'number', 'symbol']}
                                render={({symbol, number}) => {
                                    return `${symbol} ${number}`
                                }}
                            />
                        </td>
                        <td>{order.metodo_pago}</td>
                        <td>{order.usuario.nombre}</td>
                        <td>{order.usuario.telefono}</td>
                        <td>{order.ciudad === '' ? 'Ciudad sin asignar por recoger el producto en la tienda' : order.ciudad}</td>
                        <td>{order.direccion}</td>
                        <td>{order.empresa_mensajeria === '' ? 'Sin asignar aún' : order.empresa_mensajeria}</td>
                        <td>{order.numero_remesa === '' ? 'Sin asignar aún' : order.numero_remesa}</td>
                        <td>{order.estado}</td>
                        <td>
                            <button className="Btn-item-table"
                                    onClick={this.props.handleShowModal.bind(this, 'Editar Orden', order.id, order)}>
                                Editar
                                <i className="icon-pencil styles-icons"/>
                            </button>
                        </td>
                        <td>
                            <button
                                onClick={this.props.handleShowModal.bind(this, 'Agregar estado', order.id, order)}
                                className="Btn-item-table">
                                Ver
                                <i className="icon-pencil styles-icons"/>
                            </button>
                        </td>
                        <td>
                            <button
                                onClick={this.props.handleShowModal.bind(this, 'Informacion orden', order.id, order)}
                                className="Btn-item-table">
                                Ver
                                <i className="icon-pencil styles-icons"/>
                            </button>
                        </td>
                    </tr>
                ))}
                </tbody>
                </table>
            </div>
        )
    }
}