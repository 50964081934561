import {all, fork} from 'redux-saga/effects';
import LoginSaga from './components/login/redux/sagas';
import CheckAuthSaga from './commons/provider/sagas';
import ProductSaga from './components/1-Product-supply/Redux/sagas';
import OrderSaga from './components/2-Oders/redux/sagas';
import SuppliesSaga from './components/1-Product-supply/Supply/Redux/sagas';
import BrandsSaga from './components/brand/redux/sagas';
import CategoriesSaga from './components/category/redux/sagas';
import unitMeasureSaga from './components/unitMeasure/redux/sagas';
import typeSubcategoriesSaga from './components/typeSubcategory/redux/sagas';
import PaymentsMethodsSaga from './components/paymentMethod/redux/sagas';
import ShippingTypesSaga from './components/shippingType/redux/sagas';
import ProvidersSaga from './components/provider/redux/sagas';
import StateSaga from './commons/states/sagas';
import ProjectSagas from './components/project/redux/sagas';
import UserSagas from './redux/auth/user/sagas';

export function* IndexSagas() {
    yield all([
        fork(LoginSaga),
        fork(CheckAuthSaga),
        fork(ProductSaga),
        fork(SuppliesSaga),
        fork(BrandsSaga),
        fork(CategoriesSaga),
        fork(unitMeasureSaga),
        fork(typeSubcategoriesSaga),
        fork(PaymentsMethodsSaga),
        fork(ShippingTypesSaga),
        fork(ProvidersSaga),
        fork(StateSaga),
        fork(OrderSaga),
        fork(ProjectSagas),
        fork(UserSagas),
    ]);
}