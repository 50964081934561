import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {connect} from "react-redux";

const PrivateRoute = ({component: Component, provider, ...rest}) => {

    return <Route {...rest}
                  render={
                      props => provider.isLogged
                      ? (<Component {...props}/>)
                      : (<Redirect to={{pathname: '/', state: {from: props.location}}}/>)
                  }
    />
};


const mapStateToProps = state => ({
    provider: state.provider,
    login: state.login,
});

const connected = connect(mapStateToProps, {

})(PrivateRoute);

export default connected;

