import {
    LOGIN_REQUESTING,
    LOGIN_SUCCESS,
    LOGIN_ERROR,
    ME_GET_REQUESTING,
    ME_GET_SUCCESS,
    ME_GET_ERROR,
    LOGOUT_REQUESTING,
    LOGOUT_SUCCESS,
    LOGOUT_ERROR,
    SEND_EMAIL_REQUESTING,
    SEND_EMAIL_SUCCESS,
    SEND_EMAIL_ERROR,
    VERIFY_TOKEN_REQUESTING,
    VERIFY_TOKEN_SUCCESS,
    VERIFY_TOKEN_ERROR,
    RESET_PASSWORD_REQUESTING,
    RESET_PASSWORD_SUCCESS, RESET_PASSWORD_ERROR, MODAL_PASSWORD
} from "./constants";

const initialState = {
    requesting: false,
    success: false,
    error: '',
    message: '',
    user: {},
    requestingPassword: false,
    successPassword: false,
    errorPassword: false,
    requestingVerify: false,
    successVerify: false,
    errorVerify: false,
    requestingReset: false,
    successReset: false,
    errorReset: false,
    send: true,
    verify: false,
    reset: false,
    modal: false
};


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_REQUESTING :
            return {
                ...state,
                requesting: true,
                error: '',
                success: false
            };
        case LOGIN_ERROR :
            return {
                ...state,
                requesting: false,
                success: false,
                error: action.error
            };
        case LOGIN_SUCCESS :
            return {
                ...state,
                requesting: false,
                success: true,
                error: ''
            };
        case ME_GET_REQUESTING:
            return {
                ...state,
                requesting: true,
            };
        case ME_GET_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                user: action.user,
            };
        case ME_GET_ERROR:
            return {
                ...state,
                requesting: false,
                error: action.error,
            };
        case LOGOUT_REQUESTING:
            return {
                ...state,
                requesting: true,
                success: false,
            };
        case LOGOUT_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
            };
        case LOGOUT_ERROR:
            return {
                ...state,
                requesting: false,
                error: action.error,
            };
        case SEND_EMAIL_REQUESTING:
            return {
                ...state,
                requestingPassword: true,
                successPassword: false,
                errorPassword: ''
            };
        case SEND_EMAIL_SUCCESS:
            return {
                ...state,
                requestingPassword: false,
                successPassword: true,
                errorPassword: '',
                verify: true,
                send: false
            };
        case SEND_EMAIL_ERROR:
            return {
                ...state,
                requestingPassword: false,
                successPassword: false,
                errorPassword: action.error
            };
        case VERIFY_TOKEN_REQUESTING:
            return {
                ...state,
                requestingVerify: true,
                successVerify: false,
                errorVerify: ''
            };
        case VERIFY_TOKEN_SUCCESS:
            return {
                ...state,
                requestingVerify: false,
                successVerify: true,
                errorVerify: '',
                verify: false,
                reset: true
            };
        case VERIFY_TOKEN_ERROR:
            return {
                ...state,
                requestingVerify: false,
                successVerify: false,
                errorVerify: action.error
            };
        case RESET_PASSWORD_REQUESTING:
            return {
                ...state,
                requestingReset: true,
                successReset: false,
                errorReset: ''
            };
        case RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                requestingReset: false,
                successReset: true,
                errorReset: '',
                send: true,
                reset: false,
                modal: false
            };
        case RESET_PASSWORD_ERROR:
            return {
                ...state,
                requestingReset: false,
                successReset: false,
                errorReset: action.error
            };
        case MODAL_PASSWORD:
            return {
                ...state,
                modal: action.modal
            };
        default:
            return state
    }
};


export default reducer;