import React from 'react';
import {connect} from 'react-redux';
import {Field} from 'redux-form';
import {projectsGetRequesting} from "./redux/actions";

class ProjectComponent extends React.Component {

    componentWillMount() {
        const {
            projectsGetRequesting,
            provider: {token},
        } = this.props;
        projectsGetRequesting(token);
    }

    render() {
        const {
            project: {projects},
            initialValues,
        } = this.props;
        return (
            <Field name="proyecto" type="select" className="select" component="select">
                {initialValues ? <option value={initialValues.id}>{initialValues.nombre}</option> :
                    <option>...Seleccione</option>}
                {projects.length > 0 && projects.map((project, index) => {
                    if (initialValues === undefined)
                        return <option key={index} value={project.nombre}>{project.nombre}</option>
                    if (project.nombre !== initialValues.nombre)
                        return <option key={index} value={project.nombre}>{project.nombre}</option>
                })}
            </Field>
        )
    }
}

const mapStateToProps = state => ({
    provider: state.provider,
    project: state.project,
    initialValues: state.product.product.proyecto,
});

const connected = connect(mapStateToProps, {
    projectsGetRequesting,
})(ProjectComponent);

export default connected;