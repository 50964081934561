import {
    ORDER_CHANGE_STATE_ERROR,
    ORDER_CHANGE_STATE_REQUESTING,
    ORDER_CHANGE_STATE_SUCCESS,
    ORDER_SEARCH_ERROR,
    ORDER_SEARCH_REQUESTING,
    ORDER_SEARCH_SUCCESS,
    ORDER_SHIPPING_PUT_ERROR,
    ORDER_SHIPPING_PUT_REQUESTING,
    ORDER_SHIPPING_PUT_SUCCESS, ORDER_STATE_POST_ERROR,
    ORDER_STATE_POST_REQUESTING, ORDER_STATE_POST_SUCCESS,
    ORDERS_GET_ERROR,
    ORDERS_GET_REQUESTING,
    ORDERS_GET_SUCCESS,
    SET_FOCUS_ORDER,
    UPDATE_STATE_ORDER_ERROR,
    UPDATE_STATE_ORDER_REQUESTING,
    UPDATE_STATE_ORDER_SUCCESS
} from "./constants";

const initialState = {
    requestingShipping: false,
    successShipping: false,
    requestingStates: false,
    successStates: false,
    error: '',
    order: [],
    state: [],
    orderDataFocus: [],
    focusOrder: 0,
    search: '',
    paginate: 1,
    filter: 'Todos',
    hasMore: true,
    hasLess: false,
    orders: [],
    update: false
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        //Actualizar datos
        case ORDER_SHIPPING_PUT_REQUESTING:
            return {
                ...state,
                requestingShipping: true,
                successShipping: false,
            };
        case ORDER_SHIPPING_PUT_SUCCESS:
            let position = state.orders.indexOf(state.orderDataFocus);
            return {
                ...state,
                requestingShipping: false,
                successShipping: true,
                orders: state.orders.map((order, index) => index === position ? action.order : order),
            };
        case ORDER_SHIPPING_PUT_ERROR:
            return {
                ...state,
                requestingShipping: false,
                error: action.error
            };
        case ORDER_STATE_POST_REQUESTING:
            return {
                ...state,
                requestingStates: true,
                successStates: false,
            };
        case ORDER_STATE_POST_SUCCESS:
            return {
                ...state,
                requestingStates: false,
                successStates: true,
                orders: state.orders.map((order, index) => index === state.orders.indexOf(state.orderDataFocus) ? action.order : order),
            };
        case ORDER_STATE_POST_ERROR:
            return {
                ...state,
                requestingStates: false,
                error: action.error,
            };
        case SET_FOCUS_ORDER:
            return {
                ...state,
                focusOrder: action.id,
                orderDataFocus: action.order
            };
        // Obtener datos
        case ORDERS_GET_REQUESTING:
            return {
                ...state,
                paginate: action.paginate,
                filter: action.filter
            };
        case ORDERS_GET_SUCCESS:
            return {
                ...state,
                orders: action.orders,
                hasLess: state.paginate + 1 > 1,
                hasMore: true,
            };
        case ORDERS_GET_ERROR:
            return {
                ...state,
                error: action.error,
                hasMore: false,
                paginate: state.paginate !== 1 && state.paginate - 1,
                orders: []
            };

        //Actualizar estado
        case ORDER_CHANGE_STATE_REQUESTING:
            return {
                ...state,
                requestingStates: true,
                successStates: false,
                error: '',
                orderId: action.orderId
            };
        case ORDER_CHANGE_STATE_SUCCESS:
            return {
                ...state,
                requestingStates: false,
                successStates: true,
                error: '',
                orders: state.orders.map(orderItem => (
                    orderItem.id === action.order.id ? action.order : orderItem
                )),
                order: state.order.map(orderItem => (
                    orderItem.id === action.order.id ? action.order : orderItem
                )),
            };
        case ORDER_CHANGE_STATE_ERROR:
            return {
                ...state,
                requestingStates: false,
                successStates: false,
                error: action.error
            };

        //buacar dato
        case ORDER_SEARCH_REQUESTING:
            return {
                ...state,
                search: action.search
            };
        case ORDER_SEARCH_SUCCESS:
            return {
                ...state,
                order: action.order
            };
        case ORDER_SEARCH_ERROR:
            return {
                ...state,
                order: []
            };
        case  UPDATE_STATE_ORDER_REQUESTING:
            return {
                ...state,
                requesting: true,
                success: false,
                error: ''
            };
        case UPDATE_STATE_ORDER_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                error: '',
                update: true,
                orders: state.orders.map((order) => order.id === action.updateStateOrder.id ? action.updateStateOrder : order),
            };
        case UPDATE_STATE_ORDER_ERROR:
            return {
                ...state,
                requesting: false,
                success: false,
                error: action.error
            };
        default:
            return state;
    }

};

export default reducer