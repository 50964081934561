import {GET_STATES_SUCCESS,
        GET_STATES_ERROR,
        GET_STATES_REQUESTING,
        STATES_SUPPLY_ERROR,
        STATES_SUPPLY_REQUESTING,
        STATES_SUPPLY_SUCCESS,
        STATES_ORDER_ERROR,
        STATES_ORDER_REQUESTING,
        STATES_ORDER_SUCCESS,
        STATES_PRODUCT_ERROR,
        STATES_PRODUCT_REQUESTING,
        STATES_PRODUCT_SUCCESS,
} from "./constans";


const initialState = {
    requesting: false,
    success: false,
    error: '',
    states: []
};


const reducer = (state = initialState, action) => {
    switch (action.type) {
        //actualizar estados orden

        case GET_STATES_REQUESTING :
            return {
                ...state,
                requesting: true,
            };
        case GET_STATES_SUCCESS :
            return {
                ...state,
                success: true,
                requesting: false,
                states: action.states
            };
        case GET_STATES_ERROR :
            return {
                ...state,
                error: action.error,
                success: false,
                requesting: false
            };
        case STATES_SUPPLY_REQUESTING:
            return {
                ...state,
                requesting: true,
                statesSupply : []
            };
        case STATES_SUPPLY_SUCCESS:
            return {
                ...state,
                success: true,
                requesting: false,
                statesSupply: action.states
            };
        case STATES_SUPPLY_ERROR:
            return {
                ...state,
                error: action.error,
                success: false,
                requesting: false,
                statesSupply : []
            };
        case STATES_PRODUCT_REQUESTING:
            return {
                ...state,
                requesting: true,
                statesProduct : []
            };
        case STATES_PRODUCT_SUCCESS:
            return {
                ...state,
                success: true,
                requesting: false,
                statesProduct: action.states
            };
        case STATES_PRODUCT_ERROR:
            return {
                ...state,
                error: action.error,
                success: false,
                requesting: false,
                statesProduct : []
            };
        case STATES_ORDER_REQUESTING:
            return {
                ...state,
                requesting: true,
                statesOrder : []
            };
        case STATES_ORDER_SUCCESS:
            return {
                ...state,
                success: true,
                requesting: false,
                statesOrder: action.states
            };
        case STATES_ORDER_ERROR:
            return {
                ...state,
                error: action.error,
                success: false,
                requesting: false,
                statesOrder : []
            };
        default :
            return state;
    }
};

export default reducer;