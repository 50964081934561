import React from 'react';
import {connect} from 'react-redux';
import {reduxForm} from 'redux-form';
import SearchComponent from "../search/search";
import ModalNewProduct from "./modals/add-product";
import ModalEditProduct from "./modals/edit-product";
import {TableProduct} from "./table/table-product";
import {FilterComponent} from "../filter/filter";
import {statesProductRequesting} from "../../commons/states/actions";
import {shippingTypeGetRequesting} from "../shippingType/redux/actions";
import ModalAddCapacity from './modals/add-capacity';
import ModalEditCapacity from './modals/edit-capacity';
import ModalViewCapacities from './modals/view-capacities';
import ModalAddDocument from './modals/add-document';
import {NotFound} from "../../commons/errors/notFound";

import {
    productsGetRequesting,
    changeStateRequesting,
    productSearchRequesting,
    setFocusProduct,
    imageDeleteRequesting,
    shippingTypeAttachRequesting,
    shippingTypeDetachRequesting,
    capacityFocus,
    capacityDeleteRequesting,
    documentDeleteRequesting
} from "./Redux/actions";
import {toastr} from "react-redux-toastr";

class ProductComponent extends React.Component {

    state = {
        isProduct: false,
        isEditProduct: false,
        viewCapacities: false,
        addCapacity: false,
        editCapacity: false,
        addDocument: false,
    };

    handleShowModal = (modal, id = 0, product = {}) => {
        const {setFocusProduct} = this.props;
        if (modal !== 'nuevo producto') {
            setFocusProduct(id, product);
        }
        this.setState({
            isProduct: modal === 'nuevo producto',
            isEditProduct: modal === 'Editar Producto'
        })
    };

    handleHiddenModal = () => {
        this.setState({
            isProduct: false,
            isEditProduct: false,
            viewCapacities: false,
            addDocument: false,
        })
    };

    handleGetCsv = () => {
        const {login: {user}} = this.props;
        window.open(`${process.env.REACT_APP_API_URL}/api/exports/productsProvider?email=${user.correo}`, "_blank")
    };

    handleChangeEnable = (id) => {
        const {changeStateRequesting} = this.props;
        changeStateRequesting(id);
    };

    handleSearch = (value) => {
        const {productSearchRequesting, product, provider: {token}} = this.props;
        if ((product.search !== value.search) && value.search !== '')
            productSearchRequesting(value.search, token);
    };

    handleFilter = (value) => {
        const {productsGetRequesting, provider, product} = this.props;
        if (product.filter !== value)
            productsGetRequesting(product.paginate, value, provider.token);
    };

    handleGetData = (paginate) => {
        const {productsGetRequesting, provider, product} = this.props;
        if (paginate === 1 && product.hasMore)
            productsGetRequesting(product.paginate + paginate, product.filter, provider.token);
        if (paginate === -1 && product.hasLess)
            productsGetRequesting(product.paginate + paginate, product.filter, provider.token);
    };

    componentWillMount() {
        const {
            provider,
            productsGetRequesting,
            product: {paginate, filter},
            statesProductRequesting,
            shippingTypeGetRequesting,
        } = this.props;
        shippingTypeGetRequesting(provider.token);
        productsGetRequesting(paginate, filter, provider.token);
        statesProductRequesting(provider.token);
    };

    stopPropagation = (e) => {
        e.stopPropagation();
    };

    handleDeleteImage = (image) => {
        const {imageDeleteRequesting, provider: {token}} = this.props;
        imageDeleteRequesting(image, token);
    };

    handleAddDocument = (product) => {
        const {setFocusProduct} = this.props;
        setFocusProduct(product.id, product);
        this.setState({
            addDocument: true,
        });
    };

    handleDeleteDocument = (document) => {
        const {documentDeleteRequesting, provider: {token}} = this.props;
        documentDeleteRequesting(token, document);
    };

    handleAttachShippingType = (product, shippingTypes) => {
        const {shippingTypeAttachRequesting, provider: {token}} = this.props;
        shippingTypeAttachRequesting(token, product, shippingTypes);
    };

    handlesDetachShippingType = (product, shippingTypes) => {
        const {shippingTypeDetachRequesting, provider: {token}} = this.props;
        shippingTypeDetachRequesting(token, product, shippingTypes);
    };

    handleStateCapacity = (object) => {
        const {capacityDeleteRequesting, provider: {token}} = this.props;
        capacityDeleteRequesting(token, object);
    };

    handleFocusCapacity = (object) => {
        const {
            product: {capacity},
            capacityFocus
        } = this.props;
        if (capacity.id !== object.id)
            capacityFocus(object);
        this.setState({
            editCapacity: true,
        });
    };

    handleAddCapacity = (product) => {
        const {setFocusProduct} = this.props;
        setFocusProduct(product.id, product);
        this.setState({
            addCapacity: true,
        });
    };

    handleViewCapacities = (product) => {
        const {setFocusProduct} = this.props;
        setFocusProduct(product.id, product);
        this.setState({
            viewCapacities: true,
        });
    };

    handleHiddenModalCapacities = () => {
        this.setState({
            addCapacity: false,
            editCapacity: false,
        })
    };

    componentWillUpdate(nextProps, nextState, nextContext) {
        const {product: {successDeleteDocument}} = this.props;
        if (nextProps.product.successDeleteDocument !== successDeleteDocument) {
            toastr.success('Documento eliminado', 'Se ha eliminado el documento correctamente.');
        }
    }

    render() {
        const {
            product: {products, product, search},
            state: {statesProduct},
            login: {user},
            shippingTypes: {shippingTypes},
        } = this.props;
        return (
            <div className="styles-container">
                <SearchComponent
                    type={'Producto'}
                    placeholder={'Consultar nombre'}
                    handleSearch={this.handleSearch.bind(this)}
                > {user.hasOwnProperty('datos_pais') && search !== '' && (
                    product.length > 0
                        ? <TableProduct
                            handleShowModal={this.handleShowModal.bind(this)}
                            products={product}
                            dataCountry={user.datos_pais}
                            changeEnable={this.handleChangeEnable.bind(this)}
                            handleDeleteImage={this.handleDeleteImage.bind(this)}
                            shippingTypes={shippingTypes}
                            handleAttachShipping={this.handleAttachShippingType.bind(this)}
                            handleDetachShipping={this.handlesDetachShippingType.bind(this)}
                            addCapacity={this.handleAddCapacity.bind(this)}
                            focusCapacity={this.handleFocusCapacity.bind(this)}
                            stateCapacity={this.handleStateCapacity.bind(this)}
                            viewCapacities={this.handleViewCapacities.bind(this)}
                            deleteDocument={this.handleDeleteDocument.bind(this)}
                            addDocument={this.handleAddDocument.bind(this)}
                        />
                        : <NotFound/>
                )}
                </SearchComponent>
                <div className="conteiner-page-sector1">
                    <FilterComponent
                        type={'nuevo producto'}
                        handleShowModal={this.handleShowModal.bind(this)}
                        getData={this.handleGetData.bind(this)}
                        handleGetCsv={this.handleGetCsv.bind(this)}
                        handleFilter={this.handleFilter.bind(this)}
                        states={statesProduct}
                    />

                    {user.hasOwnProperty('datos_pais') && products.length > 0 ? (
                            <TableProduct
                                handleShowModal={this.handleShowModal.bind(this)}
                                products={products}
                                dataCountry={user.datos_pais}
                                changeEnable={this.handleChangeEnable.bind(this)}
                                handleDeleteImage={this.handleDeleteImage.bind(this)}
                                shippingTypes={shippingTypes}
                                handleAttachShipping={this.handleAttachShippingType.bind(this)}
                                handleDetachShipping={this.handlesDetachShippingType.bind(this)}
                                addCapacity={this.handleAddCapacity.bind(this)}
                                focusCapacity={this.handleFocusCapacity.bind(this)}
                                stateCapacity={this.handleStateCapacity.bind(this)}
                                viewCapacities={this.handleViewCapacities.bind(this)}
                                deleteDocument={this.handleDeleteDocument.bind(this)}
                                addDocument={this.handleAddDocument.bind(this)}
                            />)
                        : <NotFound/>}

                    {this.state.isProduct && (
                        <ModalNewProduct
                            stop={this.stopPropagation.bind(this)}
                            hidden={this.handleHiddenModal.bind(this)}
                        />
                    )
                    }

                    {this.state.isEditProduct && (
                        <ModalEditProduct
                            stop={this.stopPropagation.bind(this)}
                            hidden={this.handleHiddenModal.bind(this)}
                        />
                    )}

                    {this.state.viewCapacities && (
                        <ModalViewCapacities stop={this.stopPropagation.bind(this)}
                                             hidden={this.handleHiddenModal.bind(this)}
                                             dataCountry={user.datos_pais}
                                             editCapacity={this.handleFocusCapacity.bind(this)}
                                             stateCapacity={this.handleStateCapacity.bind(this)}
                        />
                    )}

                    {this.state.addCapacity && (
                        <ModalAddCapacity stop={this.stopPropagation.bind(this)}
                                          hidden={this.handleHiddenModalCapacities.bind(this)}/>
                    )}

                    {this.state.editCapacity && (
                        <ModalEditCapacity stop={this.stopPropagation.bind(this)}
                                           hidden={this.handleHiddenModalCapacities.bind(this)}/>
                    )}
                    {this.state.addDocument && (
                        <ModalAddDocument stop={this.stopPropagation.bind(this)}
                                          hidden={this.handleHiddenModal.bind(this)}/>
                    )}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    provider: state.provider,
    product: state.product,
    state: state.state,
    login: state.login,
    shippingTypes: state.shippingTypes,
});

const connected = connect(mapStateToProps, {
    productsGetRequesting,
    changeStateRequesting,
    productSearchRequesting,
    setFocusProduct,
    statesProductRequesting,
    imageDeleteRequesting,
    shippingTypeGetRequesting,
    shippingTypeAttachRequesting,
    shippingTypeDetachRequesting,
    capacityFocus,
    capacityDeleteRequesting,
    documentDeleteRequesting
})(ProductComponent);

const formed = reduxForm({
    form: 'ProductComponent'
})(connected);

export default formed;