import {CATEGORIES_GET_REQUESTING, CATEGORIES_GET_SUCCESS, CATEGORIES_GET_ERROR} from "./constants";

export const categoriesGetRequesting = (token) => ({
    type: CATEGORIES_GET_REQUESTING,
    token
});
export const categoriesGetSuccess = (categories) => ({
    type: CATEGORIES_GET_SUCCESS,
    categories
});
export const categoriesGetError = (error) => ({
  type: CATEGORIES_GET_ERROR,
  error
});