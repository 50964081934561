import React from 'react';
import {FilePond, registerPlugin} from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

registerPlugin(
    FilePondPluginImageExifOrientation,
    FilePondPluginImagePreview,
    FilePondPluginFileValidateType,
    FilePondPluginFileValidateSize
);

const typeFiles = [
    'application/pdf',
    // 'application/msword',
    // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    // 'application/vnd.ms-powerpoint',
    // 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    // 'application/vnd.ms-excel',
    // 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    // 'application/pdf',
    // 'application/vnd.oasis.opendocument.presentation',
    // 'application/vnd.oasis.opendocument.spreadsheet',
    // 'application/vnd.oasis.opendocument.text',
];

const DocumentsInput = ({input, name, meta: {touched, error}, ...rest}) => {
    return (
        <FilePond
            value={input.value}
            files={input.value}
            allowMultiple={true}
            maxFiles={rest.maxFiles}
            allowFileSizeValidation
            allowFileTypeValidation={true}
            acceptedFileTypes={typeFiles}
            labelIdle={'Arrastra y suelta tus archivos o <span class="filepond--label-action" style="color: black">Busca aquí</span>'}
            onupdatefiles={fileItems => input.onChange(fileItems)}
            {...input}
            {...rest}
            maxFileSize={"10MB"}
            labelMaxFileSizeExceeded={'File is too large'}
        />
    )
};

export default DocumentsInput;