import React from 'react';
import {connect} from 'react-redux';
import {Field} from 'redux-form';
import {unitMeasureGetRequesting} from './redux/actions';

class CategoryComponent extends React.Component {

    componentWillMount() {
        const {
            provider,
            unitMeasureGetRequesting,
        } = this.props;
        unitMeasureGetRequesting(provider.token);
    }

    render() {
        const {
            unitMeasures,
            InitialUnitMeasure
        } = this.props;
        return (
            <Field name="unidad_medida" type="select" className="select-unitMeasure" component="select">
                {InitialUnitMeasure ? <option value={InitialUnitMeasure.id}>{InitialUnitMeasure.nombre}</option> :
                    <option>...Seleccione</option>}
                {unitMeasures.length > 0 && unitMeasures.map((unitMeasure, index) => {
                    if (InitialUnitMeasure == undefined)
                        return <option key={index} value={unitMeasure.nombre}>{unitMeasure.medida}</option>
                    else if (unitMeasure.id !== InitialUnitMeasure.id)
                        return <option key={index} value={unitMeasure.nombre}>{unitMeasure.medida}</option>
                })}
            </Field>
        )
    }
}

const mapStateToProps = state => ({
    provider: state.provider,
    unitMeasures: state.unitMeasures.unitMeasures,
});

const connected = connect(mapStateToProps, {
    unitMeasureGetRequesting
})(CategoryComponent);

export default connected;