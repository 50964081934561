import {call, put, takeEvery, all} from 'redux-saga/effects';
import {handleApiErrors} from "../../../commons/errors/apiErrors";

import {CATEGORIES_GET_REQUESTING} from "./constants";
import {
    categoriesGetSuccess,
    categoriesGetError
} from "./actions";

const categoryURL  = `${process.env.REACT_APP_API_URL}/api/categories`;

function categoriesGetApi(token){
    const URL = `${categoryURL}`;

    return fetch(URL,{
        method: 'GET',
        headers: {
            Authorization: 'Bearer' + token,
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json=>{
            if (json.data.data.length > 0){
                return json.data.data;
            }else{
                throw json;
            }
        }).catch((error)=>{
            throw error
        })
}

function* categoryGetFlow(action){
    try {
        const {token} = action;
        const getCategory = yield call(categoriesGetApi, token);
        yield put(categoriesGetSuccess(getCategory));
    }catch (error) {
        yield put(categoriesGetError(error))
    }
}

function* categoryWatcher(){
    yield all([
        takeEvery(CATEGORIES_GET_REQUESTING, categoryGetFlow),
    ])
}
export default categoryWatcher;