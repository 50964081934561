import React from 'react';
import connect from "react-redux/es/connect/connect";
import FormattedCurrency from 'react-formatted-currency';

class ViewCapacities extends React.Component {

    render() {
        const {
            product: {productDataFocus},
            dataCountry,
        } = this.props;
        return (
            <div className={'body-blur'} onClick={this.props.hidden.bind(this)}>
                <div className="edit-modal" onClick={this.props.stop.bind(this)}>
                    <div className="button-container-close">
                        <button className="Btn-modal-cancel" onClick={this.props.hidden.bind(this)}>
                            <i className="icon-cancel"/>
                        </button>
                    </div>
                    <div className="text-modal">
                        <h1>Capacidades</h1>
                    </div>
                    <br/>
                    <div className="item-modal">
                        <table>
                            <thead>
                            <tr>
                                <th>Capacidad</th>
                                <th>Precio usuario</th>
                                <th>Precio gerente lider</th>
                                <th>Cantidad inventario</th>
                                <th>Limite inventario</th>
                                <th>Estado</th>
                                <th>Editar</th>
                            </tr>
                            </thead>
                            <tbody>
                            {productDataFocus.capacidades.data.length > 0 && productDataFocus.capacidades.data.map((capacity, index) => (
                                <tr key={index}>
                                    <td>{capacity.capacidad}</td>
                                    <td>
                                        <FormattedCurrency
                                            amount={capacity.precio_usuario}
                                            currency={dataCountry.moneda}
                                            symbol={dataCountry.simbolo_moneda}
                                            locale={dataCountry.ubicacion}
                                            parts={['code', 'number', 'symbol']}
                                            render={({symbol, number}) => {
                                                return `${symbol} ${number}`
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <FormattedCurrency
                                            amount={capacity.precio_gerente_lider}
                                            currency={dataCountry.moneda}
                                            symbol={dataCountry.simbolo_moneda}
                                            locale={dataCountry.ubicacion}
                                            parts={['code', 'number', 'symbol']}
                                            render={({symbol, number}) => {
                                                return `${symbol} ${number}`
                                            }}
                                        />
                                    </td>
                                    <td>{capacity.cantidad_inventario}</td>
                                    <td>{capacity.limite_inventario}</td>
                                    {capacity.estado === 'Habilitado'
                                        ? <td style={{cursor: 'pointer'}} onClick={() => this.props.stateCapacity(capacity)}>
                                            <i className={'icon-ok'}/>
                                            {capacity.estado}
                                        </td>
                                        : <td style={{cursor: 'pointer'}} onClick={() => this.props.stateCapacity(capacity)}>
                                            <i className={'icon-cancel'}/>
                                            {capacity.estado}
                                        </td>
                                    }
                                    <td style={{cursor: 'pointer'}} onClick={() => this.props.editCapacity(capacity)}>
                                        <i className={'icon-pencil'}/>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    client: state.client,
    product: state.product,
});

const connected = connect(mapStateToProps, {})(ViewCapacities);

export default connected