import React from 'react';
import {toastr} from "react-redux-toastr";
import {Field, reduxForm} from 'redux-form';
import connect from "react-redux/es/connect/connect";
import {ErrorComponent} from "../../../commons/errors/errors";

//***********import upload files****************
import {FilePond,registerPlugin} from 'react-filepond';
// Import FilePond styles
import 'filepond/dist/filepond.min.css';
// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import {suppliesProductsCreateRequesting} from "../Supply/Redux/actions";
// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);
//***********import upload files****************

const FileInput = ({input, name, id, meta: {touched, error}, ...rest}) => {

    return (
        <FilePond
            value={input.value}
            labelIdle={'Arrastra y suelta tus archivos o <span class="filepond--label-action " style="color: black">Busca aquí</span>'}
            allowMultiple={true}
            maxFiles={6}
            onupdatefiles={fileItems => input.onChange(fileItems)}
            {...input}
            {...rest}
        >
        </FilePond>
    );
};

class ModalNewSupplyProduct extends React.Component {

    componentWillUpdate(nextProps){
        const {product} = this.props;

        if (nextProps.product.error !== '' && nextProps.product.error !== product.error){
            let toastOptions = {
                component: (
                    <ErrorComponent message={nextProps.product.error}/>
                )
            };
            toastr.error('Recuerda', toastOptions);
        }
        if (nextProps.product.success && !product.success){
            toastr.success('Producto agregado','Producto creado correctamente');
            this.props.hidden();
        }
    }

    handleAction = (values) => {
        const {suppliesProductsCreateRequesting} = this.props;
        suppliesProductsCreateRequesting(values);
    };

    state={files1:[]};

    render() {
        const {
            handleSubmit,
        } = this.props;

        return (
            <div className={'body-blur'} onClick={this.props.hidden.bind(this)}>
                <div className="edit-modal" onClick={this.props.stop.bind(this)}>
                    <form onSubmit={handleSubmit(this.handleAction)}>
                        <div className="button-container-close">
                            <button className="Btn-modal-cancel " onClick={this.props.hidden.bind(this)}><i
                                className="icon-cancel"/>
                            </button>
                        </div>

                        <div className="text-modal">
                            <h1>Agregar Nuevo producto</h1>
                        </div>


                        <div className="item-modal">
                            <label>Nombre: </label>
                            <Field name={'Producto'} component={'input'}/>
                        </div>
                        <div className="item-modal">
                            <label>Referencia: </label>
                            <Field name={'Insumo'} component={'input'}/>
                        </div>

                        <div className="button-container">
                            <div className="btn-container">
                                <button className="Btn-modal">Cancelar<i className="icon-cancel styles-icons"/></button>
                                <button className="Btn-modal">Crear producto<i className="icon-ok styles-icons"/></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}
const formed = reduxForm({
    form: 'ProductSupplyCreateModal',
    enableReinitialize: true
})(ModalNewSupplyProduct);

const mapStateToProps = state => ({
    provider: state.provider,
    supply: state.supply,
});

const connected = connect(mapStateToProps,{
    suppliesProductsCreateRequesting,
})(formed);

export default connected