import {all, call, put, takeEvery} from 'redux-saga/effects';
import {handleApiErrors} from "../../../commons/errors/apiErrors";
import {
    orderChangeStateError,
    orderChangeStateSuccess,
    orderSearchError,
    orderSearchSuccess,
    ordersGetError,
    ordersGetSuccess,
    orderShippingPutError,
    orderShippingPutSuccess,
    orderStatePostError,
    orderStatePostSuccess,
    updateStateOrderError,
    updateStateOrderSuccess
} from "./actions";
import {
    ORDER_SEARCH_REQUESTING,
    ORDER_SHIPPING_PUT_REQUESTING,
    ORDER_STATE_POST_REQUESTING,
    ORDERS_GET_REQUESTING
} from "./constants";

const ordersURL = `${process.env.REACT_APP_API_URL}/api/orders`;
const orderURL = `${process.env.REACT_APP_API_URL}/api/order`;
const updateStatesOrder = `${process.env.REACT_APP_API_URL}/api/order/state`;
const ordersProviderUrl = `${process.env.REACT_APP_API_URL}/api/ordersProvider`;

const orderShippingPutApi = (values, token, id) => {
    let url = `${ordersURL}/${id}/${'shipping'}`;
    let body = {
        empresa_mensajeria: values.empresa_mensajeria,
        numero_remesa: values.numero_remesa,
    };
    return fetch(url, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        },
        body: JSON.stringify(body)
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 200)
                return json.data.data;
            return json.data;
        }).catch((error) => {
            throw error;
        })
};

function* orderShippingPutFlow(action) {
    try {
        const {values, token, id} = action;
        const order = yield call(orderShippingPutApi, values, token, id);
        yield put(orderShippingPutSuccess(order));
    } catch (error) {
        yield put(orderShippingPutError(error));
    }
}

const orderStatesPostApi = (token, order, state) => {
    let url = `${ordersURL}/post/states`;
    let body = {
        orden: order,
        observacion: state.observacion,
        estado: state.estado,
    };
    return fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        },
        body: JSON.stringify(body)
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
        throw json.data;
        if (json.code === 400)
            throw json.data;
        if (json.code === 200)
            return json.data.data;
        else
            throw []
        }).catch((error) => {
            throw error;
        })
};

function* orderStatesPostFlow(action) {
    try {
        const {token, order, state} = action;
        const orderGet = yield call(orderStatesPostApi, token, order, state);
        yield put(orderStatePostSuccess(orderGet));
    } catch (error) {
        yield put(orderStatePostError(error))
    }
}

//obtener datos
function ordersGetApi(paginate, filter, token) {

    return fetch(`${ordersProviderUrl}/filter/${filter}?page=${paginate}`, {
        method: 'GET',
        headers: {
            Authorization: 'Bearer ' + token,
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.data.data.length > 0)
                return json.data.data;
            else
                throw json
        }).catch((error) => {
            throw error;
        })
}

function* ordersGetFlow(action) {
    try {
        const {paginate, filter, token} = action;
        const orders = yield call(ordersGetApi, paginate, filter, token);
        yield put(ordersGetSuccess(orders));
    } catch (error) {
        yield put(ordersGetError(error));
    }
}

//cambiar estado
const orderChangeStateApi = (orderId, token) => {
    return fetch(`${ordersURL}/` + orderId, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.data.data.hasOwnProperty('id'))
                return json.data.data;
            else
                throw json
        }).catch((error) => {
            throw error;
        })
};

function* orderChangeStateFlow(action) {
    try {
        const {orderId, token} = action;
        const orderChange = yield call(orderChangeStateApi, orderId, token);
        yield put(orderChangeStateSuccess(orderChange));
    } catch (error) {
        yield put(orderChangeStateError(error))
    }
}

//buscar dato
const orderSearchApi = (search, token) => {
    let body = {
        search: search
    };

    return fetch(`${ordersURL}/searchProvider`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        },
        body: JSON.stringify(body)
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.data.data.length > 0)
                return json.data.data;
            else
                throw json
        }).catch((error) => {
            throw error;
        })
};

function* orderSearchFlow(action) {
    try {
        const {search, token} = action;
        const order = yield call(orderSearchApi, search, token);
        yield put(orderSearchSuccess(order));
    } catch (error) {
        yield put(orderSearchError(error));
    }
}

//actualizar estados ordenes

const updateStateOrderAPI = (token, values) => {

    let body = new FormData();
    body.append('_method', 'put');
    if (values.observacion !== undefined)
        body.append('observation', values.observacion);

    return fetch(`${updateStatesOrder}/${values.order_id}/${values.estado}`, {
        method: 'POST',
        headers: {
            Authorization: 'Bearer ' + token,
        },
        body: body
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 200)
                return json.data.data;
            throw json.data;
        }).catch((error) => {
            throw error;
        })
};

function* updateStateOrderFlow(action) {
    try {
        const {values, token} = action;
        const updateStateOrder = yield call(updateStateOrderAPI, token, values);
        yield put(updateStateOrderSuccess(updateStateOrder));
    } catch (error) {
        yield put(updateStateOrderError(error));
    }
}

function* orderWatcher() {
    yield all([
        takeEvery(ORDER_SHIPPING_PUT_REQUESTING, orderShippingPutFlow),
        takeEvery(ORDERS_GET_REQUESTING, ordersGetFlow),
        takeEvery(ORDER_STATE_POST_REQUESTING, orderStatesPostFlow),
        takeEvery(ORDER_SEARCH_REQUESTING, orderSearchFlow),
        // takeEvery(ORDER_CHANGE_STATE_REQUESTING, orderChangeStateFlow),
        // takeEvery(UPDATE_STATE_ORDER_REQUESTING, updateStateOrderFlow),
    ])
}

export default orderWatcher;
