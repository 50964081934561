import {call, put, all, takeEvery} from 'redux-saga/effects';
import {handleApiErrors} from "../../../commons/errors/apiErrors";
import {providerSet} from "../../../commons/provider/actions";
import {
    loginError,
    loginSuccess,
    logoutError,
    logoutSuccess,
    meGetError,
    meGetRequesting,
    meGetSuccess,
    resetPasswordError,
    resetPasswordSuccess,
    sendEmailError,
    sendEmailSuccess,
    verifyTokenError,
    verifyTokenSuccess
} from "./actions";
import {
    LOGIN_REQUESTING,
    LOGOUT_REQUESTING,
    ME_GET_REQUESTING, RESET_PASSWORD_REQUESTING,
    SEND_EMAIL_REQUESTING,
    VERIFY_TOKEN_REQUESTING
} from "./constants";
import {toastr} from "react-redux-toastr";

const loginUrl = `${process.env.REACT_APP_API_URL}/api/login/provider`;
const meUrl = `${process.env.REACT_APP_API_URL}/api/auth/meProvider`;
const logoutUrl = `${process.env.REACT_APP_API_URL}/api/auth/logoutProvider`;
const sendEmailUrl = `${process.env.REACT_APP_API_URL}/api/forgotPasswordProvider`;
const verifyTokenUrl = `${process.env.REACT_APP_API_URL}/api/verifyTokenResetPassword`;
const resetPasswordUrl = `${process.env.REACT_APP_API_URL}/api/resetPasswordProvider`;

const loginRequestingAPI = (email, password) => {

    let body = {
        email: email,
        password: password,
        device: 'desktop'
    };

    return fetch(loginUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body)
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422) {
                let message = '';
                for (let key in json.data) {
                    message += json.data[key] + '\n\n';
                }
                throw message;
            }
            if (json.code === 400)
                throw [json.data];
            if (json.hasOwnProperty('access_token')) {
                localStorage.setItem('@ingetem:provider', json.access_token);
                return json.access_token;
            }
        }).catch((error) => {
            throw error;
        })
};

function* loginFlow(action) {
    try {
        const {email, password} = action;
        const token = yield call(loginRequestingAPI, email, password);
        yield put(providerSet(token));
        yield put(loginSuccess());
        yield put(meGetRequesting(token));
    } catch (error) {
        yield put(loginError(error))
    }
}

const meGetApi = (token) => {
    return fetch(meUrl, {
        method: 'GET',
        headers: {
            Authorization: 'Bearer ' + token
        },
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 200)
                return json.data.data;
            throw json.data;
        }).catch((error) => {
            throw error
        })
};

function* meGetFlow(action) {
    try {
        const {token} = action;
        const user = yield call(meGetApi, token);
        yield put(meGetSuccess(user));
    } catch (error) {
        yield put(meGetError(error));
    }
}

const logoutApi = (token) => {
    return fetch(logoutUrl, {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 200) {
                localStorage.removeItem('@ingetem:provider');
                window.location.href = '/';
            } else {
                throw json.data;
            }
        }).catch((error) => {
            throw error
        })
};

function* logoutFlow(action) {
    try {
        const {token} = action;
        const logout = yield call(logoutApi, token);
        yield put(logoutSuccess(logout));
    } catch (error) {
        yield put(logoutError(error));
    }
}

const sendEmailPostApi = (values) => {
    let body = {
        'email': values.email,
    };
    return fetch(sendEmailUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.data.code === 200)
                return json.data.data;
            throw json.data;
        }).catch((error) => {
            throw error
        })
};

function* sendEmailPostFlow(action) {
    try {
        const {values} = action;
        const message = yield call(sendEmailPostApi, values);
        yield put(sendEmailSuccess(message));
        toastr.success('Verifica el correo','Se ha enviado un token a tu correo');
    } catch (error) {
        yield put(sendEmailError(error));
        toastr.error('Revisar el correo','El correo no se encuentra registrado');
    }
}

const verifyTokenResetPostApi = (values) => {
    let body = {
        'token': values.token,
    };
    return fetch(verifyTokenUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.data.code === 200)
                return json.data.data;
            throw json.data;
        }).catch((error) => {
            throw error
        })
};

function* verifyTokenFlow(action) {
    try {
        const {values} = action;
        const message = yield call(verifyTokenResetPostApi, values);
        yield put(verifyTokenSuccess(message));
        toastr.success('Token valido','El token se ha validado correctamente');
    } catch (error) {
        yield put(verifyTokenError(error));
        toastr.error('Token invalido','Revisa el token no es el mismo');
    }
}

const resetPasswordPutApi = (values) => {
    let body = {
        'token': values.token,
        'contraseña': values.contraseña,
        'contraseña_confirmation': values.re_contraseña,
    };
    return fetch(resetPasswordUrl, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.data.code === 200)
                return json.data.data;
            throw json.data;
        }).catch((error) => {
            throw error
        })
};

function* resetPasswordPutFlow(action) {
    try {
        const {values} = action;
        const message = yield call(resetPasswordPutApi, values);
        yield put(resetPasswordSuccess(message));
        toastr.success('Contraseña cambiada','La contraseña se ha cambiado satisfactoriamente');
    } catch (error) {
        yield put(resetPasswordError(error));
        toastr.error('Las contraseñas no coinciden','Las contraseñas deben ser iguales');
    }
}

function* loginWatcher() {
    yield all([
        takeEvery(LOGIN_REQUESTING, loginFlow),
        takeEvery(ME_GET_REQUESTING, meGetFlow),
        takeEvery(LOGOUT_REQUESTING, logoutFlow),
        takeEvery(SEND_EMAIL_REQUESTING, sendEmailPostFlow),
        takeEvery(VERIFY_TOKEN_REQUESTING, verifyTokenFlow),
        takeEvery(RESET_PASSWORD_REQUESTING, resetPasswordPutFlow)
    ])
}

export default loginWatcher;