export const GET_STATES_REQUESTING = 'STATE_ENABLED';
export const GET_STATES_ERROR = 'GET_STATES_ERROR';
export const GET_STATES_SUCCESS = 'GET_STATES_SUCCESS';

export const STATES_SUPPLY_REQUESTING='STATES_SUPPLY_REQUESTING';
export const STATES_SUPPLY_SUCCESS='STATES_SUPPLY_SUCCESS';
export const STATES_SUPPLY_ERROR='STATES_SUPPLY_ERROR';

export const STATES_PRODUCT_REQUESTING='STATES_PRODUCT_REQUESTING';
export const STATES_PRODUCT_SUCCESS='STATES_PRODUCT_SUCCESS';
export const STATES_PRODUCT_ERROR='STATES_PRODUCT_ERROR';

export const STATES_ORDER_REQUESTING='STATES_ORDER_REQUESTING';
export const STATES_ORDER_SUCCESS='STATES_ORDER_SUCCESS';
export const STATES_ORDER_ERROR='STATES_ORDER_ERROR';



