import {call, put, takeEvery, all} from 'redux-saga/effects';
import {handleApiErrors} from "../../../commons/errors/apiErrors";
import {projectsGetError, projectsGetSuccess} from "./actions";
import {PROJECTS_GET_REQUESTING} from "./constants";

const projectURL = `${process.env.REACT_APP_API_URL}/api/projects`;

const projectsGetApi = (token) => {
    return fetch(projectURL, {
        method: 'GET',
        headers: {
            Authorization: 'Bearer' + token,
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.data.data.length > 0)
                return json.data.data;
            else
                throw json
        }).catch((error) => {
            throw error
        })
};

function* projectsGetFlow(action) {
    try {
        const {token} = action;
        const projects = yield call(projectsGetApi, token);
        yield put(projectsGetSuccess(projects));
    } catch (error) {
        yield put(projectsGetError(error));
    }
}

function* projectWatcher() {
    yield all([
        takeEvery(PROJECTS_GET_REQUESTING, projectsGetFlow),
    ])
}

export default projectWatcher;