import {TYPE_SUBCATEGORY_ERROR, TYPE_SUBCATEGORY_REQUESTING, TYPE_SUBCATEGORY_SUCCESS} from "./constants";

const initialState = {
    requesting: false,
    success: false,
    error: '',
    message: '',
    typeSubcategories: [],
};

const reducer = (state = initialState, action) => {
    switch(action.type){
        case TYPE_SUBCATEGORY_REQUESTING:
            return{
                ...state,
                typeSubcategories: []
            };
        case TYPE_SUBCATEGORY_SUCCESS:
            return{
                ...state,
                typeSubcategories: action.typeSubcategories
            };
        case TYPE_SUBCATEGORY_ERROR:
            return{
                ...state,
                error: action.error,
            };
        default :
            return state;
    }
};

export default reducer