import {UNITMEASURE_GET_ERROR, UNITMEASURE_GET_REQUESTING, UNITMEASURE_GET_SUCCESS} from "./constants";

const initialState = {
  requesting: false,
  success: false,
  error: '',
  message: '',
  unitMeasures: [],
};

const reducer = (state = initialState, action) => {
    switch(action.type){
        case UNITMEASURE_GET_REQUESTING:
            return{
                ...state,
                unitMeasures: []
            };
        case UNITMEASURE_GET_SUCCESS:
            return{
                ...state,
                unitMeasures: action.unitMeasures,
            };
        case UNITMEASURE_GET_ERROR:
            return{
                ...state,
                error: action.error,
            };
        default :
            return state;
    }
};

export default reducer