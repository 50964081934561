import {call, put, takeEvery, all} from 'redux-saga/effects';
import {handleApiErrors} from "../../../commons/errors/apiErrors";

import {
    PRODUCTS_GET_REQUESTING,
    PRODUCT_CREATE_REQUESTING,
    PRODUCT_UPDATE_REQUESTING,
    PRODUCT_CHANGE_STATE_REQUESTING,
    PRODUCT_SEARCH_REQUESTING,
    PRODUCTS_ALL_GET_REQUESTING,
    IMAGE_DELETE_REQUESTING,
    SHIPPING_TYPE_ATTACH_REQUESTING,
    SHIPPING_TYPE_DETACH_REQUESTING,
    CAPACITY_POST_REQUESTING,
    CAPACITY_PUT_REQUESTING,
    CAPACITY_DELETE_REQUESTING,
    DOCUMENT_DELETE_REQUESTING, DOCUMENTS_POST_REQUESTING,
} from "./constants";
import {
    productsGetSuccess,
    productsGetError,
    productSuccess,
    productError,
    updateProductsSuccess,
    updateProductsError,
    changeStateSuccess,
    changeStateError,
    SearchSuccess,
    SearchError,
    productsAllGetSuccess,
    productsAllGetError,
    imageDeleteSuccess,
    imageDeleteError,
    shippingTypeAttachSuccess,
    shippingTypeAttachError,
    shippingTypeDetachSuccess,
    shippingTypeDetachError,
    capacityPostSuccess,
    capacityPostError,
    capacityPutSuccess,
    capacityPutError,
    capacityDeleteSuccess,
    capacityDeleteError,
    documentDeleteSuccess,
    documentDeleteError,
    documentsPostError,
    documentsPostSuccess,

} from "./actions";

const productsURL = `${process.env.REACT_APP_API_URL}/api/products`;
const productURL = `${process.env.REACT_APP_API_URL}/api/product`;
const productsProviderUrl = `${process.env.REACT_APP_API_URL}/api/productsProvider`;
const photosUrl = `${process.env.REACT_APP_API_URL}/api/photos`;
const documentsUrl = `${process.env.REACT_APP_API_URL}/api/documents`;
const capacityUrl = `${process.env.REACT_APP_API_URL}/api/capacities`;

//crear datos
const productCreateApi = (product, token) => {
    let body = new FormData();
    body.append('nombre', product.nombre || '');
    body.append('referencia', product.referencia || '');
    body.append('descripcion', product.descripcion || '');
    body.append('medidas', product.medidas || '');
    // body.append('precio', product.precio || '');
    // body.append('precio_gerente_lider', product.precio_gerente_lider || '');
    body.append('precio_envio', product.precio_envio || '');
    body.append('tiempo_envio', product.tiempo_envio || '');
    // body.append('precio_descuento', product.precio_descuento || 0);
    // body.append('inventario_disponible', product.inventario_disponible || '');
    // body.append('limite_inventario', product.limite_inventario || '');
    body.append('marca', product.marca || '');
    body.append('categoria', product.categoria || '');
    body.append('proyecto', product.proyecto || '');
    body.append('tipos_envio', product.tipos_envio || '');

    if (product.capacidades !== undefined && product.capacidades.length > 0) {
        product.capacidades.map((capacity, index) => body.append(`capacity_${index}`, capacity.capacidad));
        product.capacidades.map((capacity, index) => body.append(`price_user_${index}`, capacity.precio_usuario));
        product.capacidades.map((capacity, index) => body.append(`price_leader_manager_${index}`, capacity.precio_gerente_lider));
        product.capacidades.map((capacity, index) => body.append(`quantity_inventory_${index}`, capacity.cantidad_inventario));
        product.capacidades.map((capacity, index) => body.append(`limit_inventory_${index}`, capacity.limite_inventario));
        body.append('lenghtCapacities', product.capacidades.length);
    }

    if (product.fotos !== undefined && product.fotos.length > 0) {
        product.fotos.map((fileItem, index) => body.append(`photo_${index}`, fileItem.file));
        body.append('lenghtPhotos', product.fotos.length);
    }

    if (product.documentos !== undefined && product.documentos.length > 0) {
        product.documentos.map((fileItem, index) => body.append(`document_${index}`, fileItem.file));
        body.append('lenghtDocuments', product.documentos.length);
    }

    return fetch(productsURL, {
        method: 'POST',
        headers: {
            Authorization: 'Bearer' + token
        },
        body: body
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.data.code === 400)
                throw json.data;
            if (json.code === 200)
                return json.data.data;
        }).catch((error) => {
            throw error;
        })
};

function* productCreateFlow(action) {
    try {
        const {product, token} = action;
        const productCreated = yield call(productCreateApi, product, token);
        yield put(productSuccess(productCreated))
    } catch (error) {
        yield put(productError(error))
    }
}

//actualizar datos
const updateProductAPI = (token, values) => {
    let body = new FormData();
    body.append('_method', 'put');
    body.append('nombre', values.nombre || '');
    body.append('referencia', values.referencia || '');
    body.append('descripcion', values.descripcion || '');
    body.append('medidas', values.medidas || '');
    // body.append('precio', values.precio || '');
    // body.append('precio_gerente_lider', values.precio_gerente_lider || '');
    body.append('precio_envio', values.precio_envio || '');
    body.append('tiempo_envio', values.tiempo_envio || '');
    // body.append('precio_descuento', values.precio_descuento || 0);
    // body.append('inventario_disponible', values.inventario_disponible || '');
    // body.append('limite_inventario', values.limite_inventario || '');
    body.append('marca', values.marca || '');
    body.append('proyecto', values.proyecto || '');
    body.append('categoria', values.categoria || '');
    if (values.fotos[0] !== undefined && values.fotos.length > 0) {
        values.fotos.map((fileItem, index) => body.append(`photo_${index}`, fileItem.file));
        body.append('lenghtPhotos', values.fotos.length);
    }

    return fetch(`${productsURL}/${values.id}`, {
        method: 'POST',
        headers: {
            Authorization: 'Bearer' + token,
        },
        body: body
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 200)
                return json.data.data;
            throw json.data;
        }).catch((error) => {
            throw error;
        })
};

function* productUpdateFlow(action) {
    try {
        const {values, token} = action;
        const product = yield call(updateProductAPI, values, token);
        yield put(updateProductsSuccess(product));
    } catch (error) {
        yield put(updateProductsError(error));
    }
}

//obtener datos
function productsGetApi(paginate, filter, token) {
    return fetch(`${productsProviderUrl}/filter/${filter}?page=${paginate}`, {
        method: 'GET',
        headers: {
            Authorization: 'Bearer' + token,
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.data.data.length > 0)
                return json.data.data;
            else
                throw json
        }).catch((error) => {
            throw error;
        })
}

function* productsGetFlow(action) {
    try {
        const {paginate, filter, token} = action;
        const products = yield call(productsGetApi, paginate, filter, token);
        yield put(productsGetSuccess(products));
    } catch (error) {
        yield put(productsGetError(error))
    }
}

//cambiar estado
const productChangeStateApi = (productId, token) => {
    return fetch(`${productsURL}/` + productId, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer' + token
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.data.data.hasOwnProperty('id'))
                return json.data.data;
            else
                throw json
        }).catch((error) => {
            throw error;
        })
};

function* productChangeStateFlow(action) {
    try {
        const {productId, token} = action;
        const productChange = yield call(productChangeStateApi, productId, token);
        yield put(changeStateSuccess(productChange));
    } catch (error) {
        yield put(changeStateError(error));
    }
}

//buscar dato especifico
const productSearchApi = (search, token) => {

    let body = {
        search: search
    };

    return fetch(`${productsURL}/searchProvider`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer' + token
        },
        body: JSON.stringify(body)
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.data.data.length > 0)
                return json.data.data;
            else
                throw json
        }).catch((error) => {
            throw error;
        })
};


function* productSearchFlow(action) {
    try {
        const {search, token} = action;
        const product = yield call(productSearchApi, search, token);
        yield put(SearchSuccess(product));
    } catch (error) {
        yield put(SearchError(error));
    }
}

const productsAllGetApi = (token) => {
    return fetch(`${productURL}/allData`, {
        method: 'GET',
        headers: {
            Authorization: 'Bearer' + token,
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.data.data.length > 0)
                return json.data.data;
            else
                throw json
        }).catch((error) => {
            throw error;
        })
};

function* productsAllGetFlow(action) {
    try {
        const {token} = action;
        const productsAll = yield call(productsAllGetApi, token);
        yield put(productsAllGetSuccess(productsAll));
    } catch (error) {
        yield put(productsAllGetError(error));
    }
}


const deleteImageAPI = (imageId, token) => {
    let url = `${photosUrl}/${imageId}`;
    return fetch(url, {
        method: 'DELETE',
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.data.code === 200)
                return json.data.data;
            throw json.data;
        }).catch((error) => {
            throw error;
        })
};


function* imageDeleteFlow(action) {
    try {
        const {imageId, token} = action;
        const message = yield call(deleteImageAPI, imageId, token);
        yield put(imageDeleteSuccess(message));
    } catch (error) {
        yield put(imageDeleteError(error));
    }
}

const shippingTypeAttachApi = (token, product, shippingType) => {
    let body = {
        producto: product,
        tipo_envio: shippingType.nombre,
    };
    return fetch(`${productsURL}/post/attachShippingTypes`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer' + token
        },
        body: JSON.stringify(body)
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 200)
                return json.data.data;
            else
                throw json.data
        }).catch((error) => {
            throw error;
        })
};

function* shippingTypeAttachFlow(action) {
    try {
        const {token, product, shippingType} = action;
        const productGet = yield call(shippingTypeAttachApi, token, product, shippingType);
        yield put(shippingTypeAttachSuccess(productGet));
    } catch (error) {
        yield put(shippingTypeAttachError(error));
    }
}

const shippingTypeDetachApi = (token, product, shippingType) => {
    let body = {
        producto: product,
        tipo_envio: shippingType.nombre,
    };
    return fetch(`${productsURL}/post/detachShippingTypes`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer' + token
        },
        body: JSON.stringify(body)
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 200)
                return json.data.data;
            else
                throw json.data
        }).catch((error) => {
            throw error;
        })
};

function* shippingTypeDetachFlow(action) {
    try {
        const {token, product, shippingType} = action;
        const productGet = yield call(shippingTypeDetachApi, token, product, shippingType);
        yield put(shippingTypeDetachSuccess(productGet));
    } catch (error) {
        yield put(shippingTypeDetachError(error));
    }
}

const capacityPostApi = (token, capacity, product) => {
    let body = {
        capacidad: capacity.capacidad,
        precio_usuario: capacity.precio_usuario,
        precio_gerente_lider: capacity.precio_gerente_lider,
        producto: product,
        cantidad_inventario: capacity.cantidad_inventario,
        limite_inventario: capacity.limite_inventario,
    };
    return fetch(`${capacityUrl}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer' + token
        },
        body: JSON.stringify(body)
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 200)
                return json.data.data;
            throw json.data
        }).catch((error) => {
            throw error;
        })
};

function* capacityPostFlow(action) {
    try {
        const {token, capacity, product} = action;
        const productGet = yield call(capacityPostApi, token, capacity, product);
        yield put(capacityPostSuccess(productGet));
    } catch (error) {
        yield put(capacityPostError(error))
    }
}

const capacityPutApi = (token, capacity) => {
    let body = {
        capacidad: capacity.capacidad,
        precio_usuario: capacity.precio_usuario,
        precio_gerente_lider: capacity.precio_gerente_lider,
        cantidad_inventario: capacity.cantidad_inventario,
        limite_inventario: capacity.limite_inventario,
    };
    return fetch(`${capacityUrl}/${capacity.id}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer' + token
        },
        body: JSON.stringify(body)
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 200)
                return json.data.data;
            throw json.data
        }).catch((error) => {
            throw error;
        })
};

function* capacityPutFlow(action) {
    try {
        const {token, capacity} = action;
        const productGet = yield call(capacityPutApi, token, capacity);
        yield put(capacityPutSuccess(productGet));
    } catch (error) {
        yield put(capacityPutError(error))
    }
}

const capacityDeleteApi = (token, capacity) => {
    return fetch(`${capacityUrl}/${capacity.id}`, {
        method: 'DELETE',
        headers: {
            Authorization: 'Bearer' + token
        },
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 200)
                return json.data.data;
            throw json.data
        }).catch((error) => {
            throw error;
        })
};

function* capacityDeleteFlow(action) {
    try {
        const {token, capacity} = action;
        const productGet = yield call(capacityDeleteApi, token, capacity);
        yield put(capacityDeleteSuccess(productGet));
    } catch (error) {
        yield put(capacityDeleteError(error))
    }
}

const documentDeleteApi = (token, document) => {
    let url = `${documentsUrl}/${document}`;
    return fetch(url, {
        method: 'DELETE',
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.data.data.hasOwnProperty('id'))
                return json.data.data;
            throw json.data;
        }).catch((error) => {
            throw error;
        })
};

function* documentDeleteFlow(action) {
    try {
        const {token, document} = action;
        const product = yield call(documentDeleteApi, token, document);
        yield put(documentDeleteSuccess(product));
    } catch (error) {
        yield put(documentDeleteError(error));
    }
}

const documentsPostApi = (token, product, documents) => {
    let body = new FormData();
    body.append('product', product);
    if (documents.length > 0) {
        documents.map((fileItem, index) => body.append(`document_${index}`, fileItem.file));
        body.append('lenghtDocuments', documents.length);
    }
    return fetch(`${productsURL}/documents`, {
        method: 'POST',
        headers: {
            Authorization: 'Bearer' + token,
        },
        body: body
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 200)
                return json.data.data;
            throw json.data;
        }).catch((error) => {
            throw error;
        })
};

function* documentsPostFlow(action) {
    try {
        const {token, product, documents} = action;
        const productGet = yield call(documentsPostApi, token, product, documents);
        yield put(documentsPostSuccess(productGet));
    } catch (error) {
        yield put(documentsPostError(error));
    }
}

function* suppliesWatcher() {
    yield all([
        takeEvery(PRODUCTS_GET_REQUESTING, productsGetFlow),
        takeEvery(PRODUCT_CREATE_REQUESTING, productCreateFlow),
        takeEvery(PRODUCT_UPDATE_REQUESTING, productUpdateFlow),
        takeEvery(PRODUCT_CHANGE_STATE_REQUESTING, productChangeStateFlow),
        takeEvery(PRODUCT_SEARCH_REQUESTING, productSearchFlow),
        takeEvery(PRODUCTS_ALL_GET_REQUESTING, productsAllGetFlow),
        takeEvery(IMAGE_DELETE_REQUESTING, imageDeleteFlow),
        takeEvery(SHIPPING_TYPE_ATTACH_REQUESTING, shippingTypeAttachFlow),
        takeEvery(SHIPPING_TYPE_DETACH_REQUESTING, shippingTypeDetachFlow),
        takeEvery(CAPACITY_POST_REQUESTING, capacityPostFlow),
        takeEvery(CAPACITY_PUT_REQUESTING, capacityPutFlow),
        takeEvery(CAPACITY_DELETE_REQUESTING, capacityDeleteFlow),
        takeEvery(DOCUMENT_DELETE_REQUESTING, documentDeleteFlow),
        takeEvery(DOCUMENTS_POST_REQUESTING, documentsPostFlow),
    ])
}

export default suppliesWatcher;