import React from "react";
import { toastr } from "react-redux-toastr";
import { Field, reduxForm, FieldArray } from "redux-form";
import connect from "react-redux/es/connect/connect";
import { ErrorComponent } from "../../../commons/errors/errors";
import BrandComponent from "./../../brand";
import CategoryComponent from "./../../category";
import ProjectComponent from "../../project";
import { productCreate } from "../Redux/actions";
import ImagesInput from "../file-inputs/images";
import DocumentsInput from "../file-inputs/documents";
import { required, numericality, validateForm } from "redux-form-validators";

const renderField = (props) => {
  return <input {...props.input} {...props.type} {...props.label} />;
};

const validate = validateForm({
  onlyInteger: [
    required(),
    numericality({
      int: true,
      ">=": 1,
      msg: { ">=": "Debe ser mayor o igual a 1." },
    }),
  ],
});

const renderCapacities = ({ fields }) => (
  <div>
    {fields.map((capacity, index) => (
      <div key={index}>
        <div
          style={{ marginTop: "0", marginBottom: "-11px" }}
          className="button-container-close"
        >
          <button
            type="button"
            className="Btn-modal-cancel"
            onClick={() => fields.remove(index)}
          >
            <i className="icon-cancel" />
          </button>
        </div>
        <div className={"Conteiner-table"}>
          <table className={""}>
            <thead>
              <tr>
                <th>Capacidad</th>
                <th>Precio usuario</th>
                <th>Precio gerente lider</th>
                <th>Cantidad inventario</th>
                <th>Limite inventario</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Field
                    component={renderField}
                    name={`${capacity}.capacidad`}
                    type={"text"}
                    label="Ejemplo: 150 Wats"
                    className="input input_capacity"
                  />
                </td>
                <td>
                  <Field
                    component={renderField}
                    name={`${capacity}.precio_usuario`}
                    type={"number"}
                    label="Precio usuario"
                    className="input input_capacity"
                  ></Field>
                </td>
                <td>
                  <Field
                    component={renderField}
                    name={`${capacity}.precio_gerente_lider`}
                    type={"number"}
                    label="Precio gerente lider"
                    className="input input_capacity"
                  ></Field>
                </td>
                <td>
                  <Field
                    component={renderField}
                    name={`${capacity}.cantidad_inventario`}
                    type={"number"}
                    label="Precio gerente lider"
                    className="input input_capacity"
                  ></Field>
                </td>
                <td>
                  <Field
                    component={renderField}
                    name={`${capacity}.limite_inventario`}
                    type={"number"}
                    label="Precio gerente lider"
                    className="input input_capacity"
                  ></Field>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
      </div>
    ))}
    <div style={{ marginBottom: "5px" }} className="button-container">
      <button
        className="Btn-modal"
        type="button"
        onClick={() => fields.push({})}
      >
        Agregar capacidad
        <i className="icon-ok styles-icons" />
      </button>
    </div>
  </div>
);

class ModalNewProduct extends React.Component {
  componentWillUpdate(nextProps, nextState, nextContext) {
    const {
      product: { error, success },
    } = this.props;

    if (nextProps.product.error !== "" && nextProps.product.error !== error) {
      const error = {
        "Undefined index: lenghtPhotos": "Añade al menos una foto al producto",
        "Undefined index: lenghtDocuments": "El campo documentos es obligatorio"
      };
      let toastOptions = {
        component: <ErrorComponent message={nextProps.product.error} />,
      };
      toastr.error(
        "Error",
        error[nextProps.product.error.data]
          ? error[nextProps.product.error.data]
          : 'Archivo muy pesado, intente con un archivo mas liviano'
      );
    }
    if (nextProps.product.success && success !== nextProps.product.success) {
      toastr.success("Producto agregado", "Producto creado correctamente");
      this.props.hidden();
    }
  }

  handleAction = (values) => {
    const {
      productCreate,
      provider: { token },
    } = this.props;
    if (values.capacidades) productCreate(values, token);
    else toastr.error("Upss !!", "Debe ingresar al menos una capacidad");
  };

  render() {
    const {
      handleSubmit,
      shippingTypes: { shippingTypes },
      product: { requesting },
    } = this.props;
    return (
      <div className={"body-blur"} onClick={this.props.hidden.bind(this)}>
        <div className="edit-modal" onClick={this.props.stop.bind(this)}>
          <form onSubmit={handleSubmit(this.handleAction)}>
            <div className="button-container-close">
              <button
                className="Btn-modal-cancel "
                onClick={this.props.hidden.bind(this)}
              >
                <i className="icon-cancel" />
              </button>
            </div>

            <div className="text-modal">
              <h1>Agregar nuevo producto</h1>
            </div>
            <div className="item-modal">
              <label>Nombre:</label>
              <Field
                name={"nombre"}
                className="input input_city"
                component={"input"}
              />
            </div>
            <div className="item-modal">
              <label>Referencia:</label>
              <Field
                name={"referencia"}
                className="input input_city"
                component={"input"}
              />
            </div>
            <div className="item-modal">
              <label>Fotos e imagenes:</label>
              <Field
                name={"fotos"}
                maxFiles={6}
                className={"filepond"}
                component={ImagesInput}
              />
            </div>
            <div className="item-modal">
              <label>Ficha tecnica (PDF):</label>
              <Field
                name={"documentos"}
                maxFiles={1}
                className={"filepond"}
                component={DocumentsInput}
              />
            </div>
            <div className="item-modal">
              <label>Descripción:</label>
              <Field
                name={"descripcion"}
                component={"input"}
              />
            </div>
            <div className="item-modal">
              <label>Medidas:</label>
              <Field
                name={"medidas"}
                type={"text"}
                className="input input_city"
                component={"input"}
              />
            </div>
            {/*<div className="item-modal">*/}
            {/*<label>Precio:</label>*/}
            {/*<Field name={'precio'} type={'number'} className='input input_city' component={'input'}/>*/}
            {/*</div>*/}
            {/*<div className="item-modal">*/}
            {/*<label>Precio Gerente Lider:</label>*/}
            {/*<Field name={'precio_gerente_lider'} type={'number'} className='input input_city'*/}
            {/*component={'input'}/>*/}
            {/*</div>*/}
            <div className="item-modal">
              <label>Precio envio:</label>
              <Field
                name={"precio_envio"}
                type={"number"}
                className="input input_city"
                component={"input"}
              />
            </div>
            <div className="item-modal">
              <label>Tiempo de envio:</label>
              <Field
                name={"tiempo_envio"}
                type={"number"}
                className="input input_city"
                component={"input"}
                placeholder={"Numero de días maximos para entregar"}
              />
            </div>
            {/*<div className="item-modal">*/}
            {/*<label>Precio de descuento:</label>*/}
            {/*<Field name={'precio_descuento'}*/}
            {/*type={'number'}*/}
            {/*className='input input_city'*/}
            {/*component={'input'}*/}
            {/*placeholder={'Por defecto 0'}*/}
            {/*/>*/}
            {/*</div>*/}
            {/*<div className="item-modal">*/}
            {/*<label>Cantidad de unidades disponibles:</label>*/}
            {/*<Field name={'inventario_disponible'} type={'number'} className='input input_city'*/}
            {/*component={'input'}/>*/}
            {/*</div>*/}
            {/*<div className="item-modal">*/}
            {/*<label>El producto se deshabilita cuando el inventario llega a:</label>*/}
            {/*<Field name={'limite_inventario'} type={'number'} className='input input_city'*/}
            {/*component={'input'}/>*/}
            {/*</div>*/}
            <div className="item-modal">
              <label>Marca:</label>
              <BrandComponent name={"marca"} />
            </div>
            <div className="item-modal">
              <label>Categoría:</label>
              <CategoryComponent name={"categoria"} />
            </div>

            <div className="item-modal">
              <label>Proyecto:</label>
              <ProjectComponent name={"proyecto"} />
            </div>
            {/*
                        {shippingTypes.length > 0 && (
                            <div className="item-modal">
                                <label>Tipo de envio:</label>
                                <Field shippingTypes={shippingTypes}
                                       component={shippingTypeComponent}
                                       name={'tipos_envio'}
                                />
                            </div>
                        )}*/}

            <div className={"item-modal"}>
              <FieldArray name={"capacidades"} component={renderCapacities} />
            </div>

            <div className="button-container">
              <div className="btn-container">
                <button
                  className="Btn-modal"
                  onClick={this.props.hidden.bind(this)}
                >
                  Cancelar
                  <i className="icon-cancel styles-icons" />
                </button>
                <button className="Btn-modal" disabled={requesting}>
                  Crear producto
                  <i className="icon-ok styles-icons" />
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const formed = reduxForm({
  form: "ProductCreateModal",
  enableReinitialize: true,
})(ModalNewProduct);

const mapStateToProps = (state) => ({
  provider: state.provider,
  product: state.product,
  shippingTypes: state.shippingTypes,
});

const connected = connect(mapStateToProps, {
  productCreate,
})(formed);

export default connected;
