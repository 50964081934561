import {PAYMENT_METHOD_GET_ERROR, PAYMENT_METHOD_GET_REQUESTING, PAYMENT_METHOD_GET_SUCCESS} from "./constants";


export const paymentMethodGetRequesting = (token) =>({
    type: PAYMENT_METHOD_GET_REQUESTING,
    token
});

export const paymentMethodGetSuccess = (paymentMethods) => ({
    type: PAYMENT_METHOD_GET_SUCCESS,
    paymentMethods
});

export const paymentMethodGetError = (error) => ({
    type: PAYMENT_METHOD_GET_ERROR,
    error
});

