import React from 'react';
import {connect} from 'react-redux';
import {Field} from 'redux-form';
import {categoriesGetRequesting} from './redux/actions';

class CategoryComponent extends React.Component{

    componentWillMount(){
        const {
            provider,
            categoriesGetRequesting,
        } = this.props;
        categoriesGetRequesting(provider.token);
    }

    render(){
        const {
            categories,
            InitialCategory
        } = this.props;
        return(
            <Field name="categoria" type="select" className="select-category" component="select">
                {InitialCategory ? <option value={InitialCategory.id}>{InitialCategory.nombre}</option> :
                    <option>...Seleccione</option>}
                {categories.length > 0 && categories.map((category, index)=>{
                    if (InitialCategory===undefined)
                        return <option key={index} value={category.nombre}>{category.nombre}</option>
                    if (category.nombre !== InitialCategory.nombre)
                        return <option key={index} value={category.nombre}>{category.nombre}</option>
                })}
            </Field>
        )
    }
}

const mapStateToProps = state => ({
    provider: state.provider,
    categories: state.categories.categories,
});

const connected = connect(mapStateToProps,{
    categoriesGetRequesting
})(CategoryComponent);

export default connected;