import React from 'react';
import {connect} from 'react-redux';
import {reduxForm} from 'redux-form';
import SearchComponent from "../../search/search";
import {FilterComponent} from "../../filter/filter";
import {
    suppliesGetRequesting, 
    supplyChangeStateRequesting, 
    setFocusSupply, 
    supplySearchRequesting, 
    suppliesProductsGetRequesting,
    setFocusSuppliesProducts,
} from "./Redux/actions";
import {TableSupply} from "./table-supply";
import ModalNewSupply from "../modals/add-supply";
import ModalEditSupply from "../modals/edit-supply";
import ModalProductSupply from '../modals/productSupply';
import {NotFound} from "../../../commons/errors/notFound";
import ModalNewSupplyProduct from '../modals/add-Supplyproduct';
import ModalUpdateSupplyProduct from '../modals/edit-supplyProduct';
import {statesSupplyRequesting} from "../../../commons/states/actions";

class SupplyComponent extends React.Component {

    state = {
        isSupply: false,
        isEditSupply: false,
        isProductSupply: false,
        isCreateProductSupply: false,
        isUpdateProductSupply: false,
    };

    handleFocusSupply = (id,supply) => {
        const{setFocusSupply}=this.props;
        setFocusSupply(id,supply);
    };

    handleShowModal = (modal,id,supply) => {
        const {setFocusSupply}=this.props;
        if(modal === 'Editar Insumo')
            setFocusSupply(id,supply);
        this.setState({
            isSupply: modal === 'nuevo insumo',
            isEditSupply: modal === 'Editar Insumo',
            isProductSupply: modal === 'Insertar producto',
            isCreateProductSupply: modal === 'Crear Producto',
            isUpdateProductSupply: modal === 'Actualizar Producto'
        })
    };

    handleHiddenModal = () => {
        this.setState({
            isSupply: false,
            isEditSupply: false,
            isProductSupply: false,
            isCreateProductSupply: false,
            isUpdateProductSupply: false
        })
    };

    handleChangeState = (id) => {
        const {supplyChangeStateRequesting, provider} = this.props;
        supplyChangeStateRequesting(id, provider.token);
    };

    handleSearch = (value)=>{
        const {supplySearchRequesting, provider, supply} = this.props;
        if ((supply.search !== value.search) && value.search !== '')
            supplySearchRequesting(value.search, provider.token);
    };

    handleGetCsv = () => {
        const {login: {user}} = this.props;
        window.open(`${process.env.REACT_APP_API_URL}/api/exports/suppliesProvider?email=${user.correo}`, "_blank")
    };

    handleFilter = (value)=> {
        const {suppliesGetRequesting, provider, supply} = this.props;
        if (supply.filter !== value)
            suppliesGetRequesting(supply.paginate, value, provider.token);
    };

    handleGetData = (paginate) => {
        const {suppliesGetRequesting, provider, supply} = this.props;
        if (paginate === 1 && supply.hasMore)
            suppliesGetRequesting(supply.paginate + paginate, supply.filter, provider.token);

        if (paginate === -1 && supply.hasLess)
            suppliesGetRequesting(supply.paginate + paginate, supply.filter, provider.token);
    };

    componentWillMount() {
        const {
            provider,
            suppliesGetRequesting,
            supply: {paginate, filter},
            statesSupplyRequesting
        } = this.props;
        suppliesGetRequesting(paginate, filter, provider.token);
        statesSupplyRequesting(provider.token)
    };

    stopPropagation = (e) => {
        e.stopPropagation();
    };

    render() {
        const {supply: {supplies, search,supply}, state:{statesSupply}, login: {user}} = this.props;
        return (
            <div className="styles-container">
                <div className="styles-container">
                    <SearchComponent
                        type={'Insumo'}
                        placeholder={'Consultar en Insumo'}
                        handleSearch={this.handleSearch.bind(this)}
                    >{search !== '' && (
                        supply.length > 0 ? <TableSupply
                            handleShowModal={this.handleShowModal.bind(this)}
                            supplies={supply}
                            dataCountry={user.datos_pais}
                            changeState={this.handleChangeState.bind(this)}
                        /> : <NotFound/>
                    )}
                    </SearchComponent>
                    <div className="conteiner-page-sector1">
                        <FilterComponent
                            type={'nuevo insumo'}
                            handleShowModal={this.handleShowModal.bind(this)}
                            handleGetCsv={this.handleGetCsv.bind(this)}
                            handleFilter={this.handleFilter.bind(this)}
                            getData={this.handleGetData.bind(this)}
                            states={statesSupply}
                        />
                        {supplies.length > 0 ? (
                            <TableSupply
                                handleShowModal={this.handleShowModal.bind(this)}
                                supplies={supplies}
                                dataCountry={user.datos_pais}
                                changeState={this.handleChangeState.bind(this)}
                                handleFocusSupply={this.handleFocusSupply.bind(this)}
                            />
                        ): <NotFound/>}

                        {this.state.isSupply && (
                            <ModalNewSupply
                                stop={this.stopPropagation.bind(this)}
                                hidden={this.handleHiddenModal.bind(this)}/>
                        )}

                        {this.state.isEditSupply && (
                            <ModalEditSupply
                                stop={this.stopPropagation.bind(this)}
                                hidden={this.handleHiddenModal.bind(this)}/>
                        )}

                        {this.state.isCreateProductSupply && (
                            <ModalNewSupplyProduct
                                stop={this.stopPropagation.bind(this)}
                                hidden={this.handleHiddenModal.bind(this)}/>
                        )}

                        {this.state.isUpdateProductSupply && (
                            <ModalUpdateSupplyProduct
                                stop={this.stopPropagation.bind(this)}
                                hidden={this.handleHiddenModal.bind(this)}/>
                        )}    

                        {this.state.isProductSupply && (
                            <ModalProductSupply
                                stop={this.stopPropagation.bind(this)}
                                hidden={this.handleHiddenModal.bind(this)}
                                handleShowModal={this.handleShowModal.bind(this)}
                                />
                                
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    provider: state.provider,
    supply: state.supply,
    state: state.state,
    login: state.login,
});

const connected = connect(mapStateToProps, {
    suppliesGetRequesting,
    supplyChangeStateRequesting,
    setFocusSupply,
    supplySearchRequesting,
    suppliesProductsGetRequesting,
    setFocusSuppliesProducts,
    statesSupplyRequesting
})(SupplyComponent);

const formed = reduxForm({
    form: 'SupplyComponent'
})(connected);

export default formed;