import {call, put, takeEvery, all} from 'redux-saga/effects';
import {handleApiErrors} from "../../../../commons/errors/apiErrors";
import {
    GET_SUPPLY_REQUESTING,
    SUPPLIES_PRODUCTS_GET_REQUESTING,
    SUPPLY_CHANGE_STATE_REQUESTING,
    SUPPLY_CREATE_REQUESTING,
    SUPPLY_SEARCH_REQUESTING,
    SUPPLY_UPDATE_REQUESTING,
    SUPPLIES_PRODUCTS_CREATE_REQUESTING,
    SUPPLIES_PRODUCTS_UPDATE_REQUESTING
} from "../Redux/constants";
import {
    suppliesGetSuccess,
    suppliesGetError,
    supplyCreateError,
    supplyCreateSuccess,
    supplyUpdateSuccess,
    supplyUpdateError,
    supplyChangeStateSuccess,
    supplyChangeStateError,
    supplySearchSuccess,
    supplySearchError,
    suppliesProductsGetSuccess,
    suppliesProductsGetError,
    suppliesProductsCreateSuccess,
    suppliesProductsCreateError,
    suppliesProductsUpdateSuccess,
    suppliesProductsUpdateError,

} from "./actions";

const suppliesURL = `${process.env.REACT_APP_API_URL}/api/supplies`;
const suplyURL = `${process.env.REACT_APP_API_URL}/api/supply`;
const productSuppliesURL = `${process.env.REACT_APP_API_URL}/api/productSupplies`;
const suppliesProviderUrl = `${process.env.REACT_APP_API_URL}/api/suppliesProvider`;

//obtener datos
function suppliesGetApi(paginate, filter, token) {

    return fetch(`${suppliesProviderUrl}/filter/${filter}?page=${paginate}`, {
        method: 'GET',
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.data.data.length > 0)
                return json.data.data;
            else
                throw json
        }).catch((error) => {
            throw error;
        })
}

function* suppliesGetFlow(action) {
    try {
        const {paginate, filter, token} = action;
        const supplies = yield call(suppliesGetApi, paginate, filter, token);
        yield put(suppliesGetSuccess(supplies));
    } catch (error) {
        yield put(suppliesGetError(error))
    }
}

//crear datos
const supplyCreateApi = (supply, token) => {
    let body = new FormData();
    body.append('nombre', supply.nombre || '');
    body.append('referencia', supply.referencia || '');
    body.append('descripcion', supply.descripcion || '');
    body.append('precio', supply.precio || '');
    body.append('precio_gerente_lider', supply.precio_gerente_lider || '');
    body.append('precio_descuento', supply.precio_descuento || '');
    body.append('tiempo_envio', supply.tiempo_envio || '');
    body.append('precio_envio', supply.precio_envio || '');
    body.append('inventario_disponible', supply.inventario_disponible || '');
    body.append('limite_inventario', supply.limite_inventario || '');
    body.append('medidas', supply.medidas || '');
    // body.append('unidad_medida', supply.unidad_medida || '');
    body.append('marca', supply.marca || '');
    if (supply.foto) {
        body.append('foto', supply.foto[0].file || '');
    }
    return fetch(suppliesURL, {
        method: 'POST',
        headers: {
            Authorization: 'Bearer' + token,
        },
        body: body
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.data.code === 400)
                throw json.data;
            if (json.code === 200)
                return json.data.data;
        }).catch((error) => {
            throw error;
        })
};

function* supplyCreateFlow(action) {
    try {
        const {supply, token} = action;
        const supplyCreated = yield call(supplyCreateApi, supply, token);
        yield put(supplyCreateSuccess(supplyCreated))
    } catch (error) {
        yield put(supplyCreateError(error))
    }
}

//actualizar datos
const supplyUpdateApi = (token, values, id) => {
    let body = new FormData();
    body.append('_method', 'put');
    body.append('nombre', values.nombre || '');
    body.append('referencia', values.referencia || '');
    body.append('descripcion', values.descripcion || '');
    body.append('precio', values.precio || '');
    body.append('precio_gerente_lider', values.precio_gerente_lider || '');
    body.append('precio_descuento', values.precio_descuento || '');
    body.append('tiempo_envio', values.tiempo_envio || '');
    body.append('precio_envio', values.precio_envio || '');
    body.append('inventario_disponible', values.inventario_disponible || '');
    body.append('limite_inventario', values.limite_inventario || '');
    body.append('medidas', values.medidas || '');
    // body.append('unidad_medida', values.unidad_medida || '');
    body.append('marca', values.marca || '');
    if (values.new_photo !== undefined && values.new_photo.length > 0) {
        body.append('foto', values.new_photo[0].file || '');
    }

    return fetch(`${suppliesURL}/${id}`, {
        method: 'POST',
        headers: {
            Authorization: 'Bearer' + token,
        },
        body: body
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.data.code === 400)
                throw json.data;
            if (json.code === 200)
                return json.data.data;
            else
                throw json.data;
        }).catch((error) => {
            throw error
        })
};

function* supplyUpdateFlow(action) {
    try {
        const {values, token, id} = action;
        const supply = yield call(supplyUpdateApi, values, token, id);
        yield put(supplyUpdateSuccess(supply))
    } catch (error) {
        yield put(supplyUpdateError(error));
    }
}

//cambiar estado
const supplyChangeStateApi = (supplyId, token) => {
    return fetch(`${suppliesURL}/${supplyId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer' + token
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.data.data.hasOwnProperty('id'))
                return json.data.data;
            else
                throw json
        }).catch((error) => {
            throw error;
        })
};

function* supplyChangeStateFlow(action) {
    try {
        const {supplyId, token} = action;
        const supplyChange = yield call(supplyChangeStateApi, supplyId, token);
        yield put(supplyChangeStateSuccess(supplyChange))
    } catch (error) {
        yield put(supplyChangeStateError(error));
    }
}

//buscar dato especifico
const supplySearchApi = (search, token) => {

    let body = {
        search: search
    };

    return fetch(`${suplyURL}/searches`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer' + token
        },
        body: JSON.stringify(body)
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.data.data.length > 0)
                return json.data.data;
            else
                throw json
        }).catch((error) => {
            throw error;
        })
};

function* supplySearchFlow(action) {
    try {
        const {search, token} = action;
        const supply = yield call(supplySearchApi, search, token);
        yield put(supplySearchSuccess(supply));
    } catch (error) {
        yield put(supplySearchError(error));
    }
}

const suppliesProductGetApi = (token) => {

    return fetch(`${productSuppliesURL}`, {
        method: 'GET',
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.data.data.length > 0)
                return json.data.data;
            else
                throw json
        }).catch((error) => {
            throw error;
        })
};

function* suppliesProductGetFlow(action) {
    try {
        const {token} = action;
        const productSupplies = yield call(suppliesProductGetApi, token);
        yield put(suppliesProductsGetSuccess(productSupplies));
    } catch (error) {
        yield put(suppliesProductsGetError(error))
    }
}


//crear datos
const suppliesProductCreateApi = (supply, token) => {
    let body = new FormData();
    body.append('producto', supply.producto || '');
    body.append('insumo', supply.insumo || '');

    return fetch(productSuppliesURL, {
        method: 'POST',
        headers: {
            Authorization: 'Bearer' + token,
        },
        body: body
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.data.code === 400)
                throw json.data;
            if (json.code === 200)
                return json.data.data;
        }).catch((error) => {
            throw error;
        })
};

function* suppliesProductsCreateFlow(action) {
    try {
        const {suppliesProducts, token} = action;
        const suppliesProductsCreated = yield call(suppliesProductCreateApi, suppliesProducts, token);
        yield put(suppliesProductsCreateSuccess(suppliesProductsCreated))
    } catch (error) {
        yield put(suppliesProductsCreateError(error))
    }
}

//actualizar datos
const suppliesProductsUpdateApi = (token, values, id) => {
    let body = new FormData();
    body.append('_method', 'put');
    body.append('producto', values.producto || '');
    body.append('insumo', values.insumo || '');


    return fetch(`${suppliesURL}/${id}`, {
        method: 'POST',
        headers: {
            Authorization: 'Bearer' + token,
        },
        body: body
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 200)
                return json.data.data;
            else
                throw json.data;
        }).catch((error) => {
            throw error
        })
};

function* suppliesProductsUpdateFlow(action) {
    try {
        const {values, token, id} = action;
        const supply = yield call(suppliesProductsUpdateApi, values, token, id);
        yield put(suppliesProductsUpdateSuccess(supply))
    } catch (error) {
        yield put(suppliesProductsUpdateError(error));
    }
}

function* suppliesWatcher() {
    yield all([
        takeEvery(GET_SUPPLY_REQUESTING, suppliesGetFlow),
        takeEvery(SUPPLY_CREATE_REQUESTING, supplyCreateFlow),
        takeEvery(SUPPLY_UPDATE_REQUESTING, supplyUpdateFlow),
        takeEvery(SUPPLY_CHANGE_STATE_REQUESTING, supplyChangeStateFlow),
        takeEvery(SUPPLY_SEARCH_REQUESTING, supplySearchFlow),
        takeEvery(SUPPLIES_PRODUCTS_GET_REQUESTING, suppliesProductGetFlow),
        takeEvery(SUPPLIES_PRODUCTS_CREATE_REQUESTING, suppliesProductsCreateFlow),
        takeEvery(SUPPLIES_PRODUCTS_UPDATE_REQUESTING, suppliesProductsUpdateFlow)
    ])
}

export default suppliesWatcher;