import React from 'react';
import {toastr} from "react-redux-toastr";
import {Field, reduxForm} from 'redux-form';
import connect from "react-redux/es/connect/connect";
import * as Ladda from "ladda";
import {ErrorComponent} from "../../../commons/errors/errors";
import {capacityPostRequesting} from "../Redux/actions";

class ModalAddCapacity extends React.Component {

    constructor(props) {
        super(props);
        this.buttonLadda = React.createRef();
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        const {
            product: {error, capacitySuccess}
        } = this.props;

        let button = null;

        if (this.buttonLadda.current !== null)
            button = Ladda.create(this.buttonLadda.current);

        if (nextProps.product.capacityRequesting)
            button.start();

        if (nextProps.product.error !== '' && nextProps.product.error !== error) {
            let toastOptions = {
                component: (
                    <ErrorComponent message={nextProps.product.error}/>
                )
            };
            toastr.error('Error', toastOptions);
            button.stop();
        }
        if (nextProps.product.capacitySuccess !== capacitySuccess) {
            toastr.success('Capacidad agregada', 'La capacidad fue agregada correctamente.');
            button.stop();
            this.props.hidden();
        }
    }

    handleAction = (values) => {
        const {
            capacityPostRequesting,
            provider: {token},
            product: {focusProduct}
        } = this.props;
        capacityPostRequesting(token, values, focusProduct);
    };

    render() {
        const {
            handleSubmit,
            product: {capacityRequesting}
        } = this.props;
        return (
            <div className={'body-blur'} onClick={this.props.hidden.bind(this)}>
                <div className="edit-modal" onClick={this.props.stop.bind(this)}>
                    <form onSubmit={handleSubmit(this.handleAction)}>
                        <div className="button-container-close">
                            <button className="Btn-modal-cancel " onClick={this.props.hidden.bind(this)}>
                                <i className="icon-cancel"/>
                            </button>
                        </div>
                        <div className="text-modal">
                            <h1>Agregar capacidad</h1>
                        </div>
                        <div className="item-modal">
                            <label>Capacidad:</label>
                            <Field name={'capacidad'} className='input input_city' component={'input'}/>
                        </div>
                        <div className="item-modal">
                            <label>Precio usuario:</label>
                            <Field name={'precio_usuario'} type={'number'} className='input input_city' component={'input'}/>
                        </div>
                        <div className="item-modal">
                            <label>Precio gerente lider:</label>
                            <Field name={'precio_gerente_lider'} type={'number'} className='input input_city' component={'input'}/>
                        </div>

                        <div className="item-modal">
                            <label>Cantidad:</label>
                            <Field name={'cantidad_inventario'} type={'number'} className='input input_city' component={'input'}/>
                        </div>
                        <div className="item-modal">
                            <label>Limite inventario:</label>
                            <Field name={'limite_inventario'} type={'number'} className='input input_city' component={'input'}/>
                        </div>

                        <div className="button-container">
                            <div className="ladda-button btn-container"  data-style="expand-left">
                                <button className="Btn-modal" onClick={this.props.hidden.bind(this)}>
                                    Cancelar
                                    <i className="icon-cancel styles-icons"/>
                                </button>
                                <button className="Btn-modal" disabled={capacityRequesting} ref={this.buttonLadda}>
                                    Agregar capacidad
                                    <i className="icon-ok styles-icons"/>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

const formed = reduxForm({
    form: 'AddCapacity',
    enableReinitialize: true
})(ModalAddCapacity);

const mapStateToProps = state => ({
    provider: state.provider,
    product: state.product,
});

const connected = connect(mapStateToProps, {
    capacityPostRequesting,
})(formed);

export default connected