import {
    PRODUCT_CREATE_REQUESTING,
    PRODUCT_CREATE_SUCCESS,
    PRODUCT_CREATE_ERROR,

    PRODUCT_UPDATE_REQUESTING,
    PRODUCT_UPDATE_SUCCESS,
    PRODUCT_UPDATE_ERROR,
    SET_FOCUS_PRODUCT,

    PRODUCTS_GET_REQUESTING,
    PRODUCTS_GET_SUCCESS,
    PRODUCTS_GET_ERROR,

    PRODUCT_CHANGE_STATE_REQUESTING,
    PRODUCT_CHANGE_STATE_SUCCESS,
    PRODUCT_CHANGE_STATE_ERROR,

    PRODUCT_SEARCH_REQUESTING,
    PRODUCT_SEARCH_SUCCESS,
    PRODUCT_SEARCH_ERROR,

    PRODUCTS_ALL_GET_REQUESTING,
    PRODUCTS_ALL_GET_SUCCESS,
    PRODUCTS_ALL_GET_ERROR,

    IMAGE_DELETE_REQUESTING,
    IMAGE_DELETE_SUCCESS,
    IMAGE_DELETE_ERROR,

    SHIPPING_TYPE_ATTACH_REQUESTING,
    SHIPPING_TYPE_ATTACH_SUCCESS,
    SHIPPING_TYPE_ATTACH_ERROR,

    SHIPPING_TYPE_DETACH_REQUESTING,
    SHIPPING_TYPE_DETACH_SUCCESS,
    SHIPPING_TYPE_DETACH_ERROR,
    CAPACITY_POST_REQUESTING,
    CAPACITY_POST_SUCCESS,
    CAPACITY_POST_ERROR,
    CAPACITY_PUT_REQUESTING,
    CAPACITY_PUT_SUCCESS,
    CAPACITY_PUT_ERROR,
    CAPACITY_DELETE_REQUESTING,
    CAPACITY_DELETE_SUCCESS,
    CAPACITY_DELETE_ERROR,
    CAPACITY_FOCUS,
    CAPACITY_FOCUS_CLEAN,
    DOCUMENT_DELETE_REQUESTING,
    DOCUMENT_DELETE_SUCCESS,
    DOCUMENT_DELETE_ERROR,
    DOCUMENTS_POST_REQUESTING,
    DOCUMENTS_POST_SUCCESS,
    DOCUMENTS_POST_ERROR,
} from "./constants";


//create products actions
export const productCreate = (product, token) => ({
    type: PRODUCT_CREATE_REQUESTING,
    product,
    token
});
export const productSuccess = (productCreated) => ({
    type: PRODUCT_CREATE_SUCCESS,
    productCreated
});
export const productError = (error) => ({
    type: PRODUCT_CREATE_ERROR,
    error
});

//update products actions
export const updateProductsRequesting = (values, token, id) => ({
    type: PRODUCT_UPDATE_REQUESTING,
    values,
    token,
    id
});
export const updateProductsSuccess = (updateProduct) => ({
    type: PRODUCT_UPDATE_SUCCESS,
    updateProduct
});
export const updateProductsError = (error) => ({
    type: PRODUCT_UPDATE_ERROR,
    error
});
export const setFocusProduct = (id, product = {}) => ({
    type: SET_FOCUS_PRODUCT,
    id,
    product
});

//get datas products actions
export const productsGetRequesting = (paginate, filter, token) => ({
    type: PRODUCTS_GET_REQUESTING,
    paginate,
    filter,
    token
});
export const productsGetSuccess = (products) => ({
    type: PRODUCTS_GET_SUCCESS,
    products,
});
export const productsGetError = (error) => ({
    type: PRODUCTS_GET_ERROR,
    error,
});


//change state action
export const changeStateRequesting = (productId, token) => ({
    type: PRODUCT_CHANGE_STATE_REQUESTING,
    productId,
    token
});
export const changeStateSuccess = (product) => ({
    type: PRODUCT_CHANGE_STATE_SUCCESS,
    product
});
export const changeStateError = (error) => ({
    type: PRODUCT_CHANGE_STATE_ERROR,
    error
});

//consultar dato
export const productSearchRequesting = (search, token) => ({
    type: PRODUCT_SEARCH_REQUESTING,
    search,
    token
});
export const SearchSuccess = (product) => ({
    type: PRODUCT_SEARCH_SUCCESS,
    product
});
export const SearchError = () => ({
    type: PRODUCT_SEARCH_ERROR,
});

export const productsAllGetRequesting = (token) => ({
    type: PRODUCTS_ALL_GET_REQUESTING,
    token,
});

export const productsAllGetSuccess = (productsSelect) => ({
    type: PRODUCTS_ALL_GET_SUCCESS,
    productsSelect,
});

export const productsAllGetError = (error) => ({
    type: PRODUCTS_ALL_GET_ERROR,
    error,
});

export const imageDeleteRequesting = (imageId, token) => ({
    type: IMAGE_DELETE_REQUESTING,
    imageId,
    token
});

export const imageDeleteSuccess = (message) => ({
    type: IMAGE_DELETE_SUCCESS,
    message,
});

export const imageDeleteError = () => ({
    type: IMAGE_DELETE_ERROR,
});

export const shippingTypeAttachRequesting = (token, product, shippingType) => ({
    type: SHIPPING_TYPE_ATTACH_REQUESTING,
    token, product, shippingType,
});

export const shippingTypeAttachSuccess = (product) => ({
    type: SHIPPING_TYPE_ATTACH_SUCCESS,
    product,
});

export const shippingTypeAttachError = (error) => ({
    type: SHIPPING_TYPE_ATTACH_ERROR,
    error,
});

export const shippingTypeDetachRequesting = (token, product, shippingType) => ({
    type: SHIPPING_TYPE_DETACH_REQUESTING,
    token, product, shippingType,
});

export const shippingTypeDetachSuccess = (product) => ({
    type: SHIPPING_TYPE_DETACH_SUCCESS,
    product,
});

export const shippingTypeDetachError = (error) => ({
    type: SHIPPING_TYPE_DETACH_ERROR,
    error,
});

export const capacityPostRequesting = (token, capacity, product) => ({
    type: CAPACITY_POST_REQUESTING,
    token, capacity, product
});

export const capacityPostSuccess = (product) => ({
    type: CAPACITY_POST_SUCCESS,
    product,
});

export const capacityPostError = (error) => ({
    type: CAPACITY_POST_ERROR,
    error,
});

export const capacityFocus = (capacity) => ({
    type: CAPACITY_FOCUS,
    capacity,
});

export const capacityFocusClean = () => ({
    type: CAPACITY_FOCUS_CLEAN,
});

export const capacityPutRequesting = (token, capacity) => ({
    type: CAPACITY_PUT_REQUESTING,
    token, capacity
});

export const capacityPutSuccess = (product) => ({
    type: CAPACITY_PUT_SUCCESS,
    product,
});

export const capacityPutError = (error) => ({
    type: CAPACITY_PUT_ERROR,
    error,
});

export const capacityDeleteRequesting = (token, capacity) => ({
    type: CAPACITY_DELETE_REQUESTING,
    token, capacity,
});

export const capacityDeleteSuccess = (product) => ({
    type: CAPACITY_DELETE_SUCCESS,
    product,
});

export const capacityDeleteError = (error) => ({
    type: CAPACITY_DELETE_ERROR,
    error,
});

export const documentDeleteRequesting = (token, document) => ({
    type: DOCUMENT_DELETE_REQUESTING,
    token, document,
});

export const documentDeleteSuccess = (product) => ({
    type: DOCUMENT_DELETE_SUCCESS,
    product,
});

export const documentDeleteError = (error) => ({
    type: DOCUMENT_DELETE_ERROR,
    error,
});

export const documentsPostRequesting = (token, product, documents) => ({
    type: DOCUMENTS_POST_REQUESTING,
    token, product, documents
});

export const documentsPostSuccess = (product) => ({
    type: DOCUMENTS_POST_SUCCESS,
    product,
});

export const documentsPostError = (error) => ({
    type: DOCUMENTS_POST_ERROR,
    error,
});