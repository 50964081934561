import {call, put, takeEvery, all} from 'redux-saga/effects';
import {handleApiErrors} from "../../../commons/errors/apiErrors";
import {unitMeasureGetError, unitMeasureGetSuccess} from "./actions";
import {UNITMEASURE_GET_REQUESTING} from "./constants";

const unitMeasuresURL  = `${process.env.REACT_APP_API_URL}/api/unitMeasure`;

function unitMeasuresGetApi(token){
    const url = unitMeasuresURL;

    return fetch(url,{
        method: 'GET',
        headers: {
            Authorization: 'Bearer' + token,
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.data.data.length > 0)
                return json.data.data;
            else
                throw json
        }).catch((error)=>{
            throw error
        })
}

function* unitMeasureGetFlow(action){
    try {
        const {token} = action;
        const getUnitMeasure = yield call(unitMeasuresGetApi, token);
        yield put(unitMeasureGetSuccess(getUnitMeasure));
    }catch(error) {
        yield put(unitMeasureGetError(error))
    }
}

function* unitMeasureWatcher() {
    yield all([
        takeEvery(UNITMEASURE_GET_REQUESTING, unitMeasureGetFlow),
    ])
}

export default unitMeasureWatcher