import React from 'react';
import {connect} from "react-redux";
import {Field, reduxForm} from "redux-form";
import FormattedCurrency from 'react-formatted-currency';

class ModalInfoOrder extends React.Component {

    render() {
        const {
            order: {orderDataFocus},
            login: {user: {datos_pais}}
        } = this.props;
        return (
            <div className={'body-blur'} onClick={this.props.hidden.bind(this)}>
                <div className="edit-modal" style={{textAlign: 'left'}} onClick={this.props.stop.bind(this)}>
                    <h1 className="arreglojulian">
                        Informacion de la orden
                    </h1>
                    <div className="text-modal-p">
                        <p>{new Date(orderDataFocus.fecha_orden).toLocaleDateString('es-CO', {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                            weekday: 'long',
                            hour: "numeric",
                            minute: "numeric",
                            hour12: "false",
                            timeZone: "America/Bogota",
                        })}</p>
                    </div>
                    <div className="text-modal-h2">
                        <h2>Estado: {orderDataFocus.estado}</h2>
                    </div>
                    <div className="text-modal-h2">
                        <h2>Datos del usuario</h2>
                    </div>
                    <div className="text-modal-p">
                        <p><strong>Nombre: </strong>{orderDataFocus.usuario.nombre}</p>
                        <p><strong>Identificación: </strong>{orderDataFocus.usuario.numero_identificacion}</p>
                        <p><strong>Telefono: </strong>{orderDataFocus.usuario.telefono}</p>
                    </div>
                    <div className="text-modal-h2">
                        <h2>Detalles de la orden</h2>
                    </div>
                    <div className={'text-moda-p'}>
                        <p><strong>Ciudad de envio: </strong>{orderDataFocus.ciudad === '' ? 'Usuario recoge en tienda' : orderDataFocus.ciudad}</p>
                        <p><strong>Direccón de envio: </strong>{orderDataFocus.direccion}</p>
                    </div>
                    {orderDataFocus.productos.data.length > 0 && (
                        <div className="Conteiner-table">
                            <table className="table-detail-order">
                                <thead>
                                <tr>
                                    <th>Producto</th>
                                    <th>Precio</th>
                                    <th>Valor del Envío</th>
                                    <th>Cantidad</th>
                                    <th>Total</th>
                                </tr>
                                </thead>
                                <tbody>
                                {orderDataFocus.hasOwnProperty('servicio') && orderDataFocus.servicio.id !== 'No hay servicio asociado'
                                    // && orderDataFocus.servicio.pasar_instalar === 1
                                    ?
                                    <>
                                        {orderDataFocus.productos.data.map((product, index) => {
                                            return <tr key={index}>
                                                <td>{product.producto.data.nombre}</td>
                                                <td>{<FormattedCurrency
                                                    amount={product.precio_gerente_lider}
                                                    currency={orderDataFocus.proveedor.datos_pais.moneda}
                                                    symbol={orderDataFocus.proveedor.datos_pais.simbolo_moneda}
                                                    locale={orderDataFocus.proveedor.datos_pais.ubicacion}
                                                    parts={['code', 'number', 'symbol']}
                                                    render={({symbol, number}) => {
                                                        return `${symbol} ${number}`
                                                    }}
                                                />}</td>
                                                <td>{
                                                    <FormattedCurrency
                                                        amount={product.ciudad !== 'Sin ciudad asignada' ? product.producto.data.valor_envio : 0}
                                                        currency={orderDataFocus.proveedor.datos_pais.moneda}
                                                        symbol={orderDataFocus.proveedor.datos_pais.simbolo_moneda}
                                                        locale={orderDataFocus.proveedor.datos_pais.ubicacion}
                                                        parts={['code', 'number', 'symbol']}
                                                        render={({symbol, number}) => {
                                                            return `${symbol} ${number}`
                                                        }}
                                                    />}</td>
                                                <td>{product.cantidad}</td>
                                                <td>
                                                    <FormattedCurrency
                                                        amount={product.ciudad !== 'Sin ciudad asignada' ? ((product.precio_gerente_lider * product.cantidad) + product.producto.data.valor_envio) : (product.precio_gerente_lider * product.cantidad)}
                                                        currency={orderDataFocus.proveedor.datos_pais.moneda}
                                                        symbol={orderDataFocus.proveedor.datos_pais.simbolo_moneda}
                                                        locale={orderDataFocus.proveedor.datos_pais.ubicacion}
                                                        parts={['code', 'number', 'symbol']}
                                                        render={({symbol, number}) => {
                                                            return `${symbol} ${number}`
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                        })}
                                    </>
                                    : <>
                                        {orderDataFocus.productos.data.map((product, index) => {
                                            let totalProduct = 0;
                                            totalProduct += product.precio * product.cantidad_producto;
                                            return <tr key={index}>
                                                <td>{product.producto.data.nombre}</td>
                                                <td>{<FormattedCurrency
                                                    amount={product.precio_usuario}
                                                    currency={orderDataFocus.proveedor.datos_pais.moneda}
                                                    symbol={orderDataFocus.proveedor.datos_pais.simbolo_moneda}
                                                    locale={orderDataFocus.proveedor.datos_pais.ubicacion}
                                                    parts={['code', 'number', 'symbol']}
                                                    render={({symbol, number}) => {
                                                        return `${symbol} ${number}`
                                                    }}
                                                />}</td>
                                                <td>{
                                                    <>
                                                        <FormattedCurrency
                                                            amount={orderDataFocus.instalar_comprar !== 1 ? product.producto.data.valor_envio : 0}
                                                            currency={orderDataFocus.proveedor.datos_pais.moneda}
                                                            symbol={orderDataFocus.proveedor.datos_pais.simbolo_moneda}
                                                            locale={orderDataFocus.proveedor.datos_pais.ubicacion}
                                                            parts={['code', 'number', 'symbol']}
                                                            render={({symbol, number}) => {
                                                                return `${symbol} ${number}`
                                                            }}
                                                        />
                                                    </>
                                                }</td>
                                                <td>{product.cantidad}</td>
                                                <td>
                                                    <FormattedCurrency
                                                        amount={orderDataFocus.instalar_comprar !== 1   ? ((product.precio_usuario * product.cantidad) + product.producto.data.valor_envio) : (product.precio_usuario * product.cantidad)}
                                                        currency={orderDataFocus.proveedor.datos_pais.moneda}
                                                        symbol={orderDataFocus.proveedor.datos_pais.simbolo_moneda}
                                                        locale={orderDataFocus.proveedor.datos_pais.ubicacion}
                                                        parts={['code', 'number', 'symbol']}
                                                        render={({symbol, number}) => {
                                                            return `${symbol} ${number}`
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                        })}
                                    </>}
                                </tbody>
                            </table>
                        </div>
                    )}
                    {orderDataFocus.insumos.data.length > 0 && (
                        <div className="Conteiner-table">
                            <table className="table-detail-order">
                                <thead>
                                <tr>
                                    <th>Insumos</th>
                                    <th>Precio</th>
                                    <th>Valor del Envío</th>
                                    <th>Cantidad</th>
                                    <th>Total</th>
                                </tr>
                                </thead>
                                <tbody>
                                {orderDataFocus.insumos.data.map((supply, index) => {
                                    return <tr key={index}>
                                        <td>{supply.insumo.data.nombre}</td>
                                        <td>{<FormattedCurrency
                                            amount={supply.insumo.data.precio_gerente_lider}
                                            currency={orderDataFocus.proveedor.datos_pais.moneda}
                                            symbol={orderDataFocus.proveedor.datos_pais.simbolo_moneda}
                                            locale={orderDataFocus.proveedor.datos_pais.ubicacion}
                                            parts={['code', 'number', 'symbol']}
                                            render={({symbol, number}) => {
                                                return `${symbol} ${number}`
                                            }}
                                        />}</td>
                                        <td>{
                                            <FormattedCurrency
                                                amount={supply.insumo.data.precio_envio}
                                                currency={orderDataFocus.proveedor.datos_pais.moneda}
                                                symbol={orderDataFocus.proveedor.datos_pais.simbolo_moneda}
                                                locale={orderDataFocus.proveedor.datos_pais.ubicacion}
                                                parts={['code', 'number', 'symbol']}
                                                render={({symbol, number}) => {
                                                    return `${symbol} ${number}`
                                                }}
                                            />}</td>
                                        <td>{supply.cantidad}</td>
                                        <td>
                                            <FormattedCurrency
                                                amount={((supply.insumo.data.precio_gerente_lider * supply.cantidad) + supply.insumo.data.precio_envio)}
                                                currency={orderDataFocus.proveedor.datos_pais.moneda}
                                                symbol={orderDataFocus.proveedor.datos_pais.simbolo_moneda}
                                                locale={orderDataFocus.proveedor.datos_pais.ubicacion}
                                                parts={['code', 'number', 'symbol']}
                                                render={({symbol, number}) => {
                                                    return `${symbol} ${number}`
                                                }}
                                            />
                                        </td>
                                    </tr>
                                })}
                                </tbody>
                            </table>
                        </div>
                    )}
                    <div className="text-modal-h2">
                        <h2>Datos de facturación</h2>
                    </div>
                    <div className="text-modal-p">
                        <p><strong>Metodo de pago: </strong> {orderDataFocus.metodo_pago}</p>
                        <p><strong>Total de la orden : </strong>
                            <FormattedCurrency
                                amount={orderDataFocus.total}
                                currency={datos_pais.moneda}
                                symbol={datos_pais.simbolo_moneda}
                                locale={datos_pais.ubicacion}
                                parts={['code', 'number', 'symbol']}
                                render={({symbol, number}) => {
                                    return `${symbol} ${number}`
                                }}
                            />
                        </p>
                    </div>
                    <div className="text-modal-h2">
                        <h2>Datos de mensajeria</h2>
                    </div>
                    <div className="text-modal-p">
                        <p>Empresa: {orderDataFocus.empresa_mensajeria ? orderDataFocus.empresa_mensajeria : 'Sin asignar aún'}</p>
                        <p>Numero de remesa: {orderDataFocus.numero_remesa ? orderDataFocus.numero_remesa : 'Sin asignar aún'}</p>
                    </div>
                    <div className="button-container">
                        <button className="Btn-modal" onClick={this.props.hidden.bind(this)}>
                            Cerrar
                            <i className="icon-cancel styles-icons"/>
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

const formed = reduxForm({
    form: 'OrderInfoModal'
})(ModalInfoOrder);

const mapStateToProps = state => ({
    order: state.order,
    login: state.login,
});

const connected = connect(mapStateToProps, {})(formed);

export default connected
