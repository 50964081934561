//get datas supplies actions
import {
    SET_FOCUS_SUPPLY, SET_FOCUS_SUPPLY_PRODUCT,
    GET_SUPPLY_ERROR,
    GET_SUPPLY_REQUESTING,
    GET_SUPPLY_SUCCESS,
    SUPPLIES_PRODUCT_GET_ERROR,
    SUPPLIES_PRODUCT_GET_REQUESTING,
    SUPPLIES_PRODUCT_GET_SUCCESS,
    SUPPLY_CHANGE_STATE_ERROR,
    SUPPLY_CHANGE_STATE_REQUESTING,
    SUPPLY_CHANGE_STATE_SUCCESS,
    SUPPLY_CREATE_ERROR,
    SUPPLY_CREATE_REQUESTING,
    SUPPLY_CREATE_SUCCESS,
    SUPPLY_PRODUCT_CREATE_ERROR,
    SUPPLY_PRODUCT_CREATE_REQUESTING,
    SUPPLY_PRODUCT_CREATE_SUCCESS, SUPPLY_PRODUCT_UPDATE_ERROR,
    SUPPLY_PRODUCT_UPDATE_REQUESTING, SUPPLY_PRODUCT_UPDATE_SUCCESS,
    SUPPLY_SEARCH_ERROR,
    SUPPLY_SEARCH_REQUESTING,
    SUPPLY_SEARCH_SUCCESS,
    SUPPLY_UPDATE_ERROR,
    SUPPLY_UPDATE_REQUESTING,
    SUPPLY_UPDATE_SUCCESS,
    SUPPLIES_PRODUCTS_GET_ERROR,
    SUPPLIES_PRODUCTS_GET_REQUESTING,
    SUPPLIES_PRODUCTS_GET_SUCCESS,
    SUPPLIES_PRODUCTS_CREATE_REQUESTING,
    SUPPLIES_PRODUCTS_CREATE_SUCCESS,
    SUPPLIES_PRODUCTS_CREATE_ERROR,
    SET_FOCUS_SUPPLIES_PRODUCTS, SUPPLY_RESET_STATES,
} from "./constants";

//obtener datos
export const suppliesGetRequesting = (paginate,filter, token) => ({
    type: GET_SUPPLY_REQUESTING,
    paginate,
    filter,
    token
});
export const suppliesGetSuccess = (supplies) => ({
    type: GET_SUPPLY_SUCCESS,
    supplies
});
export const suppliesGetError = (error) => ({
    type: GET_SUPPLY_ERROR,
    error,
});

//crear datos
export const supplyCreateRequesting = (supply, token) => ({
    type: SUPPLY_CREATE_REQUESTING,
    supply,
    token
});
export const supplyCreateSuccess = (supplyCreated) => ({
    type: SUPPLY_CREATE_SUCCESS,
    supplyCreated
});
export const supplyCreateError = (error) => ({
    type: SUPPLY_CREATE_ERROR,
    error
});

//actualizar datos
export const supplyUpdateRequesting = (values, token, id) => ({
    type: SUPPLY_UPDATE_REQUESTING,
    values,
    token,
    id
});
export const supplyUpdateSuccess = (userSupply) => ({
    type: SUPPLY_UPDATE_SUCCESS,
    userSupply,
});
export const supplyUpdateError = (error) => ({
    type: SUPPLY_UPDATE_ERROR,
    error
});
export const setFocusSupply = (id, supply = {})=> ({
   type: SET_FOCUS_SUPPLY,
   id,
   supply
});

//cambiar estado
export const supplyChangeStateRequesting = (supplyId, token) => ({
    type: SUPPLY_CHANGE_STATE_REQUESTING,
    supplyId,
    token
});
export const supplyChangeStateSuccess = (supply) => ({
    type: SUPPLY_CHANGE_STATE_SUCCESS,
    supply
});
export const supplyChangeStateError = (error) => ({
    type: SUPPLY_CHANGE_STATE_ERROR,
    error,
});

//consultar datos
export const supplySearchRequesting = (search, token) => ({
    type: SUPPLY_SEARCH_REQUESTING,
    search,
    token
});
export const supplySearchSuccess = (supply) => ({
    type: SUPPLY_SEARCH_SUCCESS,
    supply
});
export const supplySearchError = (error) => ({
    type: SUPPLY_SEARCH_ERROR,
    error
});

//obtener datos insumoProducto
export const suppliesProductsGetRequesting = (productSupplies, token) => ({
    type: SUPPLIES_PRODUCTS_GET_REQUESTING,
    productSupplies,
    token
});
export const suppliesProductsGetSuccess = (productSupplies) => ({
    type: SUPPLIES_PRODUCTS_GET_SUCCESS,
    productSupplies
});
export const suppliesProductsGetError = (error) => ({
    type: SUPPLIES_PRODUCTS_GET_ERROR,
    error,
});


//crear datos insumoProducto
export const suppliesProductsCreateRequesting = (productSupplies, token) => ({
    type: SUPPLIES_PRODUCTS_CREATE_REQUESTING,
    productSupplies,
    token
});
export const suppliesProductsCreateSuccess = (productSuppliesCreated) => ({
    type: SUPPLIES_PRODUCTS_CREATE_SUCCESS,
    productSuppliesCreated
});
export const suppliesProductsCreateError = (error) => ({
    type: SUPPLIES_PRODUCTS_CREATE_ERROR,
    error,
});

//actualizar datos
export const suppliesProductsUpdateRequesting = (values, token, id) => ({
    type: SUPPLY_UPDATE_REQUESTING,
    values,
    token,
    id
});
export const suppliesProductsUpdateSuccess = (suppliesProducts) => ({
    type: SUPPLY_UPDATE_SUCCESS,
    suppliesProducts,
});
export const suppliesProductsUpdateError = (error) => ({
    type: SUPPLY_UPDATE_ERROR,
    error
});

export const setFocusSuppliesProducts = (supply)=> ({
    type: SET_FOCUS_SUPPLIES_PRODUCTS,
    supply,
 });

export const supplyResetStates = () => ({
    type: SUPPLY_RESET_STATES,
});
