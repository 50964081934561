import {BRANDS_GET_ERROR, BRANDS_GET_REQUESTING, BRANDS_GET_SUCCESS} from "./constants";

const initialState = {
    requesting: false,
    success: false,
    error: '',
    message: '',
    brands: [],
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case BRANDS_GET_REQUESTING:
            return {
                ...state,
                brands: [],
            };
        case BRANDS_GET_SUCCESS:
            return {
                ...state,
                brands: action.brands
            };
        case BRANDS_GET_ERROR:
            return {
                ...state,
                error: action.error,
            };
        default:
            return state;
    }
};
export default reducer