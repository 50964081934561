import React from 'react';
import {Field, Form, reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import {orderStatePostRequesting} from "../redux/actions";
import {ErrorComponent} from "../../../commons/errors/errors";
import {toastr} from "react-redux-toastr";
import * as Ladda from "ladda";

class ModalDetailOrder extends React.Component {

    state = {
        showForm: false,
    };

    constructor(props) {
        super(props);
        this.buttonEdit = React.createRef();
    }

    componentWillUpdate(nextProps) {
        const {order: {requestingStates, successStates, error}} = this.props;
        let button = null;

        if (this.buttonEdit.current !== null)
            button = Ladda.create(this.buttonEdit.current);

        if (nextProps.order.requestingStates)
            button.start();

        if (nextProps.order.error !== '' && nextProps.order.error !== error) {
            let toastOptions = {
                component: (
                    <ErrorComponent message={nextProps.order.error}/>
                )
            };
            toastr.error('Recuerda', toastOptions);
            button.stop();
        }
        if (nextProps.order.requestingStates)
            button.stop();

        if (nextProps.order.successStates && !successStates) {
            toastr.success('Orden Actualizada', 'Los datos han sido actualizados correctamente');
            this.props.hidden();
        }
    }

    handleShowForm = (boolean) => {
        this.setState({
            showForm: boolean,
        })
    };

    handleAction = (values) => {
        const {
            provider: {token},
            order: {orderDataFocus},
            orderStatePostRequesting,
        } = this.props;
        orderStatePostRequesting(token, orderDataFocus.id, values);
    };

    render() {
        const {
            handleSubmit,
            states,
            order: {orderDataFocus: {estados, estado}},
        } = this.props;
        const {showForm} = this.state;
        return (
            <div className={'body-blur'} onClick={this.props.hidden.bind(this)}>
                <div className="edit-modal" onClick={this.props.stop.bind(this)}>
                    <h1 className="arreglojulian">Estados de orden</h1>
                    {estados.data.length > 0 && estados.data.map((state, index) => (
                        <div key={index} style={{padding: 12}}>
                            <div style={{display: 'flex'}}>
                                <p style={{width: 'auto', fontWeight: 'bold', fontSize: 14}}>Estado : </p>
                                <p style={{width: 'auto', marginLeft: 7}}> {state.estado}</p>
                            </div>
                            <div style={{display: 'flex'}}>
                                <p style={{width: 'auto', fontWeight: 'bold', fontSize: 14}}>Observación : </p>
                                <p style={{width: 'auto', marginLeft: 7}}> {state.observacion}</p>
                            </div>

                            <div style={{display: 'flex'}}>
                                <p style={{width: 'auto', fontWeight: 'bold', fontSize: 14}}>Fecha : </p>
                                <p style={{width: 'auto', marginLeft: 7}}> {state.fecha_creacion && state.fecha_creacion.date ? state.fecha_creacion.date.split('.')[0] : state.fecha_creacion}</p>
                            </div>
                            <div style={{width: '100%', height: 1.5, backgroundColor: 'rgba(0,0,0,.1)'}}/>
                        </div>
                    ))}
                    {estados.data.length === 0 && showForm === false && (
                        <div style={{
                            textAlign: 'center',
                            marginTop: '5px',
                            marginBottom: '5px',
                            display: 'flex',
                            justifyContent: 'center'
                        }}>
                            <h2>Sin estados registrados.</h2>
                        </div>
                    )}
                    <div className="button-container">
                        {showForm === false && (
                            <div className="btn-container">
                                <button className="Btn-modal" onClick={this.props.hidden.bind(this)}>
                                    Cancelar
                                    <i className="icon-cancel styles-icons"/>
                                </button>
                                {estado !== 'Entregado' && (
                                    <button className="Btn-modal" onClick={() => this.handleShowForm(true)}>
                                        {estados.data.length === 0 ? 'Agregar estado' : 'Agregar nuevo estado'}
                                        <i className="icon-ok styles-icons"/>
                                    </button>
                                )}

                            </div>
                        )}
                    </div>
                    {showForm && (
                        <Form onSubmit={handleSubmit(this.handleAction)}>
                            <div className="item-modal">
                                <label>Observación:</label>
                                <Field name="observacion" type="text" className="input input-observation"
                                       component={'input'}/>
                            </div>
                            <div className="item-modal">
                                <label>Estado: </label>
                                <Field name={'estado'}
                                       type={'select'}
                                       className={'select-typeSubcategory'}
                                       component={'select'}
                                >
                                    <option>Seleccione un estado</option>
                                    {states.length > 0 && states.map((state, index) => (
                                        <option key={index} value={state.nombre}>{state.nombre}</option>
                                    ))}
                                </Field>
                            </div>
                            <div className="button-container">
                                <div className="ladda-button btn-container"  data-style="expand-left">
                                    <button className="Btn-modal" onClick={this.props.hidden.bind(this)}>
                                        Cancelar
                                        <i className="icon-cancel styles-icons"/>
                                    </button>
                                    <button action="submit" className="Btn-modal" ref={this.buttonEdit}>
                                        Guardar
                                        <i className="icon-ok styles-icons"/>
                                    </button>
                                </div>
                            </div>
                        </Form>
                    )}
                </div>
            </div>
        )
    }

}

const mapStateToProps = state => ({
    provider: state.provider,
    order: state.order,
});
const connected = connect(mapStateToProps, {
    orderStatePostRequesting
})(ModalDetailOrder);

const UpdateOrder = reduxForm({
    form: 'ModalDetailOrder',
})(connected);

export default UpdateOrder;