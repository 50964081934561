import {GET_STATES_REQUESTING,
        GET_STATES_SUCCESS,
        STATES_SUPPLY_ERROR,
        STATES_SUPPLY_REQUESTING,
        STATES_SUPPLY_SUCCESS,
        STATES_PRODUCT_ERROR,
        STATES_PRODUCT_REQUESTING,
        STATES_PRODUCT_SUCCESS,
        STATES_ORDER_ERROR,
        STATES_ORDER_REQUESTING,
        STATES_ORDER_SUCCESS,
        GET_STATES_ERROR,
        UPDATE_STATE_ORDER_ERROR,
        UPDATE_STATE_ORDER_REQUESTING,
        UPDATE_STATE_ORDER_SUCCESS
} from "./constans";

export const statesOrderRequesting = (token) => ({
    type: STATES_ORDER_REQUESTING,
    token
});

export const statesOrderSuccess = (states) => ({
    type: STATES_ORDER_SUCCESS,
    states
});

export const statesOrderError = (error) => ({
    type: STATES_ORDER_ERROR,
    error
});
export const statesProductRequesting = (token) => ({
    type: STATES_PRODUCT_REQUESTING,
    token
});

export const statesProductSuccess = (states) => ({
    type: STATES_PRODUCT_SUCCESS,
    states
});

export const statesProductError = (error) => ({
    type: STATES_PRODUCT_ERROR,
    error
});
export const statesSupplyRequesting = (token) => ({
    type: STATES_SUPPLY_REQUESTING,
    token
});

export const statesSupplySuccess = (states) => ({
    type: STATES_SUPPLY_SUCCESS,
    states
});

export const statesSupplyError = (error) => ({
    type: STATES_SUPPLY_ERROR,
    error
});
export const getStatesRequesting = (token) => ({
    type: GET_STATES_REQUESTING,
    token
});

export const getStatesSuccess = (states) => ({
    type: GET_STATES_SUCCESS,
    states
});

export const getStatesError = (error) => ({
    type: GET_STATES_ERROR,
    error
});
