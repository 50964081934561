import {
    CATEGORIES_GET_REQUESTING,
    CATEGORIES_GET_SUCCESS,
    CATEGORIES_GET_ERROR
} from "./constants";

const initialState = {
    requesting: false,
    success: false,
    error: '',
    message: '',
    categories: [],
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case CATEGORIES_GET_REQUESTING:
            return {
                ...state,
                categories: [],
            };
        case  CATEGORIES_GET_SUCCESS:
            return {
                ...state,
                categories: action.categories,
            };
        case CATEGORIES_GET_ERROR:
            return {
                ...state,
                error: action.error,
            };
        default:
            return state;
    }
};
export default reducer