import {call, put, takeEvery, all} from 'redux-saga/effects';
import {handleApiErrors} from "../../../commons/errors/apiErrors";
import {typeSubcategoryError, typeSubcategorySuccess} from "./actions";
import {TYPE_SUBCATEGORY_REQUESTING} from "./constants";

const typeSubcategoryURL = `${process.env.REACT_APP_API_URL}/api/subcategoryType`;

function typeSubcategoryGetApi(token){
    const url = typeSubcategoryURL;

    return fetch(url,{
        method: 'GET',
        headers: {
            Authorization: 'Bearer' + token,
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.data.data.length > 0)
                return json.data.data;
            else
                throw json
        }).catch((error)=>{
            throw error
        })
}

function* typeSubcategoryGetFlow(action){
    try{
        const {token} = action;
        const typeSubcategory = yield call(typeSubcategoryGetApi,token);
        yield put(typeSubcategorySuccess(typeSubcategory));
    }catch(error){
        yield put(typeSubcategoryError(error));
    }
}

function* typeSubcategoryWatcher(){
    yield all([
        takeEvery(TYPE_SUBCATEGORY_REQUESTING, typeSubcategoryGetFlow),
    ])
}

export default typeSubcategoryWatcher