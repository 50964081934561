import React from 'react';
import {Field, reduxForm} from "redux-form";
import {connect} from "react-redux";
import {userPasswordPutRequesting, resetStates} from "../../../redux/auth/user/actions";
import * as Ladda from "ladda";
import {ErrorComponent} from "../../../commons/errors/errors";
import {toastr} from "react-redux-toastr";

class ModalChangePassword extends React.Component {

    constructor(props) {
        super(props);
        this.buttonLadda = React.createRef();
    }

    handleChangePassword = (password) => {
        const {provider: {token}, userPasswordPutRequesting, login : {user}} = this.props;
        console.log(user.id);
        userPasswordPutRequesting(token, password, user.id);
    };

    componentWillUpdate(nextProps, nextState, nextContext) {
        const {user} = this.props;
        let button = null;

        if (this.buttonLadda.current !== null)
            button = Ladda.create(this.buttonLadda.current);

        if (nextProps.user.requesting)
            button.start();

        if (nextProps.user.error !== '' && nextProps.user.error !== user.error) {
            let toastOptions = {
                component: (
                    <ErrorComponent message={nextProps.user.error}/>
                )
            };
            toastr.error('Error', toastOptions);
            button.stop();
        }

        if (nextProps.user.success)
            button.stop();

        if (nextProps.user.success && !user.success) {
            toastr.success('Contraseña actualizada', 'Contraseña actualizada, has actualizado tu contraseña correctamente.');
            this.props.resetStates();
            this.props.hidden();
        }
    }

    render() {
        const {handleSubmit} = this.props;
        return (
            <div className={'body-blur'} onClick={this.props.hidden.bind(this)}>
                <div className="edit-modal" onClick={this.props.stop.bind(this)}
                     style={{color: 'black', textAlign: 'left'}}>
                    <div className="button-container-close">
                        <button className="Btn-modal-cancel" onClick={this.props.hidden.bind(this)}>
                            <i className="icon-cancel"/>
                        </button>
                    </div>
                    <form onSubmit={handleSubmit(this.handleChangePassword)}>
                        <div className="text-modal" style={{textAlign: 'center'}}>
                            <h2>Datos de contraseña</h2>
                        </div>
                        <div className="item-modal">
                            <label>Contraseña: (mínimo 6 caracteres)</label>
                            <Field type="password" className="input input-phone" name={'password'}
                                   component={'input'}/>
                        </div>
                        <div className="item-modal">
                            <label>Confirmar contraseña: </label>
                            <Field type="password" className="input input-phone" name={'rePassword'}
                                   component={'input'}/>
                        </div>
                        <div className="ladda-button btn-container"  data-style="expand-left">
                            <button ref={this.buttonLadda} data-color="green" type="submit" className="Btn-modal">
                                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                                    Guardar cambios
                                    <i className="icon-ok-1 styles-icons"/>
                                </div>
                            </button>
                            <button className="Btn-modal" onClick={this.props.hidden.bind(this)}>
                                Cancelar
                                <i className="icon-cancel styles-icons"/>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

const formed = reduxForm({
    form: 'ChangePassword',
})(ModalChangePassword);

const mapStateToProps = state => ({
    provider: state.provider,
    user: state.user,
    login: state.login
});

const connected = connect(mapStateToProps, {
    userPasswordPutRequesting,
    resetStates
})(formed);

export default connected;
