import {call, put, takeEvery, all} from 'redux-saga/effects';
import {handleApiErrors} from "../../../commons/errors/apiErrors";

import {BRANDS_GET_REQUESTING} from "./constants";
import {
    brandsGetSuccess,
    brandsGetError
} from "./actions";

const brandURL = `${process.env.REACT_APP_API_URL}/api/brands`;

function brandsGetApi(token){
    const URL = `${brandURL}`;

    return fetch (URL, {
        method: 'GET',
        headers: {
            Authorization: 'Bearer' + token,
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.data.data.length > 0){
                return json.data.data;
            } else{
                throw json;
            }
        })
        .catch((error)=>{
            throw error
        })
}

function* brandsGetFlow(action){
    try {
        const {token} = action;
        const getBrand = yield call(brandsGetApi, token);
        yield put(brandsGetSuccess(getBrand));
    }catch (error) {
        yield put(brandsGetError(error))
    }
}

function* brandWatcher(){
    yield all([
        takeEvery(BRANDS_GET_REQUESTING, brandsGetFlow)
    ])
}
export default brandWatcher;