import React from 'react';
import {connect} from "react-redux";
import {Field, reduxForm} from "redux-form";
import {toastr} from "react-redux-toastr";
import {ErrorComponent} from "../../../commons/errors/errors";
import PhotoInput from '../../1-Product-supply/file-inputs/photo';
import * as Ladda from "ladda";
import {userPutRequesting, resetStates} from "../../../redux/auth/user/actions";
import CitySelectComponent from '../../city';
class ModalMyAccount extends React.Component {

    constructor(props) {
        super(props);
        this.buttonLadda = React.createRef();
    }

    handleUpdateUser = (user) => {
        const {provider: {token}, userPutRequesting} = this.props;
        userPutRequesting(token, user);
    };

    stopPropagation = (e) => {
        e.stopPropagation();
    };

    componentWillUpdate(nextProps, nextState, nextContext) {
        const {user, resetStates} = this.props;
        let button = null;

        if (this.buttonLadda.current !== null)
            button = Ladda.create(this.buttonLadda.current);

        if (nextProps.user.requesting)
            button.start();

        if (nextProps.user.error !== '' && nextProps.user.error !== user.error) {
            let toastOptions = {
                component: (
                    <ErrorComponent message={nextProps.user.error}/>
                )
            };
            toastr.error('Error', toastOptions);
            button.stop();
        }

        if (nextProps.user.success)
            button.stop();

        if (nextProps.user.success && !user.success) {
            toastr.success('Usuario actualizado', 'Usuario actualizado, has actualizado tu información correctamente.');
            resetStates();
            this.props.hidden();
        }
    }

    render() {
        const {
            handleSubmit,
            login: {user},
        } = this.props;
        return (
            <div className={'body-blur'} onClick={this.props.hidden.bind(this)}>
                <div className="edit-modal" onClick={this.props.stop.bind(this)} style={{color: 'black', textAlign: 'left'}}>
                    <div className="button-container-close">
                        <button className="Btn-modal-cancel" onClick={this.props.hidden.bind(this)}>
                            <i className="icon-cancel"/>
                        </button>
                    </div>
                    <form onSubmit={handleSubmit(this.handleUpdateUser)}>
                        <div className="img-modal" style={{textAlign: 'center'}}>
                            <h2>Mi perfil</h2>
                            <img src={user.foto} alt={user.nombre}/>
                            <Field className="filepond" name={'photo'} component={PhotoInput}/>
                        </div>
                        <div className="text-modal">
                            <h2>Datos personales</h2>
                        </div>
                        <div className="item-modal">
                            <label>Pais:</label>
                            <p>{user.pais}</p>
                        </div>
                        <div className="item-modal">
                            <label>Nombre</label>
                            <p>{user.nombre}</p>
                        </div>
                        <div className="item-modal">
                            <label>NIT:</label>
                            <p>{user.identificacion}</p>
                        </div>
                        <div className="item-modal">
                            <label>Email: </label>
                            <p>{user.correo}</p>
                        </div>
                        <div className="item-modal">
                            <label>Telefono: </label>
                            <Field type="number" component={'input'} name={'telefono'} className="input input-phone"/>
                        </div>
                        <div className="item-modal">
                            <label>Celular: </label>
                            <Field type="number" component={'input'} name={'celular'} className="input input-phone"/>
                        </div>
                        <div className="item-modal">
                            <label>Ciudad:</label>
                            <Field type={'select'} component={CitySelectComponent} name={'city'}/>
                        </div>
                        <div className="item-modal">
                            <label>Dirección: </label>
                            <Field type={'text'} component={'input'} name={'direccion'} className="input input-phone"/>
                        </div>
                        <br/>
                        <div className="ladda-button btn-container"  data-style="expand-left">
                            <button ref={this.buttonLadda} data-color="green" type="submit" className="Btn-modal">
                                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                                    Guardar cambios
                                    <i className="icon-ok-1 styles-icons"/>
                                </div>
                            </button>
                            <button className="Btn-modal" onClick={this.props.hidden.bind(this)}>
                                Cancelar
                                <i className="icon-cancel styles-icons"/>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

const formed = reduxForm({
    form: 'MyAccount',
})(ModalMyAccount);

const mapStateToProps = state => ({
    provider: state.provider,
    login: state.login,
    initialValues: state.login.user,
    user: state.user,
});

const connected = connect(mapStateToProps, {
    userPutRequesting,
    resetStates
})(formed);

export default connected;
