import React from 'react';
import BrandComponent from './../../brand/index';
import UnitMeasureComponent from './../../unitMeasure/index';
import {Field, reduxForm} from 'redux-form';
import connect from "react-redux/es/connect/connect";
import * as Ladda from "ladda";
import {toastr} from "react-redux-toastr";
import {ErrorComponent} from "../../../commons/errors/errors";
import {supplyUpdateRequesting, supplyResetStates} from "../Supply/Redux/actions";
import {productsGetRequesting} from "../Redux/actions";
import PhotoInput from "../file-inputs/photo";


class ModalEditSupply extends React.Component {

    constructor(props) {
        super(props);
        this.buttonEdit = React.createRef();
    }

    componentWillUpdate(nextProps) {

        const {supply} = this.props;
        let button = null;

        if (this.buttonEdit.current !== null)
            button = Ladda.create(this.buttonEdit.current);

        if (nextProps.supply.requesting){
           button.start();
        }

        if (nextProps.supply.error !== '' && nextProps.supply.error !== supply.error) {
            let toastOptions = {
                component: (
                    <ErrorComponent message={nextProps.supply.error}/>
                )
            };
            toastr.error(Error, toastOptions);
            button.stop();
        }
        if (nextProps.supply.success){
            button.stop();
        }

        if (nextProps.supply.update && !supply.update) {
            this.props.supplyResetStates();
            toastr.success('Insumo Actualizado', 'Los datos han sido actualizados correctamente');
            this.props.hidden();
        }
    }

    handleAction = (values) => {
        const {provider, supplyUpdateRequesting, supply: {focusSupply}} = this.props;
        supplyUpdateRequesting(provider.token, values, focusSupply);
    };

    render() {
        const {
            handleSubmit,
            product: {products},
            initialValues
        } = this.props;
        return (
            <div className={'body-blur'} onClick={this.props.hidden.bind(this)}>
                <div className="edit-modal" onClick={this.props.stop.bind(this)}>
                    <div className="button-container-close">
                        <button className="Btn-modal-cancel" onClick={this.props.hidden.bind(this)}><i
                            className="icon-cancel"/>
                        </button>
                    </div>
                    <div className="text-modal">
                        <h1>Actualizar insumo</h1>
                    </div>
                    <form onSubmit={handleSubmit(this.handleAction)}>
                        <div className="item-modal">
                            <label>Nombre:</label>
                            <Field name={'nombre'} component={'input'}/>
                        </div>
                        <div className="item-modal">
                            <label>Referencia:</label>
                            <Field name={'referencia'} component={'input'}/>
                        </div>
                        <label>Foto:</label>
                        <Field name={'new_photo'} component={PhotoInput}/>

                        <div className="item-modal">
                            <label>Descripción:</label>
                            <Field name={'descripcion'} component={'input'}/>
                        </div>

                        <div className="item-modal">
                            <label>Precio:</label>
                            <Field name={'precio'} type={'number'} component={'input'}/>
                        </div>

                        <div className="item-modal">
                            <label>Precio Gerente Lider:</label>
                            <Field name={'precio_gerente_lider'} type={'number'} component={'input'}/>
                        </div>

                        <div className="item-modal">
                            <label>Precio Descuento:</label>
                            <Field name={'precio_descuento'} type={'number'} component={'input'}/>
                        </div>

                        <div className="item-modal">
                            <label>Dias maximo de entrega:</label>
                            <Field name={'tiempo_envio'} type={'number'} component={'input'}/>
                        </div>

                        <div className="item-modal">
                            <label>Precio envio:</label>
                            <Field name={'precio_envio'} type={'number'} component={'input'}/>
                        </div>

                        <div className="item-modal">
                            <label>Cantidad Inventario:</label>
                            <Field name={'inventario_disponible'} type={'number'} component={'input'}/>
                        </div>

                        <div className="item-modal">
                            <label>El suministro se deshabilitara cuando el inventario llegue a:</label>
                            <Field name={'limite_inventario'} type={'number'} component={'input'}/>
                        </div>

                        <div className="item-modal">
                            <label>Medidas:</label>
                            <Field name={'medidas'} component={'input'}/>
                        </div>

                        {/*<div className="item-modal">*/}
                        {/*    <label>Unidad de medida:</label>*/}
                        {/*    <UnitMeasureComponent*/}
                        {/*        InitialUnitMeasure={initialValues.unidad_medida}*/}
                        {/*        name={'unidad_medida'}/>*/}
                        {/*</div>*/}

                        <div className="item-modal">
                            <label>Marca:</label>
                            <BrandComponent
                                InitialMarca={initialValues.marca}
                                name={'marca'}/>
                        </div>

                        <div className="button-container">
                            <div className="ladda-button btn-container" data-style="expand-left">
                                <button className="Btn-modal" onClick={this.props.hidden.bind(this)}>Cancelar<i
                                    className="icon-cancel styles-icons"/></button>
                                <button className="Btn-modal" ref={this.buttonEdit}>Aprobar<i
                                    className="icon-ok styles-icons"/></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

const formed = reduxForm({
    form: 'SuppliesEditModal'
})(ModalEditSupply);

const mapStateToProps = state => ({
    provider: state.provider,
    supply: state.supply,
    product: state.product,
    initialValues: state.supply.supplyDataFocus
});

const connected = connect(mapStateToProps, {
    supplyUpdateRequesting,
    productsGetRequesting,
    supplyResetStates
})(formed);

export default connected