import React from 'react';
import PaymentMethodComponent from './../../paymentMethod/index';
import {toastr} from "react-redux-toastr";
import {Field, reduxForm} from 'redux-form';
import connect from "react-redux/es/connect/connect";
import {ErrorComponent} from "../../../commons/errors/errors";

class ModalOrder extends React.Component {
    
    componentWillUpdate(nextProps){
        const {order} = this.props;

        if (nextProps.order.error !== '' && nextProps.order.error !== order.error){
            let toastOptions = {
                component: (
                    <ErrorComponent message={nextProps.order.error}/>
                )
            };
            toastr.error('Recuerda', toastOptions);
        }
        if (nextProps.order.success && !order.success){
            toastr.success('Orden agregado','Orden creado correctamente');
            this.props.hidden();
        }
    }

    handleAction = (values) => {
        const {orderCreateRequesting} = this.props;
        // orderCreateRequesting(values);
    };

    render() {
        const {
            handleSubmit,
            product:{products}
        } = this.props;

        return (
            <div className={'body-blur'} onClick={this.props.hidden.bind(this)}>
                <div className="edit-modal" onClick={this.props.stop.bind(this)}>

                    <div className="button-container-close">
                        <button className="Btn-modal-cancel " onClick={this.props.hidden.bind(this)}><i
                            className="icon-cancel"/>
                        </button>
                    </div>
                    <form onSubmit={handleSubmit(this.handleAction)}>
                        <h1 className="arreglojulian">Agregar ordenes</h1>

                        <div className="item-modal">
                            <label>Fecha orden: </label>
                            <Field name={'fecha_orden'} type={'date'} component={'input'}/>
                        </div>

                        <div className="item-modal">
                            <label>Fecha envio: </label>
                            <Field name={'fecha_envio'} type={'date'} component={'input'}/>
                        </div>

                        <div className="item-modal">
                            <label>Mensajero: </label>
                            <Field name={'mensajero'} component={'input'}/>
                        </div>

                        <div className="item-modal">
                            <label>Cantidad: </label>
                            <Field name={'cantidad'} component={'input'}/>
                        </div>

                        <div className="item-modal">
                            <label>Tiempo Entrega: </label>
                            <Field name={'tiempo_entrega'}  component={'input'}/>
                        </div>

                        <div>
                            <label>Producto</label>
                            <Field name="producto" type="select" className="select-typeSubcategory" component="select">
                                <option>...Seleccione</option>
                                {products.length > 0 && products.map((product, index)=>{
                                    return <option key={index} value={product.id}>{product.nombre}</option>
                                })}
                            </Field>
                        </div>


                        <div className="item-modal">
                            <label>Metodo de pago: </label>
                            <PaymentMethodComponent/>
                        </div>
                        <div className="item-modal">
                            <label>Total: </label>
                            <Field name={'total'} component={'input'}/>
                        </div>
                        <div className="item-modal">
                            <label>Fecha Pago: </label>
                            <Field name={'fecha_pago'} type={'date'} component={'input'}/>
                        </div>
                        <div className="button-container">
                            <div className="btn-container">
                                <button className="Btn-modal" onClick={this.props.hidden.bind(this)}>Cancelar<i className="icon-cancel styles-icons"/></button>
                                <button className="Btn-modal">Crear Orden<i className="icon-ok styles-icons"/></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

const formed = reduxForm({
    form: 'OrderCreateModal',
    enableReinitialize: true
})(ModalOrder);

const mapStateToProps = state => ({
    client: state.client,
    order: state.order,
    product: state.product,
});

const connected = connect(mapStateToProps,{

})(formed);

export default connected
