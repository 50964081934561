//obtener datos
export const GET_SUPPLY_REQUESTING = 'GET_SUPPLY_REQUESTING';
export const GET_SUPPLY_SUCCESS = 'GET_SUPPLY_SUCCESS';
export const GET_SUPPLY_ERROR = 'GET_SUPPLY_ERROR';
//crear datos
export const SUPPLY_CREATE_REQUESTING = 'SUPPLY_CREATE_REQUESTING';
export const SUPPLY_CREATE_SUCCESS    = 'SUPPLY_CREATE_SUCCESS';
export const SUPPLY_CREATE_ERROR      = 'SUPPLY_CREATE_ERROR';

//actualizar datos
export const SUPPLY_UPDATE_REQUESTING = 'SUPPLY_UPDATE_REQUESTING';
export const SUPPLY_UPDATE_SUCCESS    = 'SUPPLY_UPDATE_SUCCESS';
export const SUPPLY_UPDATE_ERROR      = 'SUPPLY_UPDATE_ERROR';
export const SET_FOCUS_SUPPLY         = 'SET_FOCUS_SUPPLY';

//cambiar estado
export const SUPPLY_CHANGE_STATE_REQUESTING = 'SUPPLY_CHANGE_STATE_REQUESTING';
export const SUPPLY_CHANGE_STATE_SUCCESS    = 'SUPPLY_CHANGE_STATE_SUCCESS';
export const SUPPLY_CHANGE_STATE_ERROR      = 'SUPPLY_CHANGE_STATE_ERROR';

//consultar datos
export const SUPPLY_SEARCH_REQUESTING = 'SUPPLY_SEARCH_REQUESTING';
export const SUPPLY_SEARCH_SUCCESS    = 'SUPPLY_SEARCH_SUCCESS';
export const SUPPLY_SEARCH_ERROR      = 'SUPPLY_SEARCH_ERROR';


export const SUPPLIES_PRODUCT_GET_REQUESTING = 'SUPPLIES_PRODUCT_GET_REQUESTING';
export const SUPPLIES_PRODUCT_GET_SUCCESS = 'SUPPLIES_PRODUCT_GET_SUCCESS';
export const SUPPLIES_PRODUCT_GET_ERROR = 'SUPPLIES_PRODUCT_GET_ERROR';

//crear datos
export const SUPPLY_PRODUCT_CREATE_REQUESTING = 'SUPPLY_PRODUCT_CREATE_REQUESTING';
export const SUPPLY_PRODUCT_CREATE_SUCCESS    = 'SUPPLY_PRODUCT_CREATE_SUCCESS';
export const SUPPLY_PRODUCT_CREATE_ERROR      = 'SUPPLY_PRODUCT_CREATE_ERROR';

//actualizar datos
export const SUPPLY_PRODUCT_UPDATE_REQUESTING = 'SUPPLY_PRODUCT_UPDATE_REQUESTING';
export const SUPPLY_PRODUCT_UPDATE_SUCCESS    = 'SUPPLY_PRODUCT_UPDATE_SUCCESS';
export const SUPPLY_PRODUCT_UPDATE_ERROR      = 'SUPPLY_PRODUCT_UPDATE_ERROR';
export const SET_FOCUS_SUPPLY_PRODUCT         = 'SET_FOCUS_SUPPLY_PRODUCT';

//obtener datos
export const SUPPLIES_PRODUCTS_GET_REQUESTING = 'SUPPLIES_PRODUCTS_GET_REQUESTING';
export const SUPPLIES_PRODUCTS_GET_SUCCESS    = 'SUPPLIES_PRODUCTS_GET_SUCCESS';
export const SUPPLIES_PRODUCTS_GET_ERROR      = 'SUPPLIES_PRODUCTS_GET_ERROR';

export const SUPPLIES_PRODUCTS_CREATE_REQUESTING = 'SUPPLIES_PRODUCTS_CREATE_REQUESTING';
export const SUPPLIES_PRODUCTS_CREATE_SUCCESS    = 'SUPPLIES_PRODUCTS_CREATE_SUCCESS';
export const SUPPLIES_PRODUCTS_CREATE_ERROR      = 'SUPPLIES_PRODUCTS_CREATE_ERROR';

export const SUPPLIES_PRODUCTS_UPDATE_REQUESTING = 'SUPPLIES_PRODUCTS_UPDATE_REQUESTING';
export const SUPPLIES_PRODUCTS_UPDATE_SUCCESS    = 'SUPPLIES_PRODUCTS_UPDATE_SUCCESS';
export const SUPPLIES_PRODUCTS_UPDATE_ERROR      = 'SUPPLIES_PRODUCTS_UPDATE_ERROR';
export const SET_FOCUS_SUPPLIES_PRODUCTS         = 'SET_FOCUS_SUPPLIES_PRODUCTS';

export const SUPPLY_RESET_STATES = 'SUPPLY_RESET_STATES';