import React from 'react';
import {reduxForm} from "redux-form";
import connect from "react-redux/es/connect/connect";
import {suppliesProductsGetRequesting} from "../Supply/Redux/actions";

class ModalProductSupply extends React.Component {

    render() {
        const {supply:{productSupplies}} = this.props;
        return (
            <div className={'body-blur'} onClick={this.props.hidden.bind(this)}>
                <div className="edit-modal" onClick={this.props.stop.bind(this)}>

                    <div className="button-container-close">
                        <button className="Btn-modal-cancel " onClick={this.props.hidden.bind(this)}><i
                            className="icon-cancel"/>
                        </button>
                    </div>
                    <div className="text-modal">
                        <h1>Información de compra</h1>
                    </div>

                    <div className="Conteiner-table">
                        <table className="table-info-product1">
                            <thead>
                            <tr>
                                <th>Nombre de producto</th>
                                <th>Nombre del Insumo</th>
                            </tr>
                            </thead>
                            <tbody>
                            {productSupplies.map((productSupply,index)=>(
                                <tr key={index}>
                                    <td>{productSupply.producto.nombre}</td>
                                    <td>{productSupply.insumo.nombre}</td>
                                    <td>
                                        <button className="Btn-item-table">Editar<i
                                            className="icon-pencil styles-icons"/></button>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                    <br/>
                    <div className="button-container">
                        <div className="btn-container">
                            <button className="Btn-modal" onClick={this.props.hidden.bind(this)}>Cancelar<i
                                className="icon-cancel styles-icons"/>
                            </button>
                            <button className="Btn-item-table"
                                    onClick={this.props.handleShowModal.bind(this, 'Crear producto')}>Producto<i className="icon-pencil styles-icons"/>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const formed = reduxForm({
    form: 'ModalProductSupply'
})(ModalProductSupply);

const mapStateToProps = state => ({
    provider: state.provider,
    supply: state.supply,
});

const connected = connect(mapStateToProps,{
    suppliesProductsGetRequesting
})(formed);

export default connected