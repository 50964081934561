import {UNITMEASURE_GET_ERROR, UNITMEASURE_GET_REQUESTING, UNITMEASURE_GET_SUCCESS} from "./constants";


export const unitMeasureGetRequesting = (token) => ({
   type: UNITMEASURE_GET_REQUESTING,
   token
});
export const unitMeasureGetSuccess = (unitMeasures) => ({
    type: UNITMEASURE_GET_SUCCESS,
    unitMeasures
});
export const unitMeasureGetError = (error) => ({
    type: UNITMEASURE_GET_ERROR,
    error
});